.mar-b-15 {
    margin-bottom: 15px;
} 
.mar-b-30 {
    margin-bottom: 30px;
}
.mar-b-50{
    margin-bottom: 50px;
}
.mar-t-8 {
    padding: 7rem 0 4rem;
}
.mar-tb-3 {
    padding: 3rem 0;
}
.mar-tb-3-5 {
    padding: 3rem 0 5rem;
}
.col-aligned{
    padding-left: 0;
    padding-right: 0;
}
.with-center{
    text-align: center;
}
.section-divider {
    border-top: none;
    border-bottom: 1px solid #edf0f7;
    margin: 2rem auto;
}
.section-heading-box{
    margin-bottom: 7rem;
}
.wb-page-heading {
    font-size: 4rem;
    margin-bottom: 1rem;
}
.wb-page-desc{
    font-size: 1.8rem;
    font-weight: lighter;
    line-height: 1.7;
    margin-bottom: 2rem;
}
.wb-banner-heading{
    font-size: 5rem;
    margin: 0;
}
.wb-banner-heading strong{
    color: #0562e8;
}
.text-badge{
    text-transform: uppercase;
    color: #7f7f7f;
    font-size: 1.5rem;
    margin: 0 0 .5rem;
    line-height: 1;
}
.large-count-number {
    font-size: 3rem;
    font-weight: 700;
    font-family: NonBreakingSpaceOverride,Roboto,Helvetica,Arial,sans-serif;
    color: #b0b0b0;
    width: 50px;
    margin-right: 1rem;
}
.description-list li {
    font-weight: lighter;
    margin: 0 0 1rem;
    vertical-align: top;
    display: inline-block;
    width: 49%;
    padding-left: 2.5rem;
    position: relative;
    padding-right: 2rem;
}
.description-list.full-width li {
 width: 100%;
}
.description-list li::after, .description-list li::before {
    content: "+";
    font-weight: 500;
    font-size: 15pt;
    position: absolute;
}
.description-list li::before {
    left: -10px;
    top: -5px;
    color: #e8eef6;
}
.description-list li::after {
    left: 0;
    top: 0;
    color: #0562e8;
}
/* Banner */
.banner-section {
    padding: 80px 0 0;
    position: relative;
    margin-bottom: 20px;
}
.banner-background-outer {
    position: absolute;
    overflow: hidden;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    bottom: 0;
    background-size: cover;
    /* background-color: #f3f8ffbd; */
}
.banner-container {
    align-content: center;
    max-height: 1000px;
    min-height: 500px;
    display: grid;
    position: relative;
}
.banner-content-box{
    display: grid;
    align-content: center;
    height: 100%;
    padding: 2rem 0;
}
.banner-content-box-item {
    position: relative;
    z-index: 999;
    width: 100%;
}
.banner-job-search{
    margin: 3rem 0;
    position: relative;
    max-width: 700px;
}
.banner-job-search.with-center{
    margin: 3rem auto;
}
.banner-job-search .search-job{
    position: relative;
    padding: 5px 100px 5px 15px;
    font-size: 12pt;
    font-weight: lighter;
    border-radius: 2px;
    width: 100%;
    min-height: 48px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 8px 0 rgba(0,0,0,0.1);
}
.banner-job-search .search-btn {
    position: absolute;
    right: 0px;
    padding: 7px;
    background: #0562e8;
    color: #fff;
    font-size: 22px;
    border: 0;
    font-weight: lighter;
    width: 90px;
}
/* Three Boxes */
.three-boxes-item {
    position: relative;
    display: grid;
    height: 100%;
    align-content:flex-start;
}
.three-boxes-item figure {
    margin-bottom: 2rem;
    display: inline-block;
}
.three-boxes-item.item-with-icon{
    padding: 1.5rem;
}
.three-boxes-item.item-with-icon figure img {
    max-width: 7rem;
}
.three-boxes-item.item-with-image{
    padding: 0;
}
.three-boxes-item.item-with-image .three-boxes-item-content {
    padding: 0 2rem 2rem;
}
.card-heading {
    font-size: 2.2rem;
    line-height: 1.5;
    margin: 0 0 .6rem;
}
.card-description {
    font-weight: lighter;
    font-size: 1.8rem;
}
/* Responsive css */
@media screen and (max-width:1368px) {
    .banner-section{
        padding: 80px 0 0px;
        margin-bottom: 0px;
    }
    .wb-page-heading {
        font-size: 3.5rem;
    }
    .wb-banner-heading {
        font-size: 4rem;  
    }
}
@media screen and (max-width:1024px) {
    .mar-t-8 {
        padding: 5rem 0 3rem;
    }
    .banner-container {
        min-height: 250px;
        height: auto;
        padding: 20px 0;
    }
    .section-heading-box {
        margin-bottom: 4rem;
    }
    .wb-banner-heading {
        font-size: 3.5rem;  
    }
    .wb-page-heading {
        font-size: 3rem;
    }
}
@media screen and (max-width:640px) {
    .mar-t-8 {
        padding: 3rem 0;
    }
    .large-count-number{
        font-size: 2.2rem;
    }
    .wb-banner-heading {
        font-size: 2.6rem;
    }
    .wb-page-heading {
        font-size: 2.3rem;
    }
    .wb-page-desc {
        font-size: 1.6rem;
    }
    .description-list li{
        width: 100%;
    }
    .three-boxes-item{
        text-align: center;
    }
    .card-heading{
        font-size: 2rem;
    }
    .card-description {
        font-size: 1.6rem;
    }
}

.header.fixed {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 9999;
}

.user-navigation-bar {
    /* padding: 0 15px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
}

.navigation-menu li {
    line-height: 80px;
}

.navigation-menu.nav-left, .navigation-menu.nav-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.navigation-menu.nav-right {
    margin-left: auto;
}

.brand-wrapper, .brand-image, #brand-logo {
    display: flex;
    align-items: center;
}

.brand-image {
    width: auto;
    cursor: pointer;
    min-height: 80px;
}

.brand-wrapper .logo-text {
    font-size: 2.2rem;
    font-weight: normal;
    color: #373737;
    margin: 0 5px;
}

.brand-image a {
    display: block;
}

.user-navigation-bar .toggle-topMenu {
    display: none;
    width: 22px;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.user-navigation-bar .toggle-topMenu div {
    width: 100%;
    height: 3px;
    background: #333;
    border-radius: 2px;
    margin: 4px auto;
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.navbar-menu {
    vertical-align: middle;
    display: inline-block;
}

.navbar-menu li {
    display: inline-block;
    margin: 0 10px;
}

.navbar-menu li .nav-links {
    font-size: 1.8rem;
    /* font-weight: lighter; */
    color: #2b2b2b;
    padding: .5rem;
    position: relative;
}

.navbar-menu li .nav-links:hover, .navbar-menu li .nav-links.active {
    color: #0b4dad;
}

.profile-pic img {
    border-radius: 100%;
    width: 30px;
    max-width: 100%;
    vertical-align: middle;
    margin-right: .5rem;
}

.nav-links i.material-icons {
    position: relative;
    top: 3px;
    font-size: 17px;
}

.navbar-menu li.dropdown-parent {
    transition: .3s ease all;
}

.navbar-menu li .dropdown {
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 2px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .1);
    min-width: 280px;
    position: absolute;
    top: 80px;
    transition: .3s ease all;
    max-width: 100%;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    display: none;
}

.dropdown-link {
    font-size: 1.8rem;
    font-weight: lighter;
    color: #2b2b2b;
    cursor: pointer;
    padding: .5rem;
}

.navbar-menu li.dropdown-parent .dropdown .dropdown-item {
    border-radius: 3px;
    margin-bottom: 15px;
    display: block;
    padding: 10px 20px;
    line-height: 2;
}

.navbar-menu li.dropdown-parent .dropdown .dropdown-item:hover {
    background-color: #eee;
}

.navbar-menu li.dropdown-parent .dropdown .dropdown-item a {
    color: #222;
    font-size: 1.8rem;
    display: block;
    font-weight: lighter;
}

.navbar-menu li.dropdown-parent .dropdown .dropdown-item .mark-note {
    color: #8f949d;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
}

.navbar-menu li.dropdown-parent:hover .dropdown-link {
    color: #0b4dad
}

.navbar-menu li.dropdown-parent:hover .dropdown {
    visibility: visible;
    opacity: 1;
    display: flex;
    transition: .3s ease all;
}

@media only screen and (max-width: 1368px) {
    .navbar-menu li .nav-links {
        font-size: 1.7rem;
    }

    .navbar-menu li {
        margin: 0 5px;
    }
}

@media only screen and (max-width: 1024px) {
    .header {
        background-color: #fff;
        border-bottom: 1px solid #eee;
    }

    .header.dropdown-open {
        top: 0;
        bottom: 0;
        overflow-y: auto;
    }

    .navigation-menu.nav-right {
        margin-left: 0;
    }

    .navigation-menu.nav-left, .navigation-menu.nav-right {
        display: none;
    }

    .navigation-menu li {
        line-height: 60px;
    }

    .user-navigation-bar, .navigation-menu.nav-left, .navigation-menu.nav-right {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .navbar-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }

    .navbar-menu li {
        border-top: 1px solid #eee;
        font-size: 25px;
    }

    .navbar-menu li .nav-links.boxed-links {
        max-width: 220px;
        margin: 15px auto;
    }

    .Navbar__ToggleShow {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .user-navigation-bar .toggle-topMenu {
        -ms-flex-item-align: end;
        align-self: flex-end;
        display: block;
        position: absolute;
        cursor: pointer;

    }

    .navbar-menu li .dropdown {
        width: 100%;
        position: unset;
        border: 0;
        box-shadow: none;
        display: block;
        visibility: visible;
        opacity: 1;
    }
}

.dialog-content p {
    text-align: center;
}

.dialog-mask {
    background-color: #8c8c8c !important;
}
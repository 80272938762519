html {
  font-size: 8px !important;
}

.new-header html {
  font-size: 8px !important;
}

.new-header .wrapper-container {
  padding: 0 !important;
  min-height: auto;
}

.new-header .xenonstack-header-btn .xenonstack-contact-us-button span {
  color: #fff;
}

.new-header .headershadow {
  background: #fff !important;
  box-shadow: 0px 3px 6px #1860d333;
}


.new-header .header-wrapper {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #fff;
}

.new-header .nav-links {
  color: #4d5b66;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  width: 86px;
  height: 45px;
  right: 0px;
  top: calc(50% - 45px / 2);
  padding: 10px 20px;
  background: #48194e;
}

@media (max-width: 408px) {
  .new-header .nav-links {
    padding: 10px 8px;
  }
}

.new-header .nav-li {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 15px 0;
}

.new-header .nav-li a {
  color: white;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .new-header .xenonstack-header-content a {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  .new-header .mobile-header-block {
    display: none;
  }

  .new-header .mobile-navigation {
    display: none;
  }

  .new-header .xenonstack-down-arrow {
    display: none;
  }

  .new-header .xenonstack-header-buttons {
    display: none;
  }
}


.new-header .xenonstack-logo {
  height: 50px;
  width: auto;
}

.new-header .xenonstack-header {
  padding: 14px 0;
  width: 100%;
}

.new-header .xenonstack-header-right {
  line-height: 60px;
}

.new-header .xenonstack-contact-us-button {
  background-color: #48194e;

  color: white !important;
  font-size: 2.4rem;
  height: 44px;
  padding: 1rem 2rem;
  width: 130px;
  border: none;
}

.new-header .xenonstack-contact-us-buttons {
  background-color: #48184e;

  color: white !important;
  font-size: 2.4rem;
  height: 44px;
  padding: 1.5rem 3.5rem;
  width: 130px;
  border: none;
}

.new-header .xenonstack-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.new-header .xenonstack-header-content a {
  color: #1e1e1e;
  font-size: 2.2rem;
  line-height: 130%;
  position: relative;
}


.new-header .mobile-header-heading span {
  color: #1e1e1e;
  position: relative;
  padding-right: 4px;
  font-weight: 500;
  font-size: 2.2rem;
  font-family: "Roboto", sans-serif;
}

.new-header .xenonstack-internal-content>.mobile-header-heading span::after {
  content: "";
  position: absolute;
  bottom: -27px;
  left: 0;
  width: 100%;
  height: 0;
}

.new-header .xenonstack-header-content li:hover .mobile-header-heading span {
  color: #1e1e1e !important;
  font-weight: 700;
}
.new-header .no-drop-down :hover {
  color: #1e1e1e !important;
  font-weight: 700;
}

.new-header .without-border a {
  font-size: 2.4rem !important;
}

.new-header .xenonstack-header-button {
  padding: 5px 0px 5px 0px;
  display: none;
}

.new-header .xenonstack-header-dropdown {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 88px;
  z-index: 999;
  box-shadow: 0px 3px 6px #1860d333;
  border-top: 1px solid #b2b8c3;
  background-color: #fff;
  visibility: hidden;
  transform: translateY(50px);
}

.new-header .xenonstack-internal-content:hover .xenonstack-header-dropdown {
  box-shadow: 0px 3px 6px #1860d333;
  position: absolute;
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
  transition: 0.4s ease all;
}

.new-header .xenonstack-internal-content {
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  color: #1e1e1e;
}

/* internal content */

.new-header .xenonstack-header-inner {
  position: relative;
}

.new-header .xenonstack-heading-inner-wrapper {
  padding: 50px 8px 25px 10px;
  height: 106%;
}

.new-header .xenonstack-heading-inner-wrapper-company {
  padding: 50px 8px 25px 10px;
}


.new-header .xenonstack-heading-inner-details-inside {
  height: 100%;
}

.new-header .xenonstack-heading-inner-details ul {
  height: 100%;
  padding: 0px 0px 15px 0px;
}

.new-header .xenonstack-heading-inner-details li {
  width: 100%;
  display: flex;
  align-items: center;
  background: #f7f7f9;
}

.new-header li.mid {
  background: #fdeeff;
}

.new-header .xenonstack-heading-inner-details-text {
  height: 100%;
  padding: 22px 0px;
  display: flex;
  width: 50%;
  padding-right: 0;
  flex-direction: column;
}


.new-header .xenonstack-heading-inner-details-text p {
  margin-top: -15px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.new-header .xenonstack-heading-inner-details img {
  height: 100%;
}

/* Media Queries */

@media (min-width: 1024px) and (max-width: 1439px) {
  .new-header .xenonstack-header-content a {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  .new-header .mobile-header-block {
    display: none;
  }

  .new-header .mobile-navigation {
    display: none;
  }

  .new-header .xenonstack-down-arrow {
    display: none;
  }

  .new-header .xenonstack-header-buttons {
    display: none;
  }
}

@media (max-width: 1023px) {
  .new-header .mobile-header-block {
    padding: 10px;
    padding-top: 25px;
  }

  .new-header .mobile-header-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
  }

  .new-header .banner-job-search {
    display: none;
  }

  .new-header .xenonstack-header-dropdown {
    display: none;
  }

  .new-header .xenonstack-logo {
    display: none;
  }

  .new-header .xenonstack-header-content {
    display: block;
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0;
    padding: 0 30px;
  }

  .new-header .xenonstack-internal-content>.mobile-header-heading span::after {
    z-index: -1;
    background-color: white;
    display: none;
  }

  .new-header .xenonstack-header {
    padding: 0px 10px;
    display: none;
  }

  .new-header .xenonstack-internal-content {
    padding: 2px 0;
    border-bottom: 1px solid gainsboro;
  }

  .new-header .show-div {
    display: block;
  }

  .new-header .hide-div {
    display: none;
  }

  .new-header .xenonstack-header-button {
    padding: 30px 0px;
    display: none;
  }

  .new-header .xenonstack-header-content a {
    position: relative;
    width: 100%;
    justify-content: space-between;
  }

  .new-header .xenonstack-internal-content .mobile-header-heading-inside {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .new-header .xenonstack-internal-content .mobile-header-heading-inside .xenonstack-mobile-heading {
    flex-basis: 96%;
    position: relative;
  }

  .new-header .xenonstack-internal-content .mobile-header-heading-inside .xenonstack-down-arrow {
    width: 10px !important;
    justify-content: flex-end;
    align-items: flex-end;
    flex-basis: 2%;
  }
}

.new-header .heading-wrapper {
  display: flex;
  align-items: center;
}

.new-header .xenonstack-life-1,
.new-header .xenonstack-life-1-phn,
.new-header .xenonstack-life-3,
.new-header .xenonstack-life-5 {
  background: #f7f7f9;
  margin-bottom: 2%;
}

.new-header .xenonstack-life-2-phn,
.new-header .xenonstack-life-2,
.new-header .xenonstack-life-4 {
  background: #fdeeff;
  margin-bottom: 2%;
}

.new-header .xenonstack-life-2 img {
  width: 74.53%;
  padding: 17px;
}

.new-header .xenonstack-life-4 img {
  width: 74.53%;
  padding: 17px;
}

.new-header .xenonstack-life-1-phn img {
  width: 64.94%;
  padding: 15px;
}

.new-header .xenonstck-heading-img {
  flex-basis: 170px;
}

.new-header .xenonstack-desktop-view-imgs img {
  width: 100%;
}

.new-header .xenonstack-heading-inner-detail {
  background-color: #f7f7f9;
  width: 100%;
  height: 90%;
}

.new-header .xenonstack-heading-inner-details-texts.header-part {
  padding: 30px;
  justify-content: space-evenly;
  padding-right: 15px;
}

.new-header .header-sub-part {
  margin-top: 30%;
}

@media (max-width: 1024px) {
  .new-header .header-sub-part {
    margin-top: 65%;
  }
}

.new-header .xenonstack-heading-inner-detail.blog-post {
  background-color: #fdeeff;
}

.new-header .blog-post img {
  padding: 27px;
}

.new-header .blog-post p {
  padding-bottom: 23px;
}

.new-header .blog-post .inner-head h4 {
  padding-top: 0;
  padding-left: 32px;
}

.new-header .blog-post.question p {
  padding-bottom: 18px;
  padding-left: 32px;
  margin-top: 26px;
}

@media (min-width: 1022px) and (max-width: 1024px) {
  .new-header .blog-post.question p {
    padding-bottom: 30px;
  }

  .new-header .blog-post.read p {
    padding-bottom: 11px;
  }
}

@media (max-width: 768px) {
  .new-header header .container {
    padding: 0 15px;
    width: 100%;
    max-width: 100%;
  }
}

/* end */
@media (min-width: 1024px) {
  .new-header .header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}

.new-header .xenonstack-desktop-view-imgs.benefits {
  width: 209px;
}

.new-header .xenonstack-desktop-view-imgs.benefits img {
  width: 61%;
  padding: 14px;
}

.new-header .xenonstack-deskyop-view-img img {
  width: 100%;
}

.new-header .xenonstack-deskyop-view-img.faq {
  width: 147px;
  padding: 19px;
}

.new-header .xenonstack-header-buttons {
  padding-top: 4%;
}

.new-header hr {
  margin-top: 10px;
  margin-bottom: 35px;
  border-top: 1px solid #eeeeee !important;
}

.new-header .desktop-head {
  padding-left: 77px;
  padding-top: 17px;
}

.new-header .benefits-perks {
  padding-top: 17px;
}

.new-header .slider-up {
  padding-top: 20px;
}

@media (max-width: 1023px) {
  .new-header .xenonstack-heading-inner-details-texts {
    flex-basis: 180px;
  }
}

@media (max-width: 520px) {
  .new-header .xenonstack-heading-inner-details-texts {
    flex-basis: 135px;
  }
}

@media (min-width: 320px) {
  .new-header .nav-links {
    padding: 10px 20px;
  }
}

@media (min-width: 1369px) {
  .new-header .container {
    max-width: 1340px;
  }
}


.new-header .xenonstack-heading-inner-heading {
  height: 100%;
}

.new-header .xenonstack-heading-inner-heading h2 {
  height: 100%;
  border-bottom: 1px solid #46536b;
  padding-bottom: 10px;
  width: max-content;
}

.new-header .blog {
  justify-content: space-evenly;
  align-items: left;
  text-align: left;
  padding-left: 10%;
  padding-bottom: 25%;
}

.new-header .xenonstack-heading-inner-detail.blog-post h3 {
  margin-top: 25px;
  margin-left: 24px;
}

.new-header .xenonstack-heading-inner-details-text.hiring {
  padding-left: 43px;
  padding-top: 0;
}

@media (max-width: 1023px) {
  .new-header .mobile-header-block {
    padding: 10px 0;
    padding-top: 25px;
  }

  .new-header .mobile-header-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
  }

  .new-header .banner-job-search {
    display: none;
  }

  .new-header .xenonstack-header-dropdown {
    display: none;
  }

  .new-header .xenonstack-logo {
    display: none;
  }

  .new-header .xenonstack-header-content {
    display: block;
    position: absolute;
    background: #fff;
    width: 100%;
    left: 0;
    padding: 0 30px;
  }
  .new-header .xenonstack-header {
    padding: 0px 10px;
    display: none;
  }

  .new-header .xenonstack-internal-content {
    padding: 2px 0;
    border-bottom: 1px solid gainsboro;
  }

  .new-header .show-div {
    display: block;
  }

  .new-header .hide-div {
    display: none;
  }

  .new-header .xenonstack-header-button {
    padding: 30px 0px;
    display: none;
  }

  .new-header .xenonstack-header-content a {
    position: relative;
    width: 100%;
    justify-content: space-between;
  }

  .new-header .mobile-navigation-submenu li {
    font-weight: 300;
  }
  .new-header .xenonstack-internal-content .mobile-header-heading-inside {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .new-header .xenonstack-internal-content .mobile-header-heading-inside .xenonstack-mobile-heading {
    flex-basis: 96%;
    position: relative;
  }

  .new-header .xenonstack-internal-content .mobile-header-heading-inside .xenonstack-down-arrow {
    width: 10px !important;
    justify-content: flex-end;
    align-items: flex-end;
    flex-basis: 2%;
  }
}

.new-header .mobile-header {
  display: none;
}


@media (min-width: 1025px) {
  .new-header .container {
    max-width: 1170px;
  }
}

.new-header .navigation-bar {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.new-header .navigation-menu.nav-right {
  justify-content: space-around;
}

.new-header .navbar-menu {
  display: inline-block;
}

.new-header .navbar-menu li {
  display: inline-block;
  margin: 0 10px;
  justify-content: space-evenly;
  padding: 10px 59px 44px 22px;
}

.new-header .navbar-menu li img {
  width: 200px;
  height: 6 0px;
}

.new-header .navigation-menu li {
  line-height: 80px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.new-header .navbar-menu li .nav-links.boxed-links {
  background: #48194e;
  color: #fff;
  border-radius: 2px;
  padding: 0 2rem;
  min-height: 42px;
  font-weight: 400;
  display: block;
  box-sizing: border-box;
  line-height: 42px;
  -webkit-transition: 0.4s ease all;
  transition: 0.4s ease all;
}

.new-header .mar-t-8 {
  padding: 7rem 0 4rem;
}

.new-header .nav-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  position: relative;
}

.new-header .nav-ul .item>a {
  letter-spacing: 0px;
  color: #4d5b66;
  opacity: 1;
  font-size: 20px;
  line-height: 48px;
}
.new-header .menu-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.new-header .menu-icon img {
  position: relative;
  height: 20px;
  width: 20px;
}

.new-header .mobile-right {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}


.new-header .desktop-drop-down-img {
  width: 14px;
  vertical-align: unset;
}

.new-header .desktop-drop-down-imgs {
  width: 14px;
}

@media (max-width:1023px) {
  .new-header .desktop-drop-down-img {
    display: none;
  }

  .new-header .desktop-drop-down-imgs {
    display: none;
  }

  .new-header .headershadow {
    position: sticky !important;
  }
}

.new-header .header-search img {
  width: 30px;
  cursor: pointer;
}

.new-header #cross-search-image-mobile {
  width: 20px;
}

.new-header .xenonstack-header-dropdown-1 {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 83px;
  z-index: 999;
  box-shadow: 0px 3px 6px #1860D333;
  border-top: 1px solid #B2B8C3;
  background-color: #fff;
  visibility: hidden;
  transform: translateY(60px);
  opacity: 0;
}

.new-header .xenonstack-header-dropdown-show {
  box-shadow: 0px 3px 6px #1860D333;
  position: absolute;
  visibility: visible;
  transform: translateY(10px);
  opacity: 1;
  transition: .4s ease all;
}

.new-header .banner-job-search-form input {
  background: transparent;
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 0.7rem 0rem;
  font-size: 2.2rem;
  line-height: 20px;
  color: #48184f;
  width: 100%;
  opacity: 0.7;
  border: none;
  border-bottom: 2px solid rgba(37, 2, 43, 0.26);
  position: relative;
  outline: none;
}

.new-header .searchbar {
  position: absolute;
  right: 27px;
  top: 42px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 30px;
}

.new-header .header-white {
  background-color: #fff !important;
}

.new-header #cross-search-image, #cross-search-image-mobile {
  display: none;
}


.new-header ul.hs-search-field__suggestions {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}

.new-header ul.hs-search-field__suggestions li:first-child {
  color: #505e72;
  font-family: "sf_pro_displaysemibold" , sans-serif;
  font-size: 2.4rem;
  flex-basis: 100%;
  margin-bottom: 0;
  padding-bottom: 1.8rem;
}

.new-header ul.hs-search-field__suggestions li {
  flex-basis: 50%;
  padding-bottom: 25px;
  font-size: 2rem;
  font-size: 2.2rem;
  line-height: 130%;
  font-family: "Roboto", sans-serif;
  color: #4d5b66;
  font-weight: 500;

}

.new-header ul.hs-search-field__suggestions li a {
  font-size: 2.2rem;
  line-height: 130%;
  margin: 0 0 1rem;
  font-family: "Roboto", sans-serif;
  color: #4d5b66;
}

@media (max-width:767px) {
  .new-header ul.hs-search-field__suggestions li {
    flex-basis: 100%;

  }

  .wrapper-container {
    padding: 0;
    min-height: 0 !important;
  }
}

.new-header .btn-group {
  display: flex;
  justify-content: space-between;
}

@media (max-width:1023px) {
  .new-header .header-search {
    display: none;
  }

  .new-header .xenonstack-header-dropdown-show {
    transform: translateY(-10px);
  }

  .new-header .wrapper-container {
    padding: 0;
    min-height: 0;
  }

  .new-header .checkout-button {
    background-color: #fff !important;
    animation: none !important;

  }

  .new-header .checkout-button.xenonstack-header-btn a {
    animation: pulses 2s infinite;

  }
}

.new-header .xenonstack-recommends-wrapper {
  padding: 0px 0px 60px 0px;
}

.new-header .xenonstack-recommends-wrapper h4 {
  margin: 0px 0px 0px;
  color: #505e72;
  font-size: 2.4rem;
}

.new-header .xenonstack-recommends-wrapper ul {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.new-header .xenonstack-recommends-wrapper li {
  width: 100%;
  display: flex;
  align-items: center;
}


.new-header .xenonstack-recommends-wrapper p {
  padding: 0px 10px;
  margin: 0;
}

.new-header .xenonstack-header-dropdown-1 hr {
  margin-top: 0px;
}

.new-header .checkout-button {
  border-collapse: collapse;
  border-radius: 0px;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(72 25 78);
  animation: pulses 2s infinite;
  text-align: center;
  border: 0;
  transition: all 0.2s ease 0s;
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  color: #fff;
  padding: 0 17px;
  background: #48194e;
  line-height: 45px;
  width: -webkit-max-content;
  width: max-content;
  display: none;
}

.note-msg {
  font-weight: normal;
}

@media(min-width:768px) and (max-width:1023px) {
  .wrapper-container {
    padding: 40px 0;
  }
}
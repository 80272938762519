/* eslint-disable */

@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Droopy
++++ Imports ++++
++++ Reset ++++
++++ Basic ++++

	++typography++
	++Admin Basic++
	++Form++
	++Form-Wizard++
	++Product Deatails*++
	++Product Cart++
	++Popover++
	++Typerhead++
	++Editors++
	++Accordion++
	++Tabs++
	++Table++
	++Morris Chart++
	++Flot Chart++
	++Chartist++
	++Easy pie chart++
	++Skills Counter++
	++Nestable++
	++Treeview++
	++Modals++
	++sweet-alert++
	++Notification++
	++Tooltop++
	++Icons++
	++File Upload++
	++File Manager++
	++Carousel++
	++Testimonial slider++
	++Twitter slider++
	++Range Slider++
	++Gallery++
	++Typography++
	++Panel++
	++Pricing Table++
	++Dropdown++
	++Weather++
	++Sparkline++
	++Pagination++
	++Pager++
	++Labels++
	++Badge++
	++Task Panel++
	++Vecotor Map++
	++Progressbar++
	++Search Bar++
	++Chat UI++
	++Widgets++
	++Profile++
	++Followers++
	++Small Graph++
	++Timeline++
	++Caledar++
	++Todo List++
	++Activity++
	++Message Box++
	++Inbox++
	++Contact Cards++
	++Contact List++
	++Login Form++
	++Error Page++
	++Products++
	++Cropperjs++

++++ Mediaqueries ++++
-------------------------------------------------------------------*/
/****************************/
/** Imports **/
/****************************/
@import url(../vendors/bower_components/bootstrap/dist/css/bootstrap.css);
@import url(font-awesome.min.css);
@import url(animate.css);
@import url(material-design-iconic-font.min.css);
@import url(filter.css);

/*!** Reset **!*/
strong {
    font-weight: bold;
}

.form-control {
    height: 40px;
    border-radius: 0px;
}

.form-control:focus {
    border-color: #48194e;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-picker:hover {
    border-color: #48194e;
}

.ant-picker-focused {
    border-color: #48194e;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #48194e;
    background: #f3eaff;
}

.ant-pagination-item-active {
    border-color: #48194e;
    background-color: #48194e;
}

.ant-pagination-item-active a {
    color: white;
}

.ant-pagination-item a:hover {
    background-color: #48194e;
    color: white;

}

button.ant-pagination-item-link:hover {
    border-color: #48194e;
    background-color: #48194e;
    color: white;
}

.clearfix {
    overflow: hidden;
    clear: both;
    float: none;
}

a.react-tagsinput-remove {
    color: #48194e;
}

a:hover, a:active, a:focus {
    outline: 0;
    text-decoration: none
}

ul {
    list-style: none;
}

button:focus {
    border: none;
    outline: 0;
}

p.lead {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 0;
    font-weight: 400;
}

.force-UTF-8 {
    content: "¡";
}

dt {
    font-weight: 600;
}

code {
    background: transparent;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

/*!** Basic **!*/
.pa-0 {
    padding: 0px !important;
}

.pa-5 {
    padding: 5px !important;
}

.pa-10 {
    padding: 10px !important;
}

.pa-15 {
    padding: 15px !important;
}

.pa-20 {
    padding: 20px !important;
}

.pa-25 {
    padding: 25px !important;
}

.pa-30 {
    padding: 30px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.ma-0 {
    margin: 0px !important;
}

.ma-5 {
    margin: 5px !important;
}

.ma-10 {
    margin: 10px !important;
}

.ma-15 {
    margin: 15px !important;
}

.ma-20 {
    margin: 20px !important;
}

.ma-25 {
    margin: 25px !important;
}

.ma-30 {
    margin: 30px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-55 {
    margin-top: 55px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-65 {
    margin-top: 65px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-75 {
    margin-top: 75px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-85 {
    margin-top: 85px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-95 {
    margin-top: 95px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-65 {
    margin-bottom: 65px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-75 {
    margin-bottom: 75px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-85 {
    margin-bottom: 85px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-95 {
    margin-bottom: 95px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-55 {
    margin-right: 55px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.no-float {
    float: none !important;
}

.overflow-hide {
    overflow: hidden !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.bg-green, .bg-success {
    background: #48194e !important;
}

.bg-light-green {
    background: #AED581 !important;
}

.bg-dark-green {
    background: #558B2F !important;
}

.bg-xtra-light-green {
    background: #DCEDC8 !important;
}

.bg-seagreen {
    background: #007153 !important;
}

.bg-grey {
    background: #878787 !important;
}

.bg-yellow, .bg-warning {
    background: #f8b32d !important;
}

.bg-red, .bg-danger {
    background: #f33923 !important;
}

.bg-blue, .bg-primary {
    background: #2196F3 !important;
}

.bg-pink, .bg-info {
    background: #e7368d !important;
}

.bg-purple {
    background: #ac235c !important;
}

.bg-orange {
    background: #ff6300 !important;
}

.bg-gold {
    background: #e58b25 !important;
}

.bg-brown {
    background: #6d300e !important;
}

.bg-beige {
    background: #f7ce99 !important;
}

.bg-light {
    background: #fff !important;
}

.bg-dark {
    background: #212121 !important;
}

.bg-twitter {
    background: #00aced !important;
}

.txt-dark {
    color: #212121 !important;
}

.txt-grey {
    color: #878787 !important;
}

.txt-light-grey {
    color: #dedede !important;
}

.txt-info, .text-info {
    color: #e7368d !important;
}

.txt-warning, .text-warning {
    color: #f8b32d !important;
}

.txt-primary, .text-primary {
    color: #2196F3 !important;
}

.txt-success, .text-success {
    color: #0562e8 !important;
}

.txt-light-green {
    color: #AED581 !important;
}

.txt-danger, .text-danger {
    color: #f33923 !important;
}

.weight-500 {
    font-weight: 500 !important;
}

.weight-600 {
    font-weight: 600 !important;
}

.capitalize-font {
    text-transform: capitalize !important;
}

.uppercase-font {
    text-transform: uppercase !important;
}

.nonecase-font {
    text-transform: none !important;
}

.inline-block {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.table-struct {
    display: table !important;
}

.table-cell {
    display: table-cell !important;
}

.vertical-align-middle {
    vertical-align: middle !important;
}

.relative {
    position: relative !important;
}

.border-none {
    border: none !important;
}

.text-muted {
    color: #878787;
}

small {
    color: #878787;
    padding-left: 5px;
}

.truncate {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mobile-only-view {
    display: none !important;
}

hr {
    margin-top: 10px;
    margin-bottom: 35px;
    border-top: 1px solid #0562e8;
}

hr.light-grey-hr {
    border-top: 1px solid #dedede;
}

.seprator-block {
    margin-bottom: 80px;
}

/*typography*/
address .address-head {
    font-size: 15px;
    color: #212121;
    display: block;
}

.mark, mark {
    padding: 3px;
    background-color: #f33923;
    color: #fff;
}

blockquote {
    padding: 15px 20px;
    margin: 0 0 20px;
    font-size: 16px;
    border-left: 4px solid #2196F3;
    background: #f7f7f9;
    color: #212121;
}

blockquote small {
    font-size: 70%;
    text-transform: uppercase;
    padding-top: 15px;
}

/* Admin Basic */

.fixed-sidebar-left .right-nav-text {
    opacity: 1;
    -webkit-transition: opacity 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s;
    transition: opacity 0.2s ease 0s;
}

.fixed-sidebar-left .side-nav {
    border: none;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #fff;
}

.fixed-sidebar-left .side-nav li {
    width: 225px;
}

.fixed-sidebar-left .side-nav li.navigation-header>span {
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    color: #878787;
    padding: 11px 15px;
}

.fixed-sidebar-left .side-nav li.navigation-header>i {
    display: none;
    font-size: 16px;
    padding: 11px 15px;
}

.fixed-sidebar-left .side-nav li a[aria-expanded="true"]>i {
    color: #e2724c;
}

.fixed-sidebar-left .side-nav li a[aria-expanded="true"] .pull-right i:before {
    content: '\f2f8';
}

.fixed-sidebar-left .side-nav li a {
    width: 100%;
    text-transform: capitalize;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.fixed-sidebar-left .side-nav li a .pull-left>i {
    font-size: 18px;
    width: 18px;
}

.fixed-sidebar-left .side-nav li a .pull-right i {
    color: #878787;
}

.fixed-sidebar-left .side-nav>li>a {
    color: #48194e;
    padding: 7px 15px;
}

.fixed-sidebar-left .side-nav>li>a:hover {
    background: transparent;
}

.fixed-sidebar-left .side-nav>li>a:focus>i, .fixed-sidebar-left .side-nav>li>a:active>i {
    color: #e2724c;
}

.fixed-sidebar-left .side-nav>li a.active-page {
    background: rgba(33, 33, 33, 0.05);
}

.fixed-sidebar-left .side-nav>li>ul>li a {
    padding: 7px 15px 7px 51px;
    display: block;
    color: #878787;
    font-size: 14px;
}

.fixed-sidebar-left .side-nav>li>ul>li a:focus, .fixed-sidebar-left .side-nav>li>ul>li a:active {
    background: rgba(33, 33, 33, 0.05);
    color: #009dea;
}

.fixed-sidebar-left .side-nav>li>ul>li>ul>li a {
    padding-left: 62px;
    color: #878787;
}

.fixed-sidebar-left .side-nav>li>ul>li>ul>li a:hover, .fixed-sidebar-left .side-nav>li>ul>li>ul>li a:focus, .fixed-sidebar-left .side-nav>li>ul>li>ul>li a:active {
    color: #878787;
}

.fixed-sidebar-left .side-nav>li ul.collapse li a.active {
    color: #212121;
}

a.toggle-left-nav-btn, a#toggle_mobile_nav, a#toggle_mobile_search {
    color: #48194e;
    position: relative;
    line-height: 60px;
}

a.toggle-left-nav-btn i {
    font-size: 26px;
    cursor: pointer;
    vertical-align: middle;
}

a#toggle_mobile_nav {
    display: inline-block;
}

.top-fixed-nav .page-wrapper {
    margin-left: 0;
    padding-top: 134px;
}

.top-fixed-nav .fixed-sidebar-left {
    width: 100%;
    bottom: inherit;
}

.top-fixed-nav .fixed-sidebar-left:hover {
    width: 100%;
}

.top-fixed-nav .fixed-sidebar-left:hover .collapse.in {
    display: none;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv {
    overflow: visible !important;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv .slimScrollBar {
    display: none !important;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul {
    overflow: visible !important;
    width: 100% !important;
    height: auto !important;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul>li {
    width: auto;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul>li>a {
    text-align: center;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul>li>a>i {
    display: block;
    margin-bottom: 10px;
    margin-right: 0 !important;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul>li>a>.pull-right {
    display: none;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul li {
    position: relative;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul .collapse-level-1 {
    background: #ebebeb;
    height: auto !important;
    left: 0;
    position: absolute !important;
    z-index: 1;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul .collapse-level-1.two-col-list {
    width: 450px;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul .collapse-level-1 li {
    float: left;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul .collapse-level-1 li>a i:before {
    content: "\f105";
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul .collapse-level-1 li>ul {
    background: #ebebeb;
    left: 225px;
    position: absolute;
    top: 0;
    z-index: 1;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul>li:hover>ul {
    display: block;
}

.top-fixed-nav .fixed-sidebar-left>.slimScrollDiv>ul>li>ul>li:hover>ul {
    display: block;
}

.top-fixed-nav.slide-nav-toggle .page-wrapper {
    margin-left: 0;
    padding-top: 60px;
}

.top-fixed-nav.slide-nav-toggle .fixed-sidebar-left {
    top: -14px;
    width: 100%;
}

.top-fixed-nav.slide-nav-toggle .fixed-sidebar-left:hover {
    width: 100%;
}

.scrollable-nav {
    position: relative;
}

.scrollable-nav .fixed-sidebar-left, .scrollable-nav .navbar-fixed-top, .scrollable-nav .fixed-sidebar-right {
    position: absolute;
}

.scrollable-nav.box-layout .fixed-sidebar-right {
    right: 300px !important;
}

.page-wrapper {
    margin-left: 225px;
    background: #fafafa;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    left: 0;
}

.navbar.navbar-inverse.navbar-fixed-top {
    border-bottom: none;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    margin-bottom: 0;
    min-height: 66px;
    padding: 0 15px 0 0;
    background-color: #fff;
}

.fixed-sidebar-left {
    position: fixed;
    top: 66px;
    left: 0;
    width: 225px;
    margin-left: 0;
    bottom: 0;
    z-index: 1030;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
    border-right: 1px solid #dedede;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.fixed-sidebar-left .side-nav>li>a:hover, .fixed-sidebar-left .side-nav>li>a:focus, .fixed-sidebar-left .side-nav>li>a:active {
    color: #aa15ba;
}

.fixed-sidebar-left .side-nav li a:hover, .fixed-sidebar-left .side-nav li a:focus, .fixed-sidebar-left .side-nav li a:active {
    background: rgba(255, 255, 255, 0.1);
}

.fixed-sidebar-left .side-nav li a.active-page {
    background: rgba(255, 255, 255, 0.1);
}

.fixed-sidebar-left .side-nav li a[aria-expanded="true"] {
    background: rgba(255, 255, 255, 0.1);
}

.fixed-sidebar-left hr.light-grey-hr {
    border-color: rgba(255, 255, 255, 0.1);
}

/* Top Navigation */
.navbar.navbar-inverse {
    background: #fff;
}



.navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 226px;
    /* padding-left: 5px; */
    height: 66px;
    background-color: #48194e;
    -webkit-transition: 0.4s ease;
    -o-transition: 0.4s ease;
    transition: 0.4s ease;
    overflow: hidden;
}

.navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap {
    width: 225px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-img {
    margin-right: 0px;
    position: relative;
    top: 0px;
    left: -15px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    color: #fff;
    font-size: 23px;
    position: relative;
    margin-left: 5px;
    top: 3px;
}

.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.btn, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .wizard>.actions .disabled .input-group-btn>a, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .wizard>.actions .input-group-btn>a, .wizard>.actions .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>a, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .dt-buttons .input-group-btn>.dt-button, .dt-buttons .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.dt-button, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn>button, .tablesaw-sortable th.tablesaw-sortable-head .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .sweet-alert .input-group-btn>button, .sweet-alert .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .owl-theme .owl-nav .input-group-btn>[class*="owl-"], .owl-theme .owl-nav .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>[class*="owl-"], .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button.fc-month-button.fc-state-default.fc-corner-left,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button.fc-agendaWeek-button, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.fc-prev-button, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.fc-next-button, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.fc-today-button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 0;
}

.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.btn i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .wizard>.actions .input-group-btn>a i, .wizard>.actions .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>a i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .dt-buttons .input-group-btn>.dt-button i, .dt-buttons .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.dt-button i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn>button i, .tablesaw-sortable th.tablesaw-sortable-head .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .sweet-alert .input-group-btn>button i, .sweet-alert .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .owl-theme .owl-nav .input-group-btn>[class*="owl-"] i, .owl-theme .owl-nav .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>[class*="owl-"] i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button.fc-month-button.fc-state-default.fc-corner-left i,
.navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>button.fc-agendaWeek-button i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.fc-prev-button i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.fc-next-button i, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn>.fc-today-button i {
    color: #878787;
    font-size: 20px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li.dropdown {
    position: relative;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li>a {
    color: #48194e;
    background: transparent !important;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    padding: 0 9px;
    line-height: 66px;
    cursor: pointer !important;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li>a:active, .navbar.navbar-inverse.navbar-fixed-top .nav>li>a:hover, .navbar.navbar-inverse.navbar-fixed-top .nav>li>a:focus {
    color: #aa15ba;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li>a .user-auth-img {
    height: 30px;
    width: 32px;
    vertical-align: middle;
    position: relative;
    top: -2px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown {
    width: 400px;
    cursor: default;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .notification-box-head-wrap .notification-box-head {
    font-size: 16px;
    color: #212121;
    display: block;
    text-transform: capitalize;
    padding: 7px 15px 10px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .notification-box-head-wrap .clear-notifications {
    padding: 12px 15px 10px;
    text-transform: uppercase;
    font-size: 11px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .notification-box-bottom-wrap .read-all {
    padding: 5px 15px 0;
    font-size: 13px;
    color: #878787;
    text-transform: capitalize;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline {
    position: relative;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item {
    margin-left: 0;
    border-left: none;
    padding: 7px 15px 0;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item:hover {
    background: rgba(33, 33, 33, 0.05);
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item:before {
    display: none;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item>a {
    display: block;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item .icon {
    height: 44px;
    position: absolute;
    text-align: center;
    width: 44px;
    display: table;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item .icon i {
    font-size: 24px;
    vertical-align: middle;
    display: table-cell;
    color: #fff;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-content {
    margin-left: 60px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-content>.head-notifications {
    width: 230px;
    font-size: 13px;
    font-weight: 500;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-content .notifications-time {
    color: #878787;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown .streamline .sl-item .sl-content>p {
    font-size: 13px;
    color: #878787;
}

.navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.user-auth-dropdown {
    min-width: 175px;
    text-transform: capitalize;
}

.navbar.navbar-inverse.navbar-fixed-top .nav .open>a {
    background: #fff;
}

.navbar.navbar-inverse.navbar-fixed-top .nav .open>a:focus, .navbar.navbar-inverse.navbar-fixed-top .nav .open>a:hover, .navbar.navbar-inverse.navbar-fixed-top .nav .open>a:active {
    background: #fff;
}

.navbar.navbar-inverse.navbar-fixed-top .nav .open>.dropdown-menu.mega-menu {
    opacity: 1;
    visibility: visible;
    height: auto;
    overflow: auto;
}

.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav>li {
    display: inline-block;
    float: left;
}

.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav>li>a i.top-nav-icon {
    font-size: 24px;
    vertical-align: middle;
}

.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav>li>a .top-nav-icon-badge {
    background-color: #f80000 !important;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 16px;
    width: 16px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav.navbar-right {
    margin-right: 0;
}

.navbar.navbar-inverse.navbar-fixed-top .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
}

.slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 60px;
}

.slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    opacity: 0;
    visibility: hidden;
}

.slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header {
    width: 225px;
}

.slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
    opacity: 1;
    visibility: visible;
}

.slide-nav-toggle .fixed-sidebar-left {
    width: 60px;
}

.slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header>span {
    display: none;
}

.slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header>i {
    display: block;
}

.slide-nav-toggle .fixed-sidebar-left .collapse.in {
    display: none;
}

.slide-nav-toggle .fixed-sidebar-left:hover {
    width: 225px;
}

.slide-nav-toggle .fixed-sidebar-left:hover .side-nav li.navigation-header>span {
    display: block;
}

.slide-nav-toggle .fixed-sidebar-left:hover .side-nav li.navigation-header>i {
    display: none;
}

.slide-nav-toggle .fixed-sidebar-left:hover .collapse.in {
    display: block;
}

.slide-nav-toggle .fixed-sidebar-left:hover .right-nav-text {
    opacity: 1;
}

.slide-nav-toggle .fixed-sidebar-left .right-nav-text {
    opacity: 0;
}

.slide-nav-toggle .page-wrapper {
    margin-left: 60px;
}

.card-view {
    background: #fff;
    margin-bottom: 20px;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 2px 8px 0 rgba(167, 175, 183, 0.33);
    box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 2px 8px 0 rgba(167, 175, 183, 0.33);
    padding: 15px 15px 0;
    margin-left: -5px;
    margin-right: -5px;
}

.card-view.panel>.panel-heading {
    border: none;
    color: inherit;
    border-radius: 0;
    margin: -15px -15px 0;
    padding: 20px 15px;
}

.card-view.panel>.panel-heading.small-panel-heading {
    padding: 11px 15px;
}

.card-view.panel>.panel-heading .head-overlay {
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.card-view.panel>.panel-heading .pull-right button {
    background: #f2f2f2;
}

.card-view.panel .panel-body {
    padding: 10px 0;
}

.card-view.panel.panel-default>.panel-heading {
    background: #f2f2f2;
}

.card-view.panel.panel-default.border-panel>.panel-heading, .card-view.panel.panel-default.panel-tabs>.panel-heading {
    border-bottom: 1px solid #dedede;
}

.card-view.panel.panel-default.panel-tabs>.panel-heading {
    padding-bottom: 0;
}

.card-view.panel.panel-default.panel-tabs .nav-tabs {
    border-bottom: none;
}

.card-view.panel.panel-default.panel-tabs .nav-tabs>li>a {
    padding: 0 5px 12px;
    float: left;
}

.card-view.panel.fullscreen {
    height: 100%;
    left: 0;
    margin: 0;
    position: fixed;
    overflow-y: auto;
    top: 0;
    width: 100%;
    z-index: 1040;
}

.card-view.panel.fullscreen>.panel-heading .pull-right>a, .card-view.panel.fullscreen>.panel-heading .pull-right .dropdown {
    opacity: 0;
    visibility: hidden;
}

.card-view.panel.fullscreen>.panel-heading .pull-right>a.full-screen, .card-view.panel.fullscreen>.panel-heading .pull-right .dropdown.full-screen {
    opacity: 1;
    visibility: visible;
}

.card-view.panel.fullscreen>.panel-heading .pull-right>a.full-screen i.zmdi-fullscreen::before, .card-view.panel.fullscreen>.panel-heading .pull-right .dropdown.full-screen i.zmdi-fullscreen::before {
    content: "\f16c";
}

.heading-bg {
    height: 67px;
    margin: 0 -20px 10px;
    padding: 13px 0;
}

.heading-bg h5 {
    padding-top: 6px;
}

.font-icon-detail span {
    border-radius: 0 !important;
}

/*!*Form*!*/
/*.form-control, .wizard .content > .body input, .mce-floatpanel .mce-textbox, .mce-floatpanel .mce-btn, .dataTables_wrapper .dataTables_filter input,*/
/*.dataTables_wrapper .dataTables_length select, .jsgrid-cell input, .jsgrid-cell select, .tablesaw-bar .btn-select select, .dd-handle, .dd3-content, .app-search .form-control:focus,*/
/*.app-search .select2-container--default .select2-selection--single:focus,.select2-container--default .app-search .select2-selection--single:focus,*/
/*.app-search .select2-selection.select2-selection--multiple:focus, .app-search .mce-floatpanel .mce-textbox:focus, .mce-floatpanel .app-search .mce-textbox:focus,*/
/*.app-search .mce-floatpanel .mce-btn:focus, .mce-floatpanel .app-search .mce-btn:focus, .app-search .dataTables_wrapper .dataTables_filter input:focus, .dataTables_wrapper*/
/*.dataTables_filter .app-search input:focus, .app-search .dataTables_wrapper .dataTables_length select:focus, .dataTables_wrapper .dataTables_length .app-search select:focus,*/
/*.app-search .jsgrid-cell input:focus, .jsgrid-cell .app-search input:focus, .app-search .jsgrid-cell select:focus, .jsgrid-cell .app-search select:focus,*/
/*.app-search .tablesaw-bar .btn-select select:focus, .tablesaw-bar .btn-select .app-search select:focus, .app-search .dd-handle:focus, .app-search .dd3-content:focus {*/
/*border: 1px solid rgba(33, 33, 33, 0.12);*/
/*border-radius: 0;*/
/*background-color: #fff;*/
/*box-shadow: none;*/
/*color: #212121;*/
/*height: 42px;*/
/*}*/

/*.form-control:focus, .select2-container--default .select2-selection--single .select2-selection__rendered:focus,*/
/*.select2-container--default .select2-selection--single:focus, .select2-selection.select2-selection--multiple:focus, .wizard .content > .body input:focus,*/
/*.mce-floatpanel .mce-textbox:focus, .mce-floatpanel .mce-btn:focus, .dataTables_wrapper .dataTables_filter input:focus, .dataTables_wrapper .dataTables_length select:focus,*/
/*.jsgrid-cell input:focus, .jsgrid-cell select:focus, .tablesaw-bar .btn-select select:focus, .dd-handle:focus, .dd3-content:focus, .app-search .form-control:focus,*/
/*.app-search .jsgrid-cell input:focus, .jsgrid-cell .app-search input:focus, .app-search .jsgrid-cell select:focus, .jsgrid-cell .app-search select:focus,*/
/*.app-search .dd-handle:focus, .app-search .dd3-content:focus {*/
/*border-color: rgba(33, 33, 33, 0.3);*/
/*box-shadow: none;*/
/*outline: 0 none;*/
/*}*/

/*.form-control::-webkit-input-placeholder, .select2-container--default .select2-selection--single .select2-selection__rendered::-webkit-input-placeholder,*/
/*.select2-container--default .select2-selection--single::-webkit-input-placeholder, .select2-selection.select2-selection--multiple::-webkit-input-placeholder,*/
/*.wizard .content > .body input::-webkit-input-placeholder, .mce-floatpanel .mce-textbox::-webkit-input-placeholder, .mce-floatpanel .mce-btn::-webkit-input-placeholder,*/
/*.dataTables_wrapper .dataTables_filter input::-webkit-input-placeholder, .dataTables_wrapper .dataTables_length select::-webkit-input-placeholder,*/
/*.jsgrid-cell input::-webkit-input-placeholder, .jsgrid-cell select::-webkit-input-placeholder, .tablesaw-bar .btn-select select::-webkit-input-placeholder,*/
/*.dd-handle::-webkit-input-placeholder, .dd3-content::-webkit-input-placeholder, .app-search .form-control:focus::-webkit-input-placeholder,*/
/*.app-search .select2-container--default .select2-selection--single:focus::-webkit-input-placeholder,*/
/*.select2-container--default .app-search .select2-selection--single:focus::-webkit-input-placeholder,*/
/*.app-search .select2-selection.select2-selection--multiple:focus::-webkit-input-placeholder, .app-search .mce-floatpanel .mce-textbox:focus::-webkit-input-placeholder,*/
/*.mce-floatpanel .app-search .mce-textbox:focus::-webkit-input-placeholder, .app-search .mce-floatpanel .mce-btn:focus::-webkit-input-placeholder,*/
/*.mce-floatpanel .app-search .mce-btn:focus::-webkit-input-placeholder, .app-search .dataTables_wrapper .dataTables_filter input:focus::-webkit-input-placeholder,*/
/*.dataTables_wrapper .dataTables_filter .app-search input:focus::-webkit-input-placeholder,*/
/*.app-search .dataTables_wrapper .dataTables_length select:focus::-webkit-input-placeholder,*/
/*.dataTables_wrapper .dataTables_length .app-search select:focus::-webkit-input-placeholder, .app-search .jsgrid-cell input:focus::-webkit-input-placeholder,*/
/*.jsgrid-cell .app-search input:focus::-webkit-input-placeholder, .app-search .jsgrid-cell select:focus::-webkit-input-placeholder,*/
/*.jsgrid-cell .app-search select:focus::-webkit-input-placeholder, .app-search .tablesaw-bar .btn-select select:focus::-webkit-input-placeholder,*/
/*.tablesaw-bar .btn-select .app-search select:focus::-webkit-input-placeholder, .app-search .dd-handle:focus::-webkit-input-placeholder,*/
/*.app-search .dd3-content:focus::-webkit-input-placeholder {*/
/*text-transform: capitalize;*/
/*}*/

/*.form-control:-moz-placeholder, .select2-container--default .select2-selection--single .select2-selection__rendered:-moz-placeholder,*/
/*.select2-container--default .select2-selection--single:-moz-placeholder, .select2-selection.select2-selection--multiple:-moz-placeholder,*/
/*.wizard .content > .body input:-moz-placeholder, .mce-floatpanel .mce-textbox:-moz-placeholder, .mce-floatpanel .mce-btn:-moz-placeholder,*/
/*.dataTables_wrapper .dataTables_filter input:-moz-placeholder, .dataTables_wrapper .dataTables_length select:-moz-placeholder,*/
/*.jsgrid-cell input:-moz-placeholder, .jsgrid-cell select:-moz-placeholder, .tablesaw-bar .btn-select select:-moz-placeholder, .dd-handle:-moz-placeholder,*/
/*.dd3-content:-moz-placeholder, .app-search .form-control:-moz-placeholder:focus, .app-search .jsgrid-cell input:-moz-placeholder:focus,*/
/*.jsgrid-cell .app-search input:-moz-placeholder:focus, .app-search .jsgrid-cell select:-moz-placeholder:focus, .jsgrid-cell .app-search select:-moz-placeholder:focus,*/
/*.app-search .dd-handle:-moz-placeholder:focus, .app-search .dd3-content:-moz-placeholder:focus {*/
/*text-transform: capitalize;*/
/*}*/

/*.form-control::-moz-placeholder, .select2-container--default .select2-selection--single .select2-selection__rendered::-moz-placeholder,*/
/*.select2-container--default .select2-selection--single::-moz-placeholder, .select2-selection.select2-selection--multiple::-moz-placeholder,*/
/*.wizard .content > .body input::-moz-placeholder, .mce-floatpanel .mce-textbox::-moz-placeholder, .mce-floatpanel .mce-btn::-moz-placeholder,*/
/*.dataTables_wrapper .dataTables_filter input::-moz-placeholder, .dataTables_wrapper .dataTables_length select::-moz-placeholder,*/
/*.jsgrid-cell input::-moz-placeholder, .jsgrid-cell select::-moz-placeholder, .tablesaw-bar .btn-select select::-moz-placeholder,*/
/*.dd-handle::-moz-placeholder, .dd3-content::-moz-placeholder, .app-search .form-control:focus::-moz-placeholder,*/
/*.app-search .select2-container--default .select2-selection--single:focus::-moz-placeholder,*/
/*.select2-container--default .app-search .select2-selection--single:focus::-moz-placeholder,*/
/*.app-search .select2-selection.select2-selection--multiple:focus::-moz-placeholder, .app-search .mce-floatpanel .mce-textbox:focus::-moz-placeholder,*/
/*.mce-floatpanel .app-search .mce-textbox:focus::-moz-placeholder, .app-search .mce-floatpanel .mce-btn:focus::-moz-placeholder,*/
/*.mce-floatpanel .app-search .mce-btn:focus::-moz-placeholder, .app-search .dataTables_wrapper .dataTables_filter input:focus::-moz-placeholder,*/
/*.dataTables_wrapper .dataTables_filter .app-search input:focus::-moz-placeholder, .app-search .dataTables_wrapper .dataTables_length select:focus::-moz-placeholder,*/
/*.dataTables_wrapper .dataTables_length .app-search select:focus::-moz-placeholder, .app-search .jsgrid-cell input:focus::-moz-placeholder,*/
/*.jsgrid-cell .app-search input:focus::-moz-placeholder, .app-search .jsgrid-cell select:focus::-moz-placeholder, .jsgrid-cell .app-search select:focus::-moz-placeholder,*/
/*.app-search .tablesaw-bar .btn-select select:focus::-moz-placeholder, .tablesaw-bar .btn-select .app-search select:focus::-moz-placeholder,*/
/*.app-search .dd-handle:focus::-moz-placeholder, .app-search .dd3-content:focus::-moz-placeholder {*/
/*text-transform: capitalize;*/
/*}*/

/*.form-control:-ms-input-placeholder, .select2-container--default .select2-selection--single .select2-selection__rendered:-ms-input-placeholder,*/
/*.select2-container--default .select2-selection--single:-ms-input-placeholder, .select2-selection.select2-selection--multiple:-ms-input-placeholder,*/
/*.wizard .content > .body input:-ms-input-placeholder, .mce-floatpanel .mce-textbox:-ms-input-placeholder, .mce-floatpanel .mce-btn:-ms-input-placeholder,*/
/*.dataTables_wrapper .dataTables_filter input:-ms-input-placeholder, .dataTables_wrapper .dataTables_length select:-ms-input-placeholder,*/
/*.jsgrid-cell input:-ms-input-placeholder, .jsgrid-cell select:-ms-input-placeholder, .tablesaw-bar .btn-select select:-ms-input-placeholder,*/
/*.dd-handle:-ms-input-placeholder, .dd3-content:-ms-input-placeholder, .app-search .form-control:-ms-input-placeholder:focus,*/
/*.app-search .jsgrid-cell input:-ms-input-placeholder:focus, .jsgrid-cell .app-search input:-ms-input-placeholder:focus,*/
/*.app-search .jsgrid-cell select:-ms-input-placeholder:focus, .jsgrid-cell .app-search select:-ms-input-placeholder:focus,*/
/*.app-search .dd-handle:-ms-input-placeholder:focus, .app-search .dd3-content:-ms-input-placeholder:focus {*/
/*text-transform: capitalize;*/
/*}*/

/*.form-control.filled-input, .select2-container--default .select2-selection--single .filled-input.select2-selection__rendered,*/
/*.select2-container--default .filled-input.select2-selection--single, .filled-input.select2-selection.select2-selection--multiple,*/
/*.wizard .content > .body input.filled-input, .mce-floatpanel .filled-input.mce-textbox, .mce-floatpanel .filled-input.mce-btn,*/
/*.dataTables_wrapper .dataTables_filter input.filled-input, .dataTables_wrapper .dataTables_length select.filled-input, .jsgrid-cell input.filled-input,*/
/*.jsgrid-cell select.filled-input, .tablesaw-bar .btn-select select.filled-input, .filled-input.dd-handle, .filled-input.dd3-content,*/
/*.app-search .filled-input.form-control:focus, .app-search .jsgrid-cell input.filled-input:focus, .jsgrid-cell .app-search input.filled-input:focus,*/
/*.app-search .jsgrid-cell select.filled-input:focus, .jsgrid-cell .app-search select.filled-input:focus, .app-search .filled-input.dd-handle:focus,*/
/*.app-search .filled-input.dd3-content:focus {*/
/*background: #f7f7f9;*/
/*border: none;*/
/*}*/

/*.form-control.filled-input:focus, .select2-container--default .select2-selection--single .filled-input.select2-selection__rendered:focus,*/
/*.select2-container--default .filled-input.select2-selection--single:focus, .filled-input.select2-selection.select2-selection--multiple:focus,*/
/*.wizard .content > .body input.filled-input:focus, .mce-floatpanel .filled-input.mce-textbox:focus, .mce-floatpanel .filled-input.mce-btn:focus,*/
/*.dataTables_wrapper .dataTables_filter input.filled-input:focus, .dataTables_wrapper .dataTables_length select.filled-input:focus,*/
/*.jsgrid-cell input.filled-input:focus, .jsgrid-cell select.filled-input:focus, .tablesaw-bar .btn-select select.filled-input:focus, .filled-input.dd-handle:focus,*/
/*.filled-input.dd3-content:focus {*/
/*background: rgba(247, 247, 249, 0.8);*/
/*}*/

/*.form-control.rounded-input, .select2-container--default .select2-selection--single .rounded-input.select2-selection__rendered,*/
/*.select2-container--default .rounded-input.select2-selection--single, .rounded-input.select2-selection.select2-selection--multiple,*/
/*.wizard .content > .body input.rounded-input, .mce-floatpanel .rounded-input.mce-textbox, .mce-floatpanel .rounded-input.mce-btn,*/
/*.dataTables_wrapper .dataTables_filter input.rounded-input, .dataTables_wrapper .dataTables_length select.rounded-input, .jsgrid-cell input.rounded-input,*/
/*.jsgrid-cell select.rounded-input, .tablesaw-bar .btn-select select.rounded-input, .rounded-input.dd-handle, .rounded-input.dd3-content,*/
/*.app-search .rounded-input.form-control:focus, .app-search .jsgrid-cell input.rounded-input:focus, .jsgrid-cell .app-search input.rounded-input:focus,*/
/*.app-search .jsgrid-cell select.rounded-input:focus, .jsgrid-cell .app-search select.rounded-input:focus, .app-search .rounded-input.dd-handle:focus,*/
/*.app-search .rounded-input.dd3-content:focus {*/
/*border-radius: 60px;*/
/*}*/

/*.form-control.rounded-outline-input, .select2-container--default .select2-selection--single .rounded-outline-input.select2-selection__rendered,*/
/*.select2-container--default .rounded-outline-input.select2-selection--single, .rounded-outline-input.select2-selection.select2-selection--multiple,*/
/*.wizard .content > .body input.rounded-outline-input, .mce-floatpanel .rounded-outline-input.mce-textbox, .mce-floatpanel .rounded-outline-input.mce-btn,*/
/*.dataTables_wrapper .dataTables_filter input.rounded-outline-input, .dataTables_wrapper .dataTables_length select.rounded-outline-input,*/
/*.jsgrid-cell input.rounded-outline-input, .jsgrid-cell select.rounded-outline-input, .tablesaw-bar .btn-select select.rounded-outline-input,*/
/*.rounded-outline-input.dd-handle, .rounded-outline-input.dd3-content, .app-search .rounded-outline-input.form-control:focus,*/
/*.app-search .jsgrid-cell input.rounded-outline-input:focus, .jsgrid-cell .app-search input.rounded-outline-input:focus,*/
/*.app-search .jsgrid-cell select.rounded-outline-input:focus, .jsgrid-cell .app-search select.rounded-outline-input:focus,*/
/*.app-search .rounded-outline-input.dd-handle:focus, .app-search .rounded-outline-input.dd3-content:focus {*/
/*background: transparent;*/
/*border: 1px solid #dedede;*/
/*}*/

/*.input-group-addon.btn, .wizard > .actions a.input-group-addon, .dt-buttons .input-group-addon.dt-button, .tablesaw-sortable th.tablesaw-sortable-head button.input-group-addon,*/
/*.sweet-alert button.input-group-addon, .owl-theme .owl-nav .input-group-addon[class*="owl-"], button.input-group-addon.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*button.input-group-addon.fc-month-button.fc-state-default.fc-corner-left,*/
/*button.input-group-addon.fc-agendaWeek-button, .input-group-addon.fc-prev-button,*/
/*.input-group-addon.fc-next-button, .input-group-addon.fc-today-button {*/
/*color: #fff;*/
/*}*/

/*.checkbox, .radio {*/
/*padding-top: 5px;*/
/*padding-bottom: 5px;*/
/*min-height: 27px;*/
/*margin-top: 0;*/
/*margin-bottom: 0;*/
/*}*/

/*.form-control-feedback {*/
/*line-height: 42px;*/
/*height: 42px;*/
/*}*/

/*.select2-container--default .select2-selection--single .select2-selection__rendered,*/
/*.select2-container--default .select2-results__option[aria-selected="true"] {*/
/*padding-left: 12px;*/
/*}*/

/*.select2-container--default .select2-selection--single .select2-selection__rendered,*/
/*.select2-container--default .select2-selection--single {*/
/*line-height: 40px;*/
/*}*/

/*.select2-container--default .select2-selection--single {*/
/*border: none;*/
/*}*/

/*.select2-container--default {*/
/*width: 100% !important;*/
/*}*/

/*.select2-container--default .select2-results__group, .select2-container--default .select2-results__options--nested li {*/
/*padding-left: 12px;*/
/*}*/

/*.select2-container--default.select2-container--open .select2-selection__rendered {*/
/*border-color: #878787;*/
/*}*/

/*.select2-container--default .select2-selection__arrow {*/
/*height: 40px !important;*/
/*}*/

/*.select2-container .select2-selection--multiple .select2-selection__rendered {*/
/*padding-left: 12px;*/
/*line-height: 40px;*/
/*}*/

/*.select2-container .select2-selection--multiple .select2-search__field {*/
/*margin-top: 0;*/
/*}*/

/*.select2-container--default .select2-selection--multiple .select2-selection__choice {*/
/*border-radius: 0;*/
/*color: #fff;*/
/*padding: 8px 10px;*/
/*margin-bottom: 10px;*/
/*margin-right: 5px;*/
/*display: inline-block;*/
/*text-align: center;*/
/*vertical-align: baseline;*/
/*white-space: nowrap;*/
/*background: #0562e8;*/
/*border: none;*/
/*line-height: 10px;*/
/*font-size: 12px;*/
/*}*/

/*.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {*/
/*color: #fff;*/
/*}*/

/*.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {*/
/*width: 100%;*/
/*}*/

/*.bootstrap-select .dropdown-toggle:focus, .bootstrap-select .dropdown-toggle:active {*/
/*outline: none !important;*/
/*}*/

/*.bootstrap-select .btn.dropdown-toggle, .bootstrap-select .wizard > .actions a.dropdown-toggle, .wizard > .actions .bootstrap-select a.dropdown-toggle,*/
/*.bootstrap-select .dt-buttons .dropdown-toggle.dt-button, .dt-buttons .bootstrap-select .dropdown-toggle.dt-button,*/
/*.bootstrap-select .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle, .tablesaw-sortable th.tablesaw-sortable-head .bootstrap-select button.dropdown-toggle,*/
/*.bootstrap-select .sweet-alert button.dropdown-toggle, .sweet-alert .bootstrap-select button.dropdown-toggle,*/
/*.bootstrap-select .owl-theme .owl-nav .dropdown-toggle[class*="owl-"], .owl-theme .owl-nav .bootstrap-select .dropdown-toggle[class*="owl-"],*/
/*.bootstrap-select button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right, .bootstrap-select button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,*/
/*.bootstrap-select button.dropdown-toggle.fc-agendaWeek-button, .bootstrap-select .dropdown-toggle.fc-prev-button, .bootstrap-select .dropdown-toggle.fc-next-button,*/
/*.bootstrap-select .dropdown-toggle.fc-today-button {*/
/*box-shadow: none;*/
/*border: 1px solid rgba(33, 33, 33, 0.12);*/
/*}*/

/*.bootstrap-select .btn .filter-option, .bootstrap-select .wizard > .actions a .filter-option, .wizard > .actions .bootstrap-select a .filter-option,*/
/*.bootstrap-select .dt-buttons .dt-button .filter-option, .dt-buttons .bootstrap-select .dt-button .filter-option,*/
/*.bootstrap-select .tablesaw-sortable th.tablesaw-sortable-head button .filter-option, .tablesaw-sortable th.tablesaw-sortable-head .bootstrap-select button .filter-option,*/
/*.bootstrap-select .sweet-alert button .filter-option, .sweet-alert .bootstrap-select button .filter-option, .bootstrap-select .owl-theme .owl-nav [class*="owl-"] .filter-option,*/
/*.owl-theme .owl-nav .bootstrap-select [class*="owl-"] .filter-option, .bootstrap-select button.fc-agendaDay-button.fc-state-default.fc-corner-right .filter-option,*/
/*.bootstrap-select button.fc-month-button.fc-state-default.fc-corner-left .filter-option, .bootstrap-select button.fc-agendaWeek-button .filter-option,*/
/*.bootstrap-select .fc-prev-button .filter-option, .bootstrap-select .fc-next-button .filter-option, .bootstrap-select .fc-today-button .filter-option {*/
/*color: #212121;*/
/*}*/

/*.sm-bootstrap-select .bootstrap-select .btn.dropdown-toggle, .sm-bootstrap-select .bootstrap-select .wizard > .actions a.dropdown-toggle,*/
/*.wizard > .actions .sm-bootstrap-select .bootstrap-select a.dropdown-toggle, .sm-bootstrap-select .bootstrap-select .dt-buttons .dropdown-toggle.dt-button,*/
/*.dt-buttons .sm-bootstrap-select .bootstrap-select .dropdown-toggle.dt-button, .sm-bootstrap-select .bootstrap-select .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,*/
/*.tablesaw-sortable th.tablesaw-sortable-head .sm-bootstrap-select .bootstrap-select button.dropdown-toggle, .sm-bootstrap-select .bootstrap-select .sweet-alert button.dropdown-toggle,*/
/*.sweet-alert .sm-bootstrap-select .bootstrap-select button.dropdown-toggle, .sm-bootstrap-select .bootstrap-select .owl-theme .owl-nav .dropdown-toggle[class*="owl-"],*/
/*.owl-theme .owl-nav .sm-bootstrap-select .bootstrap-select .dropdown-toggle[class*="owl-"],*/
/*.sm-bootstrap-select .bootstrap-select button.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.sm-bootstrap-select .bootstrap-select button.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,*/
/*.sm-bootstrap-select .bootstrap-select button.dropdown-toggle.fc-agendaWeek-button, .sm-bootstrap-select .bootstrap-select .dropdown-toggle.fc-prev-button,*/
/*.sm-bootstrap-select .bootstrap-select .dropdown-toggle.fc-next-button, .sm-bootstrap-select .bootstrap-select .dropdown-toggle.fc-today-button {*/
/*font-size: 12px;*/
/*height: 27px;*/
/*padding: 3px 25px 3px 15px;*/
/*}*/

/*.sm-bootstrap-select .bootstrap-select .dropdown-menu li a span.text {  font-size: 12px; }*/

/*.switchery {*/
/*margin-right: 10px;*/
/*margin-top: 15px;*/
/*}*/

/*.no-margin-switcher .switchery {  margin-top: 0; }*/

/*.bootstrap-touchspin .input-group-btn-vertical > .btn, .bootstrap-touchspin .wizard > .actions .disabled .input-group-btn-vertical > a,*/
/*.bootstrap-touchspin .wizard > .actions .input-group-btn-vertical > a, .wizard > .actions .bootstrap-touchspin .input-group-btn-vertical > a,*/
/*.bootstrap-touchspin .dt-buttons .input-group-btn-vertical > .dt-button, .dt-buttons .bootstrap-touchspin .input-group-btn-vertical > .dt-button,*/
/*.bootstrap-touchspin .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn-vertical > button,*/
/*.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-touchspin .input-group-btn-vertical > button, .bootstrap-touchspin .sweet-alert .input-group-btn-vertical > button,*/
/*.sweet-alert .bootstrap-touchspin .input-group-btn-vertical > button,*/
/*.bootstrap-touchspin .owl-theme .owl-nav .input-group-btn-vertical > [class*="owl-"],*/
/*.owl-theme .owl-nav .bootstrap-touchspin .input-group-btn-vertical > [class*="owl-"],*/
/*.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.bootstrap-touchspin .input-group-btn-vertical > button.fc-month-button.fc-state-default.fc-corner-left,*/
/*.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaWeek-button,*/
/*.bootstrap-touchspin .input-group-btn-vertical > .fc-prev-button, .bootstrap-touchspin .input-group-btn-vertical > .fc-next-button,*/
/*.bootstrap-touchspin .input-group-btn-vertical > .fc-today-button {*/
/*border-radius: 0;*/
/*padding: 10px;*/
/*}*/

/*.bootstrap-touchspin .input-group-btn-vertical > .btn i, .bootstrap-touchspin .wizard > .actions .input-group-btn-vertical > a i,*/
/*.wizard > .actions .bootstrap-touchspin .input-group-btn-vertical > a i, .bootstrap-touchspin .dt-buttons .input-group-btn-vertical > .dt-button i,*/
/*.dt-buttons .bootstrap-touchspin .input-group-btn-vertical > .dt-button i, .bootstrap-touchspin .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn-vertical > button i,*/
/*.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-touchspin .input-group-btn-vertical > button i, .bootstrap-touchspin .sweet-alert .input-group-btn-vertical > button i,*/
/*.sweet-alert .bootstrap-touchspin .input-group-btn-vertical > button i, .bootstrap-touchspin .owl-theme .owl-nav .input-group-btn-vertical > [class*="owl-"] i,*/
/*.owl-theme .owl-nav .bootstrap-touchspin .input-group-btn-vertical > [class*="owl-"] i,*/
/*.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaDay-button.fc-state-default.fc-corner-right i,*/
/*.bootstrap-touchspin .input-group-btn-vertical > button.fc-month-button.fc-state-default.fc-corner-left i,*/
/*.bootstrap-touchspin .input-group-btn-vertical > button.fc-agendaWeek-button i, .bootstrap-touchspin .input-group-btn-vertical > .fc-prev-button i,*/
/*.bootstrap-touchspin .input-group-btn-vertical > .fc-next-button i, .bootstrap-touchspin .input-group-btn-vertical > .fc-today-button i {*/
/*top: 6px;*/
/*}*/

/*.bootstrap-touchspin .btn.btn-default.dropdown-toggle, .bootstrap-touchspin .wizard > .actions a.btn-default.dropdown-toggle,*/
/*.wizard > .actions .bootstrap-touchspin a.btn-default.dropdown-toggle, .bootstrap-touchspin .dt-buttons .btn-default.dropdown-toggle.dt-button,*/
/*.dt-buttons .bootstrap-touchspin .btn-default.dropdown-toggle.dt-button, .bootstrap-touchspin .tablesaw-sortable th.tablesaw-sortable-head button.dropdown-toggle,*/
/*.tablesaw-sortable th.tablesaw-sortable-head .bootstrap-touchspin button.dropdown-toggle, .bootstrap-touchspin .sweet-alert button.btn-default.dropdown-toggle,*/
/*.sweet-alert .bootstrap-touchspin button.btn-default.dropdown-toggle, .bootstrap-touchspin .owl-theme .owl-nav .dropdown-toggle[class*="owl-"],*/
/*.owl-theme .owl-nav .bootstrap-touchspin .dropdown-toggle[class*="owl-"], .bootstrap-touchspin button.btn-default.dropdown-toggle.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.bootstrap-touchspin button.btn-default.dropdown-toggle.fc-month-button.fc-state-default.fc-corner-left,*/
/*.bootstrap-touchspin button.btn-default.dropdown-toggle.fc-agendaWeek-button, .bootstrap-touchspin .btn-default.dropdown-toggle.fc-prev-button,*/
/*.bootstrap-touchspin .btn-default.dropdown-toggle.fc-next-button, .bootstrap-touchspin .btn-default.dropdown-toggle.fc-today-button {*/
/*color: #fff !important;*/
/*}*/

/*.ms-container .ms-list {*/
/*border: 1px solid #dedede;*/
/*border-radius: 0;*/
/*box-shadow: none;*/
/*}*/

/*.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover {*/
/*background: #0562e8;*/
/*}*/

/*.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {*/
/*background: #0562e8;*/
/*border-radius: 0;*/
/*}*/

/*.bootstrap-switch {*/
/*border-radius: 0;*/
/*border-color: #dedede;*/
/*box-shadow: none;*/
/*}*/

/*.bootstrap-switch.bootstrap-switch-focused {*/
/*border-color: #dedede;*/
/*box-shadow: none;*/
/*}*/

/*.bootstrap-tagsinput {*/
/*border: 1px solid #dedede;*/
/*border-radius: 0;*/
/*box-shadow: none;*/
/*color: #878787;*/
/*}*/

/*.bootstrap-tagsinput .tag {*/
/*border-radius: 0;*/
/*color: white;*/
/*background: #0562e8;*/
/*}*/

/*.tag.label, .tag.jvectormap-tip {*/
/*padding: 8px 10px;*/
/*margin-bottom: 10px;*/
/*margin-right: 5px;*/
/*display: inline-block;*/
/*font-size: 12px;*/
/*font-weight: 400;*/
/*}*/

/*.editableform .editable-buttons .btn.btn-sm, .editableform .editable-buttons .wizard > .actions a.btn-sm, .wizard > .actions .editableform .editable-buttons a.btn-sm,*/
/*.editableform .editable-buttons .dt-buttons .btn-sm.dt-button, .dt-buttons .editableform .editable-buttons .btn-sm.dt-button,*/
/*.editableform .editable-buttons .tablesaw-sortable th.tablesaw-sortable-head button.btn-sm,*/
/*.tablesaw-sortable th.tablesaw-sortable-head .editableform .editable-buttons button.btn-sm, .editableform .editable-buttons .sweet-alert button.btn-sm,*/
/*.sweet-alert .editableform .editable-buttons button.btn-sm, .editableform .editable-buttons .owl-theme .owl-nav .btn-sm[class*="owl-"],*/
/*.owl-theme .owl-nav .editableform .editable-buttons .btn-sm[class*="owl-"], .editableform .editable-buttons button.btn-sm.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.editableform .editable-buttons button.btn-sm.fc-month-button.fc-state-default.fc-corner-left,*/
/*.editableform .editable-buttons button.btn-sm.fc-agendaWeek-button, .editableform .editable-buttons .btn-sm.fc-prev-button,*/
/*.editableform .editable-buttons .btn-sm.fc-next-button, .editableform .editable-buttons .btn-sm.fc-today-button {*/
/*padding: 9px 25px;*/
/*}*/

/*.editableform .form-control.input-sm, .editableform .select2-container--default .select2-selection--single .input-sm.select2-selection__rendered,*/
/*.select2-container--default .select2-selection--single .editableform .input-sm.select2-selection__rendered, .editableform .select2-container--default .input-sm.select2-selection--single,*/
/*.select2-container--default .editableform .input-sm.select2-selection--single, .editableform .input-sm.select2-selection.select2-selection--multiple,*/
/*.editableform .wizard .content > .body input.input-sm, .wizard .content > .body .editableform input.input-sm, .editableform .mce-floatpanel .input-sm.mce-textbox,*/
/*.mce-floatpanel .editableform .input-sm.mce-textbox, .editableform .mce-floatpanel .input-sm.mce-btn, .mce-floatpanel .editableform .input-sm.mce-btn,*/
/*.editableform .dataTables_wrapper .dataTables_filter input.input-sm, .dataTables_wrapper .dataTables_filter .editableform input.input-sm,*/
/*.editableform .dataTables_wrapper .dataTables_length select.input-sm, .dataTables_wrapper .dataTables_length .editableform select.input-sm,*/
/*.editableform .jsgrid-cell input.input-sm, .jsgrid-cell .editableform input.input-sm, .editableform .jsgrid-cell select.input-sm,*/
/*.jsgrid-cell .editableform select.input-sm, .editableform .tablesaw-bar .btn-select select.input-sm, .tablesaw-bar .btn-select .editableform select.input-sm,*/
/*.editableform .input-sm.dd-handle, .editableform .input-sm.dd3-content {*/
/*padding: 6px 12px;*/
/*font-size: 14px;*/
/*}*/

/*.editableform select.form-control.input-sm, .editableform .select2-container--default .select2-selection--single select.input-sm.select2-selection__rendered,*/
/*.select2-container--default .select2-selection--single .editableform select.input-sm.select2-selection__rendered,*/
/*.editableform .select2-container--default select.input-sm.select2-selection--single,*/
/*.select2-container--default .editableform select.input-sm.select2-selection--single, .editableform select.input-sm.select2-selection.select2-selection--multiple,*/
/*.editableform .mce-floatpanel select.input-sm.mce-textbox, .mce-floatpanel .editableform select.input-sm.mce-textbox,*/
/*.editableform .mce-floatpanel select.input-sm.mce-btn, .mce-floatpanel .editableform select.input-sm.mce-btn,*/
/*.editableform .dataTables_wrapper .dataTables_length select.input-sm, .dataTables_wrapper .dataTables_length .editableform select.input-sm,*/
/*.editableform .jsgrid-cell select.input-sm, .jsgrid-cell .editableform select.input-sm, .editableform .tablesaw-bar .btn-select select.input-sm,*/
/*.tablesaw-bar .btn-select .editableform select.input-sm, .editableform select.input-sm.dd-handle, .editableform select.input-sm.dd3-content {*/
/*height: 42px;*/
/*line-height: 1.428;*/
/*}*/



/*.dropzone {*/
/*border: 1px solid #dedede;*/
/*min-height: 200px;*/
/*background: transparent;*/
/*}*/

/*.dropzone .dz-preview.dz-image-preview {*/
/*background: transparent none repeat scroll 0 0;*/
/*}*/

/*!*Form-Wizard*!*/
/*.wizard .content {*/
/*background: transparent;*/
/*border-radius: 0;*/
/*min-height: 100px;*/
/*margin: 0;*/
/*border: none;*/
/*border-top: none;*/
/*margin-bottom: 30px; }*/
/*.wizard .content .table-responsive {*/
/*margin: 0 -30px; }*/
/*.wizard .content > .body {*/
/*width: 100%;*/
/*height: auto;*/
/*padding: 30px;*/
/*position: relative; }*/
/*.wizard .content > .body input.error {*/
/*border-color: rgba(241, 91, 38, 0.5) !important;*/
/*background: transparent; }*/
/*.wizard .steps .number {*/
/*font-size: 18px;*/
/*color: #878787; }*/
/*.wizard .steps ul {*/
/*display: table;*/
/*width: 100%; }*/
/*.wizard .steps ul > li {*/
/*display: table-cell;*/
/*background: #dedede; }*/
/*.wizard .steps ul > li.done {*/
/*background: #0562e8; }*/
/*.wizard .steps ul > li.current {*/
/*background: #f8b32d !important; }*/
/*.wizard .steps ul > li > a {*/
/*margin: 0;*/
/*padding: 14px 20px;*/
/*background: transparent !important; }*/
/*.wizard .steps ul > li > a:hover, .wizard .steps ul > li > a:focus, .wizard .steps ul > li > a:active {*/
/*margin: 0;*/
/*padding: 14px 20px; }*/
/*.wizard .steps ul > li span.number {*/
/*border-radius: 100%;*/
/*border: 2px solid rgba(255, 255, 255, 0.2);*/
/*width: 35px;*/
/*height: 35px;*/
/*display: inline-block;*/
/*vertical-align: middle;*/
/*padding-top: 3px;*/
/*margin-right: 8px;*/
/*text-align: center;*/
/*background: white; }*/
/*.wizard .steps ul > li.disabled a {*/
/*cursor: not-allowed !important; }*/
/*.wizard > .actions {*/
/*padding-right: 15px; }*/
/*.wizard > .actions > ul > li {*/
/*background: transparent none repeat scroll 0 0;*/
/*display: inline-block;*/
/*float: none;*/
/*margin: 0;*/
/*margin-left: 10px;*/
/*padding: 0; }*/
/*.wizard .bootstrap-touchspin {*/
/*width: 70px; }*/

/*.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover {*/
/*box-shadow: none;*/
/*cursor: not-allowed;*/
/*opacity: 0.65; }*/

/*.wizard > .content > .body label.error {*/
/*display: none !important; }*/

/*.current {*/
/*color: inherit !important; }*/

/*.wizard > .content > .body ul.cards > li {*/
/*display: inline-block; }*/

/*.tabcontrol > .content {*/
/*border-top: medium none;*/
/*height: auto;*/
/*padding-top: 0; }*/
/*.tabcontrol > .content > .body {*/
/*position: relative;*/
/*height: auto;*/
/*padding: 15px 0 0 0;*/
/*width: 100%; }*/

/*.tabcontrol > .steps > ul {*/
/*border-bottom: 1px solid #f7f7f9;*/
/*margin-top: 0;*/
/*float: left;*/
/*width: 100%;*/
/*top: 0; }*/
/*.tabcontrol > .steps > ul > li {*/
/*border: medium none;*/
/*margin: 0 5px 0 0;*/
/*padding: 0;*/
/*background: transparent; }*/
/*.tabcontrol > .steps > ul > li:hover, .tabcontrol > .steps > ul > li:focus, .tabcontrol > .steps > ul > li:active {*/
/*background: transparent;*/
/*border: medium none; }*/
/*.tabcontrol > .steps > ul > li > a {*/
/*background: transparent;*/
/*border: none;*/
/*padding: 10px 20px;*/
/*color: #878787;*/
/*margin: 0;*/
/*border-radius: 0;*/
/*text-transform: capitalize; }*/
/*.tabcontrol > .steps > ul > li > a:active, .tabcontrol > .steps > ul > li > a:focus, .tabcontrol > .steps > ul > li > a:hover {*/
/*color: #212121; }*/
/*.tabcontrol > .steps > ul > li.current {*/
/*border: medium none;*/
/*padding: 0; }*/
/*.tabcontrol > .steps > ul > li.current > a {*/
/*color: #212121;*/
/*padding: 10px 20px;*/
/*border-bottom: 3px solid #0562e8; }*/
/*.tabcontrol > .steps > ul > li.current > a:active, .tabcontrol > .steps > ul > li.current > a:focus, .tabcontrol > .steps > ul > li.current > a:hover {*/
/*color: #212121; }*/
/*.tabcontrol > .steps > ul > li:first-child > a {*/
/*border-top-left-radius: 10px; }*/
/*.tabcontrol > .steps > ul > li:last-child {*/
/*margin-right: 0; }*/
/*.tabcontrol > .steps > ul > li:last-child > a {*/
/*border-top-right-radius: 10px; }*/

/*.review-tag {*/
/*font-size: 18px; }*/


/*!*Popover*!*/
/*.popover {*/
/*border: medium none;*/
/*border-radius: 0;*/
/*padding: 0; }*/
/*.popover h3.popover-title, .popover .popover-title.error-comment {*/
/*background-color: #0562e8;*/
/*border-bottom: 1px solid #dedede;*/
/*border-radius: 0;*/

/*color: #fff;*/
/*font-size: 15px; }*/

/*.popover.bottom > .arrow::after {*/
/*border-bottom-color: #0562e8; }*/

/*!*Typerhead*!*/
/*.typeahead,*/
/*.tt-query,*/
/*.tt-hint {*/
/*width: 396px; }*/

/*.tt-menu {*/
/*width: 422px;*/
/*margin: 12px 0;*/
/*padding: 8px 0;*/
/*background-color: #fff;*/
/*border: 1px solid #dedede;*/
/*-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);*/
/*-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);*/
/*box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }*/

/*.tt-suggestion {*/
/*padding: 3px 20px;*/
/*border-radius: 0; }*/

/*.tt-suggestion:hover {*/
/*cursor: pointer;*/
/*color: #fff;*/
/*background-color: #0562e8; }*/

/*.tt-suggestion.tt-cursor {*/
/*color: #fff;*/
/*background-color: #0097cf; }*/

/*#custom-templates .empty-message {*/
/*padding: 5px 10px;*/
/*text-align: center; }*/

/*#multiple-datasets .league-name {*/
/*margin: 0 20px 5px 20px;*/
/*padding: 3px 0;*/
/*border-bottom: 1px solid #ccc; }*/

/*#scrollable-dropdown-menu .tt-menu {*/
/*max-height: 150px;*/
/*overflow-y: auto; }*/

/*#rtl-support .tt-menu {*/
/*text-align: right; }*/

/*!*Editors*!*/
/*.bootstrap-wysihtml5-insert-image-modal h3, .bootstrap-wysihtml5-insert-image-modal .error-comment {*/
/*font-size: 18px; }*/

/*.bootstrap-wysihtml5-insert-link-modal h3, .bootstrap-wysihtml5-insert-link-modal .error-comment {*/
/*font-size: 18px; }*/
/*.bootstrap-wysihtml5-insert-link-modal .checkbox {*/
/*margin-bottom: inherit;*/
/*margin-top: inherit;*/
/*min-height: inherit;*/
/*padding-bottom: inherit;*/
/*padding-top: inherit; }*/
/*.bootstrap-wysihtml5-insert-link-modal .checkbox input[type="checkbox"] {*/
/*opacity: 1; }*/
/*.bootstrap-wysihtml5-insert-link-modal .checkbox label::before {*/
/*display: none; }*/

/*ul.wysihtml5-toolbar a.btn.wysihtml5-command-active, ul.wysihtml5-toolbar .wizard > .actions a.wysihtml5-command-active, .wizard > .actions ul.wysihtml5-toolbar a.wysihtml5-command-active, ul.wysihtml5-toolbar .dt-buttons a.wysihtml5-command-active.dt-button, .dt-buttons ul.wysihtml5-toolbar a.wysihtml5-command-active.dt-button, ul.wysihtml5-toolbar .owl-theme .owl-nav a.wysihtml5-command-active[class*="owl-"], .owl-theme .owl-nav ul.wysihtml5-toolbar a.wysihtml5-command-active[class*="owl-"], ul.wysihtml5-toolbar a.wysihtml5-command-active.fc-prev-button, ul.wysihtml5-toolbar a.wysihtml5-command-active.fc-next-button, ul.wysihtml5-toolbar a.wysihtml5-command-active.fc-today-button {*/
/*box-shadow: none; }*/

/*.mce-ico {*/
/*font-family: "tinymce",Arial !important; }*/

/*.mce-floatpanel .mce-btn {*/
/*background: transparent; }*/
/*.mce-floatpanel .mce-btn:hover {*/
/*background: transparent; }*/
/*.mce-floatpanel .mce-btn.mce-active {*/
/*background-color: transparent !important; }*/
/*.mce-floatpanel .mce-btn.mce-active:hover {*/
/*background-color: transparent;*/
/*border-color: #dedede; }*/
/*.mce-floatpanel .mce-window-body .mce-listbox {*/
/*border-color: #dedede; }*/
/*.mce-floatpanel .mce-window-head .mce-title {*/
/*font-size: 18px;*/
/*font-weight: 400; }*/
/*.mce-floatpanel .mce-primary button {*/
/*background: #0562e8;*/
/*border: solid 2px #0562e8; }*/
/*.mce-floatpanel .mce-primary button:hover, .mce-floatpanel .mce-primary button:active, .mce-floatpanel .mce-primary button:focus {*/
/*background: rgba(139, 195, 74, 0.85);*/
/*border: solid 2px rgba(139, 195, 74, 0.1); }*/
/*.mce-floatpanel .mce-menu-item-normal.mce-active {*/
/*background-color: #0562e8; }*/
/*.mce-floatpanel .mce-menu-item:hover, .mce-floatpanel .mce-menu-item.mce-selected, .mce-floatpanel .mce-menu-item:focus {*/
/*background-color: #0562e8; }*/

/*.mce-foot button {*/
/*background: #f33923;*/
/*color: #fff !important;*/
/*border: solid 2px #f33923; }*/
/*.mce-foot button:hover, .mce-foot button:active, .mce-foot button:focus {*/
/*background: rgba(243, 57, 35, 0.85);*/
/*border: solid 2px rgba(243, 57, 35, 0.1); }*/

/*.mce-panel {*/
/*border-color: #dedede !important; }*/

/*.btn-group.open .note-btn.dropdown-toggle {*/
/*box-shadow: none; }*/

/*.note-editor.panel {*/
/*border-radius: 0;*/
/*box-shadow: none; }*/
/*.note-editor.panel .panel-heading {*/
/*background: transparent; }*/
/*.note-editor .checkbox {*/
/*margin-bottom: inherit;*/
/*margin-top: inherit;*/
/*min-height: inherit;*/
/*padding-bottom: inherit;*/
/*padding-top: inherit; }*/
/*.note-editor .checkbox input[type="checkbox"] {*/
/*opacity: 1; }*/
/*.note-editor .checkbox label::before {*/
/*display: none; }*/
/*.note-editor .note-btn.btn-sm {*/
/*padding: 9px 25px;*/
/*font-size: 14px; }*/
/*.note-editor.note-frame {*/
/*border: 1px solid #dedede; }*/
/*.note-editor .note-toolbar.panel-heading {*/
/*border: 1px solid #dedede; }*/
/*.note-editor h4 {*/
/*font-size: 18px; }*/

/*!*Tabs*!*/
/*.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {*/
/*border: none; }*/

/*.tab-struct .tab-pane {*/
/*padding-top: 15px; }*/
/*.tab-struct.vertical-tab .tab-pane {*/
/*padding-top: 0;*/
/*padding-left: 15px; }*/
/*.tab-struct.vertical-tab-reverse .tab-pane {*/
/*padding-left: 0;*/
/*padding-right: 15px; }*/

/*.pills-struct .tab-pane {*/
/*padding-top: 15px; }*/
/*.pills-struct.vertical-pills .tab-pane {*/
/*padding-top: 0;*/
/*padding-left: 15px; }*/
/*.pills-struct.vertical-pills-reverse .tab-pane {*/
/*padding-left: 0;*/
/*padding-right: 15px; }*/

/*.nav-tabs {*/
/*border-bottom: 1px solid #dedede; }*/
/*.nav-tabs > li {*/
/*margin-right: 5px; }*/
/*.nav-tabs > li > a {*/
/*background: #dedede;*/
/*border: none;*/
/*padding: 10px 20px;*/
/*color: #878787;*/
/*margin: 0;*/
/*border-radius: 0;*/
/*text-transform: capitalize;*/
/*-webkit-transition: 0.2s ease;*/
/*-moz-transition: 0.2s ease;*/
/*transition: 0.2s ease; }*/
/*.nav-tabs > li > a:active, .nav-tabs > li > a:focus, .nav-tabs > li > a:hover {*/
/*background: #f2f2f2;*/
/*color: #212121; }*/
/*.nav-tabs > li.active > a, .nav-tabs > li.open > a {*/
/*background: #f2f2f2;*/
/*color: #212121;*/
/*border-color: transparent; }*/
/*.nav-tabs > li.active > a:active, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover, .nav-tabs > li.open > a:active, .nav-tabs > li.open > a:focus, .nav-tabs > li.open > a:hover {*/
/*background: #f2f2f2;*/
/*color: #212121;*/
/*border-color: transparent; }*/
/*.nav-tabs > li:first-child > a {*/
/*border-top-left-radius: 10px; }*/
/*.nav-tabs > li:last-child {*/
/*margin-right: 0; }*/
/*.nav-tabs > li:last-child > a {*/
/*border-top-right-radius: 10px; }*/

/*.nav-tab-reverse .nav-tabs > li {*/
/*float: right;*/
/*margin-right: 0;*/
/*margin-left: 5px; }*/
/*.nav-tab-reverse .nav-tabs > li:first-child > a {*/
/*border-top-left-radius: 0;*/
/*border-top-right-radius: 10px; }*/
/*.nav-tab-reverse .nav-tabs > li:last-child {*/
/*margin-left: 0; }*/
/*.nav-tab-reverse .nav-tabs > li:last-child > a {*/
/*border-top-right-radius: 0;*/
/*border-top-left-radius: 10px; }*/

/*.vertical-tab .tab-content {*/
/*overflow: hidden;*/
/*border-left: 1px solid #dedede; }*/
/*.vertical-tab .ver-nav-tab {*/
/*float: left;*/
/*border-bottom: none; }*/
/*.vertical-tab .ver-nav-tab > li {*/
/*margin-bottom: 5px;*/
/*margin-right: -1px;*/
/*float: none; }*/
/*.vertical-tab .ver-nav-tab > li > a {*/
/*border: none; }*/
/*.vertical-tab .ver-nav-tab > li:first-child > a {*/
/*border-top-left-radius: 10px; }*/
/*.vertical-tab .ver-nav-tab > li:last-child {*/
/*margin-bottom: 0; }*/
/*.vertical-tab .ver-nav-tab > li:last-child > a {*/
/*border-bottom-left-radius: 10px;*/
/*border-top-right-radius: 0; }*/
/*.vertical-tab.vertical-tab-reverse .tab-content {*/
/*border-left: none;*/
/*border-right: 1px solid #dedede; }*/
/*.vertical-tab.vertical-tab-reverse .ver-nav-tab {*/
/*float: right; }*/
/*.vertical-tab.vertical-tab-reverse .ver-nav-tab > li:first-child > a {*/
/*border-top-left-radius: 0;*/
/*border-top-right-radius: 10px; }*/
/*.vertical-tab.vertical-tab-reverse .ver-nav-tab > li:last-child > a {*/
/*border-bottom-left-radius: 0;*/
/*border-bottom-right-radius: 10px;*/
/*border-top-right-radius: 0; }*/

/*.custom-tab-1 .nav-tabs > li > a {*/
/*background: transparent !important;*/
/*border: none; }*/
/*.custom-tab-1 .nav-tabs > li.active > a {*/
/*border-bottom: 3px solid #0562e8; }*/
/*.custom-tab-1.vertical-tab .ver-nav-tab > li {*/
/*background: transparent !important; }*/
/*.custom-tab-1.vertical-tab .ver-nav-tab > li.active > a {*/
/*border-bottom: none;*/
/*border-right: 3px solid #0562e8; }*/
/*.custom-tab-1.vertical-tab.vertical-tab-reverse > li.active > a {*/
/*border-right: none;*/
/*border-left: 3px solid #0562e8; }*/

/*.custom-tab-2 .nav-tabs {*/
/*border: none; }*/
/*.custom-tab-2 .nav-tabs > li > a {*/
/*background: transparent;*/
/*border-radius: 0; }*/
/*.custom-tab-2 .nav-tabs > li > a:active, .custom-tab-2 .nav-tabs > li > a:focus, .custom-tab-2 .nav-tabs > li > a:hover {*/
/*color: #0562e8; }*/
/*.custom-tab-2 .nav-tabs > li.active > a, .custom-tab-2 .nav-tabs > li.open > a {*/
/*color: #0562e8;*/
/*background: #f2f2f2; }*/
/*.custom-tab-2 .nav-tabs > li.active > a:active, .custom-tab-2 .nav-tabs > li.active > a:focus, .custom-tab-2 .nav-tabs > li.active > a:hover, .custom-tab-2 .nav-tabs > li.open > a:active, .custom-tab-2 .nav-tabs > li.open > a:focus, .custom-tab-2 .nav-tabs > li.open > a:hover {*/
/*color: #0562e8;*/
/*background: #f2f2f2; }*/
/*.custom-tab-2 .nav-tabs > li:first-child > a, .custom-tab-2 .nav-tabs > li:last-child > a {*/
/*border-radius: 0; }*/
/*.custom-tab-2.nav-tab-reverse .nav-tabs > li:first-child > a, .custom-tab-2.nav-tab-reverse .nav-tabs > li:last-child > a {*/
/*border-radius: 0; }*/
/*.custom-tab-2 .tab-pane {*/
/*background: #f2f2f2;*/
/*padding: 15px !important; }*/

/*.nav-pills > li {*/
/*margin-right: 5px; }*/
/*.nav-pills > li > a {*/
/*background: #f7f7f9;*/
/*border: none;*/
/*padding: 10px 20px;*/
/*color: #878787;*/
/*margin: 0;*/
/*border-radius: 0;*/
/*text-transform: capitalize; }*/
/*.nav-pills > li > a:active, .nav-pills > li > a:focus, .nav-pills > li > a:hover {*/
/*background: #0562e8;*/
/*color: #fff; }*/
/*.nav-pills > li.active > a, .nav-pills > li.open > a {*/
/*background: #0562e8;*/
/*color: #fff; }*/
/*.nav-pills > li.active > a:active, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover, .nav-pills > li.open > a:active, .nav-pills > li.open > a:focus, .nav-pills > li.open > a:hover {*/
/*background: #0562e8;*/
/*color: #fff; }*/
/*.nav-pills > li:last-child {*/
/*margin-right: 0; }*/

/*.nav-pills-reverse .nav-pills > li {*/
/*float: right;*/
/*margin-right: 0;*/
/*margin-left: 5px; }*/
/*.nav-pills-reverse .nav-pills > li:last-child {*/
/*margin-left: 0; }*/

/*.vertical-pills .tab-content {*/
/*overflow: hidden;*/
/*padding-left: 15px; }*/
/*.vertical-pills .ver-nav-pills {*/
/*float: left; }*/
/*.vertical-pills .ver-nav-pills > li {*/
/*margin: 0 0 5px;*/
/*float: none; }*/
/*.vertical-pills .ver-nav-pills > li:last-child {*/
/*margin-bottom: 0; }*/
/*.vertical-pills.vertical-pills-reverse .tab-content {*/
/*padding-left: 0;*/
/*padding-right: 15px; }*/
/*.vertical-pills.vertical-pills-reverse .ver-nav-pills {*/
/*float: right; }*/

/*.nav-pills-rounded.nav-pills > li > a {*/
/*border-radius: 60px; }*/

/*.nav-pills-outline.nav-pills > li > a {*/
/*background-color: transparent !important;*/
/*border: 2px solid #f7f7f9;*/
/*color: #878787; }*/
/*.nav-pills-outline.nav-pills > li > a:hover, .nav-pills-outline.nav-pills > li > a:active, .nav-pills-outline.nav-pills > li > a:focus {*/
/*border-color: #0562e8;*/
/*color: #212121; }*/
/*.nav-pills-outline.nav-pills > li.active > a {*/
/*background-color: transparent !important;*/
/*border: 2px solid #0562e8;*/
/*color: #212121; }*/
/*.nav-pills-outline.nav-pills > li.active > a:hover, .nav-pills-outline.nav-pills > li.active > a:active, .nav-pills-outline.nav-pills > li.active > a:focus {*/
/*border: 2px solid #0562e8;*/
/*color: #212121; }*/

/*!*Table*!*/
/*.table > tbody > tr.active > td, .jsgrid-table > tbody > tr.active > td, .table > tbody > tr.active > th, .jsgrid-table > tbody > tr.active > th, .table > tbody > tr > td.active, .jsgrid-table > tbody > tr > td.active, .table > tbody > tr > th.active, .jsgrid-table > tbody > tr > th.active, .table > tfoot > tr.active > td, .jsgrid-table > tfoot > tr.active > td, .table > tfoot > tr.active > th, .jsgrid-table > tfoot > tr.active > th, .table > tfoot > tr > td.active, .jsgrid-table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .jsgrid-table > tfoot > tr > th.active, .table > thead > tr.active > td, .jsgrid-table > thead > tr.active > td, .table > thead > tr.active > th, .jsgrid-table > thead > tr.active > th, .table > thead > tr > td.active, .jsgrid-table > thead > tr > td.active, .table > thead > tr > th.active, .jsgrid-table > thead > tr > th.active {*/
/*background: rgba(220, 220, 220, 0.08); }*/

/*.table > tbody > tr.success > td, .jsgrid-table > tbody > tr.success > td, .table > tbody > tr.success > th, .jsgrid-table > tbody > tr.success > th, .table > tbody > tr > td.success, .jsgrid-table > tbody > tr > td.success, .table > tbody > tr > th.success, .jsgrid-table > tbody > tr > th.success, .table > tfoot > tr.success > td, .jsgrid-table > tfoot > tr.success > td, .table > tfoot > tr.success > th, .jsgrid-table > tfoot > tr.success > th, .table > tfoot > tr > td.success, .jsgrid-table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .jsgrid-table > tfoot > tr > th.success, .table > thead > tr.success > td, .jsgrid-table > thead > tr.success > td, .table > thead > tr.success > th, .jsgrid-table > thead > tr.success > th, .table > thead > tr > td.success, .jsgrid-table > thead > tr > td.success, .table > thead > tr > th.success, .jsgrid-table > thead > tr > th.success {*/
/*background: rgba(139, 195, 74, 0.08); }*/

/*.table > tbody > tr.info > td, .jsgrid-table > tbody > tr.info > td, .table > tbody > tr.info > th, .jsgrid-table > tbody > tr.info > th, .table > tbody > tr > td.info, .jsgrid-table > tbody > tr > td.info, .table > tbody > tr > th.info, .jsgrid-table > tbody > tr > th.info, .table > tfoot > tr.info > td, .jsgrid-table > tfoot > tr.info > td, .table > tfoot > tr.info > th, .jsgrid-table > tfoot > tr.info > th, .table > tfoot > tr > td.info, .jsgrid-table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .jsgrid-table > tfoot > tr > th.info, .table > thead > tr.info > td, .jsgrid-table > thead > tr.info > td, .table > thead > tr.info > th, .jsgrid-table > thead > tr.info > th, .table > thead > tr > td.info, .jsgrid-table > thead > tr > td.info, .table > thead > tr > th.info, .jsgrid-table > thead > tr > th.info {*/
/*background: rgba(231, 54, 141, 0.08); }*/

/*.table > tbody > tr.warning > td, .jsgrid-table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .jsgrid-table > tbody > tr.warning > th, .table > tbody > tr > td.warning, .jsgrid-table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .jsgrid-table > tbody > tr > th.warning, .table > tfoot > tr.warning > td, .jsgrid-table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th, .jsgrid-table > tfoot > tr.warning > th, .table > tfoot > tr > td.warning, .jsgrid-table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .jsgrid-table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .jsgrid-table > thead > tr.warning > td, .table > thead > tr.warning > th, .jsgrid-table > thead > tr.warning > th, .table > thead > tr > td.warning, .jsgrid-table > thead > tr > td.warning, .table > thead > tr > th.warning, .jsgrid-table > thead > tr > th.warning {*/
/*background: rgba(248, 179, 45, 0.08); }*/

/*.table > tbody > tr.danger > td, .jsgrid-table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .jsgrid-table > tbody > tr.danger > th, .table > tbody > tr > td.danger, .jsgrid-table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .jsgrid-table > tbody > tr > th.danger, .table > tfoot > tr.danger > td, .jsgrid-table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th, .jsgrid-table > tfoot > tr.danger > th, .table > tfoot > tr > td.danger, .jsgrid-table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .jsgrid-table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .jsgrid-table > thead > tr.danger > td, .table > thead > tr.danger > th, .jsgrid-table > thead > tr.danger > th, .table > thead > tr > td.danger, .jsgrid-table > thead > tr > td.danger, .table > thead > tr > th.danger, .jsgrid-table > thead > tr > th.danger {*/
/*background: rgba(243, 57, 35, 0.08); }*/

/*.table-responsive {*/
/*border: none; }*/
/*.table-responsive .table, .table-responsive .jsgrid-table {*/
/*max-width: none;*/
/*-webkit-overflow-scrolling: touch !important; }*/

/*.table, .jsgrid-table {*/
/*border-color: #dedede !important; }*/

/*.table > tbody > tr > td, .jsgrid-table > tbody > tr > td, .table > tbody > tr > th, .jsgrid-table > tbody > tr > th, .table > tfoot > tr > td, .jsgrid-table > tfoot > tr > td, .table > tfoot > tr > th, .jsgrid-table > tfoot > tr > th, .table > thead > tr > td, .jsgrid-table > thead > tr > td, .table > thead > tr > th, .jsgrid-table > thead > tr > th {*/
/*padding: 16px;*/
/*border-top: 1px solid #dedede;*/
/*vertical-align: middle; }*/

/*.table > thead > tr > th, .jsgrid-table > thead > tr > th, .table > tfoot > tr > th, .jsgrid-table > tfoot > tr > th {*/
/*border-bottom: 2px solid #dedede !important;*/
/*color: #212121;*/
/*font-size: 12px;*/
/*font-weight: 500;*/
/*text-transform: uppercase; }*/

/*.table > tfoot > tr > th, .jsgrid-table > tfoot > tr > th {*/
/*border-bottom: 1px solid #dedede !important; }*/

/*.table-hover > tbody > tr:hover {*/
/*background-color: rgba(33, 33, 33, 0.05) !important; }*/

/*.table-bordered {*/
/*border: 1px solid #dedede; }*/

/*.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {*/
/*border: 1px solid #dedede; }*/

/*.table-striped > tbody > tr:nth-of-type(2n+1) {*/
/*background-color: rgba(33, 33, 33, 0.05); }*/

/*.color-bg-table .table > caption + thead > tr:first-child > td, .color-bg-table .jsgrid-table > caption + thead > tr:first-child > td, .color-bg-table .table >*/
/*caption + thead > tr:first-child > th, .color-bg-table .jsgrid-table >*/
/*caption + thead > tr:first-child > th, .color-bg-table .table > colgroup + thead > tr:first-child > td, .color-bg-table .jsgrid-table > colgroup + thead > tr:first-child > td, .color-bg-table .table > colgroup + thead > tr:first-child > th, .color-bg-table .jsgrid-table > colgroup + thead > tr:first-child > th, .color-bg-table .table > thead:first-child > tr:first-child > td, .color-bg-table .jsgrid-table > thead:first-child > tr:first-child > td, .color-bg-table .table > thead:first-child > tr:first-child > th, .color-bg-table .jsgrid-table > thead:first-child > tr:first-child > th {*/
/*background: #0562e8; }*/
/*.color-bg-table .table > thead > tr > th, .color-bg-table .jsgrid-table > thead > tr > th {*/
/*color: #fff; }*/

/*.bootstrap-table .fixed-table-container {*/
/*border: 1px solid #dedede;*/
/*border-radius: 0; }*/
/*.bootstrap-table .fixed-table-container tbody td {*/
/*border: 1px solid #dedede; }*/

/*.fixed-table-body .card-view {*/
/*background: transparent;*/
/*border: medium none;*/
/*border-radius: 0;*/
/*box-shadow: none;*/
/*margin-bottom: 0;*/
/*padding: 0; }*/
/*.fixed-table-body .card-view .title {*/
/*font-weight: 400; }*/
/*.fixed-table-body .card-view:hover {*/
/*box-shadow: none; }*/

/*table.dataTable tbody tr {*/
/*background-color: transparent; }*/

/*table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {*/
/*background-color: inherit; }*/

/*table.dataTable.display.table-striped > tbody > tr:nth-of-type(2n+1) {*/
/*background: rgba(33, 33, 33, 0.05); }*/

/*table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3, table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1, table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd, table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {*/
/*background-color: inherit; }*/

/*table.dataTable.display.table-striped tbody tr > .sorting_1,*/
/*table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {*/
/*background: transparent; }*/

/*table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {*/
/*border-color: #dedede;*/
/*border-left: none;*/
/*border-bottom: none; }*/

/*table.dataTable > tfoot > tr > th {*/
/*border-bottom: 0 solid #dedede !important;*/
/*border-left: medium none !important; }*/

/*table.dataTable {*/
/*border-right: medium none !important; }*/

/*table.dataTable > caption + thead > tr:first-child > td, table.dataTable > caption + thead > tr:first-child > th, table.dataTable > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .jsgrid-table > colgroup + thead > tr:first-child > th, table.dataTable > thead:first-child > tr:first-child > td, table.dataTable > thead:first-child > tr:first-child > th {*/
/*border-left: medium none !important; }*/

/*.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_length {*/
/*margin-bottom: 30px; }*/
/*.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {*/
/*padding-top: 20px;*/
/*margin-bottom: 14px;*/
/*color: #878787; }*/
/*.dataTables_wrapper .dataTables_length,*/
/*.dataTables_wrapper .dataTables_info {*/
/*padding-left: 15px; }*/
/*.dataTables_wrapper .dataTables_info {*/
/*color: #878787; }*/
/*.dataTables_wrapper .dataTables_filter {*/
/*padding-right: 15px; }*/
/*.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_length select {*/
/*height: 25px; }*/

/*.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:active, .dataTables_wrapper .dataTables_paginate .paginate_button:focus {*/
/*background: #0562e8;*/
/*border-color: #0562e8;*/
/*color: #fff !important;*/
/*box-shadow: none; }*/

/*.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {*/
/*color: #212121; }*/

/*.sorting,*/
/*.sorting_asc,*/
/*.sorting_desc {*/
/*position: relative; }*/

/*table.dataTable thead .sorting::after {*/
/*content: "\f0dc";*/
/*opacity: 0.2; }*/

/*table.dataTable thead .sorting_asc::after {*/
/*content: "\f0de"; }*/

/*table.dataTable thead .sorting_desc::after {*/
/*content: "\f0dd"; }*/

/*table.dataTable thead .sorting::after, table.dataTable thead .sorting_asc::after, table.dataTable thead .sorting_desc::after {*/
/*display: block;*/
/*font-family: FontAwesome;*/
/*position: absolute;*/
/*right: 10px;*/
/*top: 15px;*/
/*font-size: 14px; }*/

/*table.dataTable thead .sorting,*/
/*table.dataTable thead .sorting_asc,*/
/*table.dataTable thead .sorting_desc {*/
/*background: none;*/
/*border-left: medium none;*/
/*border-top: medium none; }*/

/*.dt-buttons {*/
/*float: left; }*/
/*.dt-buttons .dt-button {*/
/*display: inline-block;*/
/*margin-right: 10px;*/
/*margin-bottom: 10px; }*/
/*.dt-buttons .dt-button:hover {*/
/*color: #fff; }*/
/*.dt-buttons .dt-button:last-child {*/
/*margin-right: 0; }*/

/*table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child::before {*/
/*background-color: #0562e8;*/
/*box-shadow: none; }*/

/*table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {*/
/*background-color: #f33923;*/
/*box-shadow: none; }*/

/*table.footable > tbody > tr > td.footable-sortable, table.footable > tbody > tr > th.footable-sortable, table.footable > tfoot > tr > td.footable-sortable, table.footable > tfoot > tr > th.footable-sortable, table.footable > thead > tr > td.footable-sortable, table.footable > thead > tr > th.footable-sortable {*/
/*padding: 16px;*/
/*border-top: none; }*/

/*table.footable > tr.footable-filtering > th,*/
/*table.footable > thead > tr.footable-filtering > th {*/
/*border: none !important; }*/

/*tr.footable-header > th {*/
/*border-top: none !important; }*/

/*.footable-details.table, .footable-details.jsgrid-table {*/
/*background: transparent; }*/

/*.footable-editing .btn.footable-edit, .footable-editing .wizard > .actions a.footable-edit, .wizard > .actions .footable-editing a.footable-edit, .footable-editing .dt-buttons .footable-edit.dt-button, .dt-buttons .footable-editing .footable-edit.dt-button, .footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-edit, .tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-edit, .footable-editing .sweet-alert button.footable-edit, .sweet-alert .footable-editing button.footable-edit, .footable-editing .owl-theme .owl-nav .footable-edit[class*="owl-"], .owl-theme .owl-nav .footable-editing .footable-edit[class*="owl-"], .footable-editing button.footable-edit.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.footable-editing button.footable-edit.fc-month-button.fc-state-default.fc-corner-left,*/
/*.footable-editing button.footable-edit.fc-agendaWeek-button, .footable-editing .footable-edit.fc-prev-button, .footable-editing .footable-edit.fc-next-button, .footable-editing .footable-edit.fc-today-button, .footable-editing .btn.footable-delete, .footable-editing .wizard > .actions a.footable-delete, .wizard > .actions .footable-editing a.footable-delete, .footable-editing .dt-buttons .footable-delete.dt-button, .dt-buttons .footable-editing .footable-delete.dt-button, .footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-delete, .tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-delete, .footable-editing .sweet-alert button.footable-delete, .sweet-alert .footable-editing button.footable-delete, .footable-editing .owl-theme .owl-nav .footable-delete[class*="owl-"], .owl-theme .owl-nav .footable-editing .footable-delete[class*="owl-"], .footable-editing button.footable-delete.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.footable-editing button.footable-delete.fc-month-button.fc-state-default.fc-corner-left,*/
/*.footable-editing button.footable-delete.fc-agendaWeek-button, .footable-editing .footable-delete.fc-prev-button, .footable-editing .footable-delete.fc-next-button, .footable-editing .footable-delete.fc-today-button {*/
/*background: transparent;*/
/*border: none !important; }*/
/*.footable-editing .btn.footable-edit .fooicon.fooicon-pencil, .footable-editing .wizard > .actions a.footable-edit .fooicon.fooicon-pencil, .wizard > .actions .footable-editing a.footable-edit .fooicon.fooicon-pencil, .footable-editing .dt-buttons .footable-edit.dt-button .fooicon.fooicon-pencil, .dt-buttons .footable-editing .footable-edit.dt-button .fooicon.fooicon-pencil, .footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-edit .fooicon.fooicon-pencil, .tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-edit .fooicon.fooicon-pencil, .footable-editing .sweet-alert button.footable-edit .fooicon.fooicon-pencil, .sweet-alert .footable-editing button.footable-edit .fooicon.fooicon-pencil, .footable-editing .owl-theme .owl-nav .footable-edit[class*="owl-"] .fooicon.fooicon-pencil, .owl-theme .owl-nav .footable-editing .footable-edit[class*="owl-"] .fooicon.fooicon-pencil, .footable-editing button.footable-edit.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-pencil,*/
/*.footable-editing button.footable-edit.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-pencil,*/
/*.footable-editing button.footable-edit.fc-agendaWeek-button .fooicon.fooicon-pencil, .footable-editing .footable-edit.fc-prev-button .fooicon.fooicon-pencil, .footable-editing .footable-edit.fc-next-button .fooicon.fooicon-pencil, .footable-editing .footable-edit.fc-today-button .fooicon.fooicon-pencil, .footable-editing .btn.footable-delete .fooicon.fooicon-pencil, .footable-editing .wizard > .actions a.footable-delete .fooicon.fooicon-pencil, .wizard > .actions .footable-editing a.footable-delete .fooicon.fooicon-pencil, .footable-editing .dt-buttons .footable-delete.dt-button .fooicon.fooicon-pencil, .dt-buttons .footable-editing .footable-delete.dt-button .fooicon.fooicon-pencil, .footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-delete .fooicon.fooicon-pencil, .tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-delete .fooicon.fooicon-pencil, .footable-editing .sweet-alert button.footable-delete .fooicon.fooicon-pencil, .sweet-alert .footable-editing button.footable-delete .fooicon.fooicon-pencil, .footable-editing .owl-theme .owl-nav .footable-delete[class*="owl-"] .fooicon.fooicon-pencil, .owl-theme .owl-nav .footable-editing .footable-delete[class*="owl-"] .fooicon.fooicon-pencil, .footable-editing button.footable-delete.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-pencil,*/
/*.footable-editing button.footable-delete.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-pencil,*/
/*.footable-editing button.footable-delete.fc-agendaWeek-button .fooicon.fooicon-pencil, .footable-editing .footable-delete.fc-prev-button .fooicon.fooicon-pencil, .footable-editing .footable-delete.fc-next-button .fooicon.fooicon-pencil, .footable-editing .footable-delete.fc-today-button .fooicon.fooicon-pencil {*/
/*color: #0562e8; }*/
/*.footable-editing .btn.footable-edit .fooicon.fooicon-trash, .footable-editing .wizard > .actions a.footable-edit .fooicon.fooicon-trash, .wizard > .actions .footable-editing a.footable-edit .fooicon.fooicon-trash, .footable-editing .dt-buttons .footable-edit.dt-button .fooicon.fooicon-trash, .dt-buttons .footable-editing .footable-edit.dt-button .fooicon.fooicon-trash, .footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-edit .fooicon.fooicon-trash, .tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-edit .fooicon.fooicon-trash, .footable-editing .sweet-alert button.footable-edit .fooicon.fooicon-trash, .sweet-alert .footable-editing button.footable-edit .fooicon.fooicon-trash, .footable-editing .owl-theme .owl-nav .footable-edit[class*="owl-"] .fooicon.fooicon-trash, .owl-theme .owl-nav .footable-editing .footable-edit[class*="owl-"] .fooicon.fooicon-trash, .footable-editing button.footable-edit.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-trash,*/
/*.footable-editing button.footable-edit.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-trash,*/
/*.footable-editing button.footable-edit.fc-agendaWeek-button .fooicon.fooicon-trash, .footable-editing .footable-edit.fc-prev-button .fooicon.fooicon-trash, .footable-editing .footable-edit.fc-next-button .fooicon.fooicon-trash, .footable-editing .footable-edit.fc-today-button .fooicon.fooicon-trash, .footable-editing .btn.footable-delete .fooicon.fooicon-trash, .footable-editing .wizard > .actions a.footable-delete .fooicon.fooicon-trash, .wizard > .actions .footable-editing a.footable-delete .fooicon.fooicon-trash, .footable-editing .dt-buttons .footable-delete.dt-button .fooicon.fooicon-trash, .dt-buttons .footable-editing .footable-delete.dt-button .fooicon.fooicon-trash, .footable-editing .tablesaw-sortable th.tablesaw-sortable-head button.footable-delete .fooicon.fooicon-trash, .tablesaw-sortable th.tablesaw-sortable-head .footable-editing button.footable-delete .fooicon.fooicon-trash, .footable-editing .sweet-alert button.footable-delete .fooicon.fooicon-trash, .sweet-alert .footable-editing button.footable-delete .fooicon.fooicon-trash, .footable-editing .owl-theme .owl-nav .footable-delete[class*="owl-"] .fooicon.fooicon-trash, .owl-theme .owl-nav .footable-editing .footable-delete[class*="owl-"] .fooicon.fooicon-trash, .footable-editing button.footable-delete.fc-agendaDay-button.fc-state-default.fc-corner-right .fooicon.fooicon-trash,*/
/*.footable-editing button.footable-delete.fc-month-button.fc-state-default.fc-corner-left .fooicon.fooicon-trash,*/
/*.footable-editing button.footable-delete.fc-agendaWeek-button .fooicon.fooicon-trash, .footable-editing .footable-delete.fc-prev-button .fooicon.fooicon-trash, .footable-editing .footable-delete.fc-next-button .fooicon.fooicon-trash, .footable-editing .footable-delete.fc-today-button .fooicon.fooicon-trash {*/
/*color: #f33923; }*/

/*.footable-show .fooicon.fooicon-pencil {*/
/*padding-right: 6px; }*/

/*.footable-filtering .checkbox {*/
/*margin-bottom: inherit;*/
/*margin-top: inherit;*/
/*min-height: inherit;*/
/*padding: 3px 20px !important;*/

/*text-transform: capitalize; }*/
/*.footable-filtering .checkbox input[type="checkbox"] {*/
/*opacity: 1; }*/
/*.footable-filtering .checkbox label::before {*/
/*display: none; }*/

/*.fooicon.fooicon-sort,*/
/*.fooicon.fooicon-sort-asc,*/
/*.fooicon.fooicon-sort-desc {*/
/*font-family: FontAwesome !important;*/
/*font-size: 14px; }*/

/*.fooicon.fooicon-sort:before {*/
/*content: "\f0dc"; }*/

/*.fooicon.fooicon-sort-asc:before {*/
/*content: "\f0de"; }*/

/*.fooicon.fooicon-sort-desc:before {*/
/*content: "\f0dd"; }*/

/*table.footable > tbody > tr.footable-empty > td {*/
/*font-size: 18px; }*/

/*.jsgrid {*/
/*height: auto !important; }*/

/*.jsgrid-grid-header {*/
/*overflow: auto; }*/

/*.jsgrid-pager-page {*/
/*display: inline-block;*/
/*margin-bottom: 5px; }*/

/*.jsgrid-row > .jsgrid-cell,*/
/*.jsgrid-alt-row > .jsgrid-cell {*/
/*background: transparent;*/
/*border-color: #dedede; }*/

/*.jsgrid-header-row > .jsgrid-header-cell {*/
/*background: inherit;*/
/*font-size: 12px;*/
/*color: #212121;*/
/*text-align: left;*/
/*text-transform: uppercase; }*/

/*.jsgrid-filter-row > .jsgrid-cell, .jsgrid-header-row > .jsgrid-header-cell, .jsgrid-insert-row > .jsgrid-cell {*/
/*background: inherit;*/
/*border-bottom: 1px solid #dedede; }*/

/*.jsgrid-edit-row > .jsgrid-cell, .jsgrid-filter-row > .jsgrid-cell, .jsgrid-grid-body, .jsgrid-grid-header, .jsgrid-header-row > .jsgrid-header-cell, .jsgrid-insert-row > .jsgrid-cell {*/
/*background: inherit;*/
/*border-color: #dedede;*/
/*height: auto !important;*/
/*overflow: auto; }*/

/*.jsgrid-alt-row > .jsgrid-cell {*/
/*background: inherit; }*/

/*.jsgrid-selected-row > .jsgrid-cell {*/
/*background: inherit;*/
/*border-color: #dedede; }*/

/*.jsgrid-pager {*/
/*color: #212121;*/
/*margin-top: 10px; }*/
/*.jsgrid-pager a {*/
/*color: #212121; }*/

/*.jsgrid-align-center, .jsgrid-align-center input, .jsgrid-align-center select, .jsgrid-align-center textarea, .jsgrid-align-right, .jsgrid-align-right input, .jsgrid-align-right select, .jsgrid-align-right textarea {*/
/*text-align: left; }*/

/*.jsgrid-pager-current-page, .jsgrid-pager-page a:hover, .jsgrid-pager-page a:active, .jsgrid-pager-page a:focus {*/
/*background: #0562e8;*/
/*border: 1px solid #0562e8;*/
/*color: #fff;*/
/*font-weight: 400; }*/

/*.jsgrid-pager-page, .jsgrid-pager-page a {*/
/*padding: 5px 10px;*/
/*border: 1px solid transparent; }*/

/*.tablesaw-swipe .tablesaw-cell-persist {*/
/*box-shadow: none;*/
/*border-right: inherit; }*/

/*.tablesaw-sortable th.tablesaw-sortable-head button {*/
/*padding: 0;*/
/*border: none !important;*/
/*font-weight: normal;*/
/*font-size: 12px;*/
/*text-transform: uppercase; }*/

/*.tablesaw {*/
/*margin-bottom: 0 !important; }*/
/*.tablesaw thead {*/
/*background: transparent;*/
/*border: none; }*/
/*.tablesaw thead tr:first-child th {*/
/*border-right: medium none;*/
/*padding: 16px !important; }*/
/*.tablesaw td a {*/
/*color: #878787 !important; }*/
/*.tablesaw td, .tablesaw tbody th {*/
/*vertical-align: middle;*/
/*font-size: inherit;*/
/*color: #878787 !important; }*/

/*.tablesaw-toolbar {*/
/*font-size: 100%;*/
/*margin-bottom: 30px; }*/
/*.tablesaw-toolbar label {*/
/*color: #212121;*/
/*display: block;*/
/*margin-right: 0;*/
/*padding: 0;*/
/*text-transform: uppercase; }*/

/*.tablesaw-bar .btn-select select {*/
/*opacity: 1;*/
/*color: #212121 !important;*/
/*font-size: 14px !important;*/
/*font-weight: 400;*/
/*text-transform: capitalize;*/
/*filter: alpha(opacity=1);*/
/*display: inline-block;*/
/*color: inherit;*/
/*background: transparent;*/
/*position: static; }*/

/*.tablesaw-enhanced .tablesaw-bar .btn:hover, .tablesaw-enhanced .tablesaw-bar .wizard > .actions a:hover, .wizard > .actions .tablesaw-enhanced .tablesaw-bar a:hover, .tablesaw-enhanced .tablesaw-bar .dt-buttons .dt-button:hover, .dt-buttons .tablesaw-enhanced .tablesaw-bar .dt-button:hover, .tablesaw-enhanced .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button:hover, .tablesaw-sortable th.tablesaw-sortable-head .tablesaw-enhanced .tablesaw-bar button:hover, .tablesaw-enhanced .tablesaw-bar .sweet-alert button:hover, .sweet-alert .tablesaw-enhanced .tablesaw-bar button:hover, .tablesaw-enhanced .tablesaw-bar .owl-theme .owl-nav [class*="owl-"]:hover, .owl-theme .owl-nav .tablesaw-enhanced .tablesaw-bar [class*="owl-"]:hover, .tablesaw-enhanced .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,*/
/*.tablesaw-enhanced .tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left:hover,*/
/*.tablesaw-enhanced .tablesaw-bar button.fc-agendaWeek-button:hover, .tablesaw-enhanced .tablesaw-bar .fc-prev-button:hover, .tablesaw-enhanced .tablesaw-bar .fc-next-button:hover, .tablesaw-enhanced .tablesaw-bar .fc-today-button:hover, .tablesaw-enhanced .tablesaw-bar .btn:focus, .tablesaw-enhanced .tablesaw-bar .wizard > .actions a:focus, .wizard > .actions .tablesaw-enhanced .tablesaw-bar a:focus, .tablesaw-enhanced .tablesaw-bar .dt-buttons .dt-button:focus, .dt-buttons .tablesaw-enhanced .tablesaw-bar .dt-button:focus, .tablesaw-enhanced .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button:focus, .tablesaw-sortable th.tablesaw-sortable-head .tablesaw-enhanced .tablesaw-bar button:focus, .tablesaw-enhanced .tablesaw-bar .sweet-alert button:focus, .sweet-alert .tablesaw-enhanced .tablesaw-bar button:focus, .tablesaw-enhanced .tablesaw-bar .owl-theme .owl-nav [class*="owl-"]:focus, .owl-theme .owl-nav .tablesaw-enhanced .tablesaw-bar [class*="owl-"]:focus, .tablesaw-enhanced .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,*/
/*.tablesaw-enhanced .tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left:focus,*/
/*.tablesaw-enhanced .tablesaw-bar button.fc-agendaWeek-button:focus, .tablesaw-enhanced .tablesaw-bar .fc-prev-button:focus, .tablesaw-enhanced .tablesaw-bar .fc-next-button:focus, .tablesaw-enhanced .tablesaw-bar .fc-today-button:focus {*/
/*background: transparent; }*/

/*.tablesaw-bar .tablesaw-toolbar .btn, .tablesaw-bar .tablesaw-toolbar .wizard > .actions a, .wizard > .actions .tablesaw-bar .tablesaw-toolbar a, .tablesaw-bar .tablesaw-toolbar .dt-buttons .dt-button, .dt-buttons .tablesaw-bar .tablesaw-toolbar .dt-button, .tablesaw-bar .tablesaw-toolbar .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .tablesaw-bar .tablesaw-toolbar button, .tablesaw-bar .tablesaw-toolbar .sweet-alert button, .sweet-alert .tablesaw-bar .tablesaw-toolbar button, .tablesaw-bar .tablesaw-toolbar .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .tablesaw-bar .tablesaw-toolbar [class*="owl-"], .tablesaw-bar .tablesaw-toolbar button.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*.tablesaw-bar .tablesaw-toolbar button.fc-month-button.fc-state-default.fc-corner-left,*/
/*.tablesaw-bar .tablesaw-toolbar button.fc-agendaWeek-button, .tablesaw-bar .tablesaw-toolbar .fc-prev-button, .tablesaw-bar .tablesaw-toolbar .fc-next-button, .tablesaw-bar .tablesaw-toolbar .fc-today-button {*/
/*font-size: 0 !important; }*/

/*.btn.btn-small.btn-select, .wizard > .actions a.btn-small.btn-select, .dt-buttons .btn-small.btn-select.dt-button, .tablesaw-sortable th.tablesaw-sortable-head button.btn-small.btn-select, .sweet-alert button.btn-small.btn-select, .owl-theme .owl-nav .btn-small.btn-select[class*="owl-"], button.btn-small.btn-select.fc-agendaDay-button.fc-state-default.fc-corner-right,*/
/*button.btn-small.btn-select.fc-month-button.fc-state-default.fc-corner-left,*/
/*button.btn-small.btn-select.fc-agendaWeek-button, .btn-small.btn-select.fc-prev-button, .btn-small.btn-select.fc-next-button, .btn-small.btn-select.fc-today-button {*/
/*padding-left: 20px;*/
/*padding-right: 20px !important; }*/

/*.tablesaw-bar .btn-select::after {*/
/*display: none; }*/

/*.tablesaw-enhanced .tablesaw-bar .btn:active, .tablesaw-enhanced .tablesaw-bar .wizard > .actions a:active, .wizard > .actions .tablesaw-enhanced .tablesaw-bar a:active, .tablesaw-enhanced .tablesaw-bar .dt-buttons .dt-button:active, .dt-buttons .tablesaw-enhanced .tablesaw-bar .dt-button:active, .tablesaw-enhanced .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button:active, .tablesaw-sortable th.tablesaw-sortable-head .tablesaw-enhanced .tablesaw-bar button:active, .tablesaw-enhanced .tablesaw-bar .sweet-alert button:active, .sweet-alert .tablesaw-enhanced .tablesaw-bar button:active, .tablesaw-enhanced .tablesaw-bar .owl-theme .owl-nav [class*="owl-"]:active, .owl-theme .owl-nav .tablesaw-enhanced .tablesaw-bar [class*="owl-"]:active, .tablesaw-enhanced .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,*/
/*.tablesaw-enhanced .tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left:active,*/
/*.tablesaw-enhanced .tablesaw-bar button.fc-agendaWeek-button:active, .tablesaw-enhanced .tablesaw-bar .fc-prev-button:active, .tablesaw-enhanced .tablesaw-bar .fc-next-button:active, .tablesaw-enhanced .tablesaw-bar .fc-today-button:active {*/
/*background-image: none;*/
/*box-shadow: none !important; }*/

/*.tablesaw-bar .btn select option, .tablesaw-bar .wizard > .actions a select option, .wizard > .actions .tablesaw-bar a select option, .tablesaw-bar .dt-buttons .dt-button select option, .dt-buttons .tablesaw-bar .dt-button select option, .tablesaw-bar .tablesaw-sortable th.tablesaw-sortable-head button select option, .tablesaw-sortable th.tablesaw-sortable-head .tablesaw-bar button select option, .tablesaw-bar .sweet-alert button select option, .sweet-alert .tablesaw-bar button select option, .tablesaw-bar .owl-theme .owl-nav [class*="owl-"] select option, .owl-theme .owl-nav .tablesaw-bar [class*="owl-"] select option, .tablesaw-bar button.fc-agendaDay-button.fc-state-default.fc-corner-right select option,*/
/*.tablesaw-bar button.fc-month-button.fc-state-default.fc-corner-left select option,*/
/*.tablesaw-bar button.fc-agendaWeek-button select option, .tablesaw-bar .fc-prev-button select option, .tablesaw-bar .fc-next-button select option, .tablesaw-bar .fc-today-button select option {*/
/*color: #212121;*/

/*font-weight: 400; }*/


/*Notification*/
.alert {
    border-radius: 2px;
    border: none;
    color: #fff;
}

.alert i {
    font-size: 24px;
}

.alert.alert-success {
    background: #0562e8;
}

.alert.alert-info {
    background: #e7368d;
}

.alert.alert-danger {
    background: #f33923;
}

.alert.alert-warning {
    background: #f8b32d;
}

.alert.alert-style-1 {
    padding-left: 70px;
    background: #fff;
    position: relative;
    color: #212121;
    border: 1px solid #dedede;
}

.alert.alert-style-1 i {
    height: 100%;
    left: 0;
    margin: 0;
    padding-top: 13px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 50px;
    color: #fff;
}

.alert.alert-style-1.alert-success i {
    color: #0562e8;
    background: #f7f7f7;
}

.alert.alert-style-1.alert-info i {
    color: #e7368d;
    background: #f7f7f7;
}

.alert.alert-style-1.alert-danger i {
    color: #f33923;
    background: #f7f7f7;
}

.alert.alert-style-1.alert-warning i {
    color: #f8b32d;
    background: #f7f7f7;
}

.alert-dismissable .close, .alert-dismissible .close {
    top: 0;
}

.bottom-center-fullwidth .jq-toast-wrap {
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0 !important;
}

.top-center-fullwidth .jq-toast-wrap {
    left: 0;
    right: 0;
    width: 100%;
    top: 2px !important;
}

/*Icons*/
.icon-container {
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    text-align: left;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

.icon-container:hover {
    color: #212121;
}

.icon-container i, .icon-container span.glyphicon {
    margin-right: 20px;
    font-size: 18px;
}

.glyphs.character-mapping li, .glyphs.character-mapping input:hover, .glyphs.character-mapping input:focus {
    color: #212121;
    border-color: #212121;
}

.font-icon-detail span:first-child {
    color: #212121;
    border: 1px solid #dedede;
}

.font-icon-list:hover .font-icon-detail span:first-child, .font-icon-detail.zeroclipboard-is-hover span:first-child {
    background-color: #212121;
}

.checkbox label:before {
    border-radius: 0;
}

.checkbox input[type="checkbox"]:focus+label::before, .checkbox input[type="radio"]:focus+label::before,
.radio input[type="radio"]:focus+label::before {
    outline: none;
}

.checkbox-primary input[type="checkbox"]:checked+label::before,
.checkbox-primary input[type="radio"]:checked+label::before {
    background-color: #2196F3;
    border-color: #2196F3;
}

.checkbox-danger input[type="checkbox"]:checked+label::before,
.checkbox-danger input[type="radio"]:checked+label::before {
    background-color: #f33923;
    border-color: #f33923;
}

.checkbox-info input[type="checkbox"]:checked+label::before,
.checkbox-info input[type="radio"]:checked+label::before {
    background-color: #e7368d;
    border-color: #e7368d;
}

.checkbox-warning input[type="checkbox"]:checked+label::before,
.checkbox-warning input[type="radio"]:checked+label::before {
    background-color: #f8b32d;
    border-color: #f8b32d;
}

.checkbox-success input[type="checkbox"]:checked+label::before,
.checkbox-success input[type="radio"]:checked+label::before {
    background-color: #0562e8;
    border-color: #0562e8;
}

.checkbox-primary input[type="checkbox"]:indeterminate+label::before,
.checkbox-primary input[type="radio"]:indeterminate+label::before {
    background-color: #2196F3;
    border-color: #2196F3;
}

.checkbox-danger input[type="checkbox"]:indeterminate+label::before,
.checkbox-danger input[type="radio"]:indeterminate+label::before {
    background-color: #f33923;
    border-color: #f33923;
}

.checkbox-info input[type="checkbox"]:indeterminate+label::before,
.checkbox-info input[type="radio"]:indeterminate+label::before {
    background-color: #e7368d;
    border-color: #e7368d;
}

.checkbox-warning input[type="checkbox"]:indeterminate+label::before,
.checkbox-warning input[type="radio"]:indeterminate+label::before {
    background-color: #f8b32d;
    border-color: #f8b32d;
}

.checkbox-success input[type="checkbox"]:indeterminate+label::before,
.checkbox-success input[type="radio"]:indeterminate+label::before {
    background-color: #0562e8;
    border-color: #0562e8;
}

.radio-primary input[type="radio"]+label::after {
    background-color: #2196F3;
}

.radio-primary input[type="radio"]:checked+label::before {
    border-color: #2196F3;
}

.radio-primary input[type="radio"]:checked+label::after {
    background-color: #2196F3;
}

.radio-danger input[type="radio"]+label::after {
    background-color: #f33923;
}

.radio-danger input[type="radio"]:checked+label::before {
    border-color: #f33923;
}

.radio-danger input[type="radio"]:checked+label::after {
    background-color: #f33923;
}

.radio-info input[type="radio"]+label::after {
    background-color: #e7368d;
}

.radio-info input[type="radio"]:checked+label::before {
    border-color: #e7368d;
}

.radio-info input[type="radio"]:checked+label::after {
    background-color: #e7368d;
}

.radio-warning input[type="radio"]+label::after {
    background-color: #f8b32d;
}

.radio-warning input[type="radio"]:checked+label::before {
    border-color: #f8b32d;
}

.radio-warning input[type="radio"]:checked+label::after {
    background-color: #f8b32d;
}

.radio-success input[type="radio"]+label::after {
    background-color: #0562e8;
}

.radio-success input[type="radio"]:checked+label::before {
    border-color: #0562e8;
}

.radio-success input[type="radio"]:checked+label::after {
    background-color: #0562e8;
}

.file-manager .hr-line-dashed {
    margin: 15px 0;
}

.file {
    border: 1px solid #dedede;
    border-radius: 2px;
    padding: 0;
    background-color: #fff;
    position: relative;
}

.file .icon, .file .image {
    height: 150px;
    overflow: hidden;
    background-size: cover;
    background-position: top;
}

.file .icon {
    padding: 15px 10px;
    display: table;
    width: 100%;
    text-align: center;
}

.file .icon i {
    color: #2196F3;
    display: table-cell;
    font-size: 30px;
    vertical-align: middle;
}

.file .file-name {
    padding: 10px;
    background-color: rgba(33, 33, 33, 0.05);
    border-top: 1px solid rgba(33, 33, 33, 0.05);
}

.file .file-name span {
    font-size: 12px;
    color: #878787;
}

.file-control {
    font-size: 13px;
    margin-right: 10px;
    text-transform: uppercase;
}

.file-control.active {
    color: #f33923;
}

.file-name small {
    color: #878787;
}

ul.tag-list li {
    list-style: none;
}

a.compose-mail {
    padding: 8px 10px;
}

.mail-search {
    max-width: 300px;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

.ibox.collapsed .ibox-content {
    display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}

.ibox:after, .ibox:before {
    display: table;
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fff;
    border-color: #e7eaec;
    -webkit-border-image: none;
    -o-border-image: none;
    border-image: none;
    border-style: solid solid none;
    border-width: 3px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 14px 15px 7px;
    min-height: 48px;
}

.ibox-content {
    background-color: #fff;
    color: inherit;
}

.ibox-footer {
    color: inherit;
    border-top: 1px solid #dedede;
    font-size: 90%;
    background: #fff;
    padding: 10px 15px;
}


/*Panel*/
.panel-title {
    margin: 6px;
}

.panel-title>small {
    color: #878787;
    font-size: 14px;
}

.panel .panel-heading a {
    position: relative;
    display: flex !important;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.panel .panel-heading a i {
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;

}

.panel .panel-heading a i.zmdi-chevron-down {
    opacity: 1;
    /* position: absolute; */
    top: 5px;
    color: white;
    font-size: 24px;
}

.panel .panel-heading a i.zmdi-chevron-up {
    opacity: 0;
    color: white;
    display: none;
}

.panel .panel-heading a.collapsed i.zmdi-chevron-down {
    opacity: 0;
    display: none;

}

.panel .panel-heading a.collapsed i.zmdi-chevron-up {
    opacity: 1;
    display: block;
}

.panel .panel-heading .pull-right i {
    font-size: 20px;
    color: #878787;
}

.panel .panel-heading .pull-right .dropdown i {
    font-size: 16px;
}

.panel .panel-heading .pull-right .dropdown i.caret-down {
    position: static;
    margin-left: 2px;
}

.panel .panel-heading .pull-right.right-float-sub-text {
    font-size: 11px;
    padding-top: 2px;
    text-transform: uppercase;
}

.panel .panel-footer {
    background-color: transparent;
    border-top: 1px solid #dedede;
    margin: 0 -15px;
    padding: 5px 20px 0;
}

.panel.panel-refresh {
    position: relative;
}

.panel.panel-refresh .refresh-container {
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
    z-index: 5;
    cursor: wait;
    overflow: hidden;
}

.panel.panel-refresh .refresh-container .la-anim-1 {
    position: absolute;
}

.panel.panel-refresh .refresh-container .la-anim-1.la-animate {
    -o-transition: transform 2s ease;
    transition: transform 2s ease, -webkit-transform 2s ease;
}

.panel-info>.panel-heading {
    background-color: #e7368d;
}

.panel-danger>.panel-heading {
    background-color: #f33923;
}

.panel-warning>.panel-heading {
    background-color: #48194e;
}

.panel-success>.panel-heading {
    background-color: #48194e;
}

.panel-primary>.panel-heading {
    background-color: #0562e8;
}

.panel-inverse>.panel-heading {
    background-color: #dedede;
    color: #212121 !important;
}

.well {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 20px;
}

.well.well-lg {
    padding: 25px;
}

.well.well-sm {
    padding: 15px;
}

/*Dropdown*/
.show-on-hover:hover>ul.dropdown-menu {
    display: block;
}

.dropdown-menu {
    border-radius: 2px;
    border: none;
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.175);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.175);
}


.dropdown-menu>li>a {
    color: #212121;
}

.dropdown-menu>li>a:hover {
    color: #212121;
    background: rgba(33, 33, 33, 0.05);
}

.dropdown-menu>li>a i {
    color: #878787;
    font-size: 18px;
    margin-right: 10px;
    width: 18px;
}

.dropdown-menu>li.sub-menu {
    position: relative;
}

.dropdown-menu>li.sub-menu .dropdown-menu:before, .dropdown-menu>li.sub-menu .dropdown-menu:after {
    display: none !important;
}

.dropdown-menu>li.sub-menu>ul {
    top: 0;
}

.dropdown-menu>li.sub-menu>ul.open-left-side {
    right: 100% !important;
}

.dropdown-menu>li.sub-menu>ul.open-right-side {
    left: 100% !important;
}

.dropdown-menu .divider {
    background-color: #dedede;
}

.dropdown-menu>.active>a {
    background: #0562e8;
}

.dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
    background: #0562e8;
}

.dropdown-menu.animated {
    -webkit-animation-duration: 0.55s;
    animation-duration: 0.55s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
}

/*Pagination*/
.pagination>li>a, .pagination>li>span {
    border-color: #dedede;
    border-radius: 0 !important;
    float: right !important;
    color: #48194e;
}

.pagination>li>a:hover, .pagination>li>a:focus, .pagination>li>a:active, .pagination>li>span:hover, .pagination>li>span:focus, .pagination>li>span:active {
    border-color: #f3eaff;
    background-color: #48194e;
}

.pagination>li.active>a, .pagination>li.active>span {
    background: #48194e;
    border-color: #48194e;
    color: #fff;
    font-weight: bold;
}

.pagination>li.active>a:hover, .pagination>li.active>a:focus, .pagination>li.active>a:active, .pagination>li.active>span:hover, .pagination>li.active>span:focus, .pagination>li.active>span:active {
    background: #48194e;
    border-color: #f3eaff;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    border-color: #dedede;
    color: #0562e8;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    color: #fff;
}

/*Pager*/
.pager li>a, .pager li>span {
    border: 1px solid #0562e8;
    border-radius: 60px;
}

/*Labels*/
.label, .jvectormap-tip {
    padding: 3px 6px 3px;
    font-size: 11px;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 400;
}

.label.label-info, .label-info.jvectormap-tip {
    background: #e7368d;
}

.label.label-primary, .label-primary.jvectormap-tip {
    background: #2196F3;
}

.label.label-success, .label-success.jvectormap-tip {
    background: #0562e8;
}

.label.label-danger, .label-danger.jvectormap-tip {
    background: #f33923;
}

.label.label-warning, .label-warning.jvectormap-tip {
    background: #f8b32d;
}

/*Badge*/
.badge {
    background-color: #DCDCDC;
    font-weight: 400;
    padding: 5px 10px;
    color: #212121;
}

.badge.badge-info {
    background: #e7368d;
    color: #fff;
}

.badge.badge-primary {
    background: #2196F3;
    color: #fff;
}

.badge.badge-success {
    background: #0562e8;
    color: #fff;
}

.badge.badge-danger {
    background: #f33923;
    color: #fff;
}

.badge.badge-warning {
    background: #f8b32d;
    color: #fff;
}

.badge.transparent-badge {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
}

.badge.transparent-badge.badge-info {
    color: #e7368d;
}

.badge.transparent-badge.badge-primary {
    color: #2196F3;
}

.badge.transparent-badge.badge-success {
    color: #0562e8;
}

.badge.transparent-badge.badge-danger {
    color: #f33923;
}

.badge.transparent-badge.badge-warning {
    color: #f8b32d;
}

/*Vecotor Map*/

/*Search Bar*/
.app-search {
    margin: 0;
    position: relative;
}

.app-search a {
    color: #4c5667;
    position: absolute;
    right: 10px;
    top: 15px;
}

.app-search .form-control,
.app-search .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .app-search .select2-selection__rendered,
.app-search .select2-container--default .select2-selection--single,
.select2-container--default .app-search .select2-selection--single,
.app-search .select2-selection.select2-selection--multiple,
.app-search .wizard .content > .body input,
.wizard .content > .body .app-search input,
.app-search .mce-floatpanel .mce-textbox,
.mce-floatpanel .app-search .mce-textbox,
.app-search .mce-floatpanel .mce-btn,
.mce-floatpanel .app-search .mce-btn,
.app-search .dataTables_wrapper .dataTables_filter input,
.dataTables_wrapper .dataTables_filter .app-search input,
.app-search .dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length .app-search select,
.app-search .jsgrid-cell input,
.jsgrid-cell .app-search input,
.app-search .jsgrid-cell select,
.jsgrid-cell .app-search select,
.app-search .tablesaw-bar .btn-select select,
.tablesaw-bar .btn-select .app-search select,
.app-search .dd-handle,
.app-search .dd3-content,
.app-search .form-control:focus,
.app-search .jsgrid-cell input:focus,
.jsgrid-cell .app-search input:focus,
.app-search .jsgrid-cell select:focus,
.jsgrid-cell .app-search select:focus,
.app-search .dd-handle:focus,	
.app-search .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single .app-search .select2-selection__rendered:focus,
.app-search .select2-container--default .select2-selection--single:focus,
.select2-container--default .app-search .select2-selection--single:focus,
.app-search .select2-selection.select2-selection--multiple:focus,
.app-search .wizard .content > .body input:focus,
.wizard .content > .body .app-search input:focus,
.app-search .mce-floatpanel .mce-textbox:focus,
.mce-floatpanel .app-search .mce-textbox:focus,
.app-search .mce-floatpanel .mce-btn:focus,
.mce-floatpanel .app-search .mce-btn:focus,
.app-search .dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter .app-search input:focus,
.app-search .dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length .app-search select:focus,
.app-search .tablesaw-bar .btn-select select:focus,
.tablesaw-bar .btn-select .app-search select:focus,
.app-search .dd3-content:focus {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    border: medium none;
    border-radius: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #4c5667;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 40px;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    width: 180px;
}

.app-search .form-control:focus, .app-search .select2-container--default .select2-selection--single .select2-selection__rendered:focus, .select2-container--default .select2-selection--single .app-search .select2-selection__rendered:focus,
.app-search .select2-container--default .select2-selection--single:focus,
.select2-container--default .app-search .select2-selection--single:focus, .app-search .select2-selection.select2-selection--multiple:focus, .app-search .wizard .content>.body input:focus, .wizard .content>.body .app-search input:focus, .app-search .mce-floatpanel .mce-textbox:focus, .mce-floatpanel .app-search .mce-textbox:focus, .app-search .mce-floatpanel .mce-btn:focus, .mce-floatpanel .app-search .mce-btn:focus, .app-search .dataTables_wrapper .dataTables_filter input:focus, .dataTables_wrapper .dataTables_filter .app-search input:focus, .app-search .dataTables_wrapper .dataTables_length select:focus, .dataTables_wrapper .dataTables_length .app-search select:focus, .app-search .jsgrid-cell input:focus, .jsgrid-cell .app-search input:focus, .app-search .jsgrid-cell select:focus, .jsgrid-cell .app-search select:focus, .app-search .tablesaw-bar .btn-select select:focus, .tablesaw-bar .btn-select .app-search select:focus, .app-search .dd-handle:focus, .app-search .dd3-content:focus, .app-search .form-control:focus:focus, .app-search .select2-container--default .select2-selection--single .select2-selection__rendered:focus:focus, .select2-container--default .select2-selection--single .app-search .select2-selection__rendered:focus:focus,
.app-search .select2-container--default .select2-selection--single:focus:focus,
.select2-container--default .app-search .select2-selection--single:focus:focus, .app-search .select2-selection.select2-selection--multiple:focus:focus, .app-search .wizard .content>.body input:focus:focus, .wizard .content>.body .app-search input:focus:focus, .app-search .mce-floatpanel .mce-textbox:focus:focus, .mce-floatpanel .app-search .mce-textbox:focus:focus, .app-search .mce-floatpanel .mce-btn:focus:focus, .mce-floatpanel .app-search .mce-btn:focus:focus, .app-search .dataTables_wrapper .dataTables_filter input:focus:focus, .dataTables_wrapper .dataTables_filter .app-search input:focus:focus, .app-search .dataTables_wrapper .dataTables_length select:focus:focus, .dataTables_wrapper .dataTables_length .app-search select:focus:focus, .app-search .jsgrid-cell input:focus:focus, .jsgrid-cell .app-search input:focus:focus, .app-search .jsgrid-cell select:focus:focus, .jsgrid-cell .app-search select:focus:focus, .app-search .tablesaw-bar .btn-select select:focus:focus, .tablesaw-bar .btn-select .app-search select:focus:focus, .app-search .dd-handle:focus:focus, .app-search .dd3-content:focus:focus {
    width: 300px;
}

/*Chat UI*/
.chat-search {
    width: 100%;
    border-bottom: 1px solid #dedede;
}

.chat-search .input-group {
    width: 100%;
}

.chat-search .input-group .form-control, .chat-search .input-group .select2-container--default .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--single .chat-search .input-group .select2-selection__rendered,
.chat-search .input-group .select2-container--default .select2-selection--single,
.select2-container--default .chat-search .input-group .select2-selection--single, .chat-search .input-group .select2-selection.select2-selection--multiple, .chat-search .input-group .wizard .content>.body input, .wizard .content>.body .chat-search .input-group input, .chat-search .input-group .mce-floatpanel .mce-textbox, .mce-floatpanel .chat-search .input-group .mce-textbox, .chat-search .input-group .mce-floatpanel .mce-btn, .mce-floatpanel .chat-search .input-group .mce-btn, .chat-search .input-group .dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_filter .chat-search .input-group input, .chat-search .input-group .dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_length .chat-search .input-group select, .chat-search .input-group .jsgrid-cell input, .jsgrid-cell .chat-search .input-group input, .chat-search .input-group .jsgrid-cell select, .jsgrid-cell .chat-search .input-group select, .chat-search .input-group .tablesaw-bar .btn-select select, .tablesaw-bar .btn-select .chat-search .input-group select, .chat-search .input-group .dd-handle, .chat-search .input-group .dd3-content {
    height: 36px;
    border-radius: 50px;
    padding-right: 30px;
    background: #fff;
}

.chat-search .input-group .input-group-btn {
    position: absolute;
    right: 22px;
    top: 8px;
    z-index: 4;
}

.chat-search .input-group .input-group-btn>.btn, .chat-search .input-group .wizard>.actions .disabled .input-group-btn>a, .chat-search .input-group .wizard>.actions .input-group-btn>a, .wizard>.actions .chat-search .input-group .input-group-btn>a, .chat-search .input-group .dt-buttons .input-group-btn>.dt-button, .dt-buttons .chat-search .input-group .input-group-btn>.dt-button, .chat-search .input-group .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn>button, .tablesaw-sortable th.tablesaw-sortable-head .chat-search .input-group .input-group-btn>button, .chat-search .input-group .sweet-alert .input-group-btn>button, .sweet-alert .chat-search .input-group .input-group-btn>button, .chat-search .input-group .owl-theme .owl-nav .input-group-btn>[class*="owl-"], .owl-theme .owl-nav .chat-search .input-group .input-group-btn>[class*="owl-"], .chat-search .input-group .input-group-btn>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.chat-search .input-group .input-group-btn>button.fc-month-button.fc-state-default.fc-corner-left,
.chat-search .input-group .input-group-btn>button.fc-agendaWeek-button, .chat-search .input-group .input-group-btn>.fc-prev-button, .chat-search .input-group .input-group-btn>.fc-next-button, .chat-search .input-group .input-group-btn>.fc-today-button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 0;
}

.chat-search .input-group .input-group-btn>.btn i, .chat-search .input-group .wizard>.actions .input-group-btn>a i, .wizard>.actions .chat-search .input-group .input-group-btn>a i, .chat-search .input-group .dt-buttons .input-group-btn>.dt-button i, .dt-buttons .chat-search .input-group .input-group-btn>.dt-button i, .chat-search .input-group .tablesaw-sortable th.tablesaw-sortable-head .input-group-btn>button i, .tablesaw-sortable th.tablesaw-sortable-head .chat-search .input-group .input-group-btn>button i, .chat-search .input-group .sweet-alert .input-group-btn>button i, .sweet-alert .chat-search .input-group .input-group-btn>button i, .chat-search .input-group .owl-theme .owl-nav .input-group-btn>[class*="owl-"] i, .owl-theme .owl-nav .chat-search .input-group .input-group-btn>[class*="owl-"] i, .chat-search .input-group .input-group-btn>button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.chat-search .input-group .input-group-btn>button.fc-month-button.fc-state-default.fc-corner-left i,
.chat-search .input-group .input-group-btn>button.fc-agendaWeek-button i, .chat-search .input-group .input-group-btn>.fc-prev-button i, .chat-search .input-group .input-group-btn>.fc-next-button i, .chat-search .input-group .input-group-btn>.fc-today-button i {
    color: #878787;
    font-size: 20px;
}

.add-friend {
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.add-friend span {
    text-transform: capitalize;
    font-size: 16px;
    width: 33.33%;
    float: left;
    text-align: center;
    vertical-align: middle;
}

.add-friend a {
    width: 33.33%;
    float: left;
    font-size: 18px;
}

.chat-list-wrap .chat-body>a {
    border-bottom: 1px solid #dedede;
    display: block;
}

.chat-list-wrap .chat-body .chat-data {
    padding: 10px 15px;
    position: relative;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.chat-list-wrap .chat-body .chat-data:hover {
    background: rgba(33, 33, 33, 0.05);
}

.chat-list-wrap .chat-body .chat-data.active-user {
    background: rgba(33, 33, 33, 0.05);
}

.chat-list-wrap .chat-body .chat-data .user-img {
    height: 35px;
    width: 35px;
    float: left;
    margin-right: 15px;
}

.chat-list-wrap .chat-body .chat-data .user-data {
    float: left;
}

.chat-list-wrap .chat-body .chat-data .user-data .time {
    font-size: 12px;
    margin-top: 2px;
    width: 165px;
}

.chat-list-wrap .chat-body .chat-data .status {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 10px;
    position: absolute;
    left: 40px;
    top: 37px;
    width: 10px;
}

.chat-list-wrap .chat-body .chat-data .status.away {
    background: #f8b32d;
}

.chat-list-wrap .chat-body .chat-data .status.offline {
    background: #f33923;
}

.chat-list-wrap .chat-body .chat-data .status.online {
    background: #0562e8;
}

.recent-chat-wrap .panel-heading {
    padding: 0;
    background: transparent;
}

.recent-chat-wrap .panel-heading .goto-back {
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #dedede;
}

.recent-chat-wrap .panel-heading .goto-back span {
    text-transform: capitalize;
    font-size: 16px;
    width: 33.33%;
    float: left;
    text-align: center;
    vertical-align: middle;
}

.recent-chat-wrap .panel-heading .goto-back a {
    width: 33.33%;
    float: left;
    font-size: 18px;
}

.recent-chat-wrap.card-view {
    border: none;
    padding: 0;
    margin: 0;
}

.recent-chat-wrap .input-group {
    width: 100%;
    display: block;
}

.recent-chat-wrap .input-group .input-msg-send {
    background: rgba(33, 33, 33, 0.05);
    border: medium none;
    height: 60px;
    padding-right: 60px;
}

.recent-chat-wrap .input-group .input-group-btn {
    position: absolute;
    right: 22px;
    top: 20px;
    z-index: 4;
}

.recent-chat-wrap .input-group .input-group-btn .btn, .recent-chat-wrap .input-group .input-group-btn .wizard>.actions a, .wizard>.actions .recent-chat-wrap .input-group .input-group-btn a, .recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button, .dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button, .recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button, .recent-chat-wrap .input-group .input-group-btn .sweet-alert button, .sweet-alert .recent-chat-wrap .input-group .input-group-btn button, .recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*="owl-"], .recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button, .recent-chat-wrap .input-group .input-group-btn .fc-prev-button, .recent-chat-wrap .input-group .input-group-btn .fc-next-button, .recent-chat-wrap .input-group .input-group-btn .fc-today-button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 0;
}

.recent-chat-wrap .input-group .input-group-btn .btn:hover, .recent-chat-wrap .input-group .input-group-btn .wizard>.actions a:hover, .wizard>.actions .recent-chat-wrap .input-group .input-group-btn a:hover, .recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button:hover, .dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button:hover, .recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:hover, .tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button:hover, .recent-chat-wrap .input-group .input-group-btn .sweet-alert button:hover, .sweet-alert .recent-chat-wrap .input-group .input-group-btn button:hover, .recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"]:hover, .owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*="owl-"]:hover, .recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:hover,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button:hover, .recent-chat-wrap .input-group .input-group-btn .fc-prev-button:hover, .recent-chat-wrap .input-group .input-group-btn .fc-next-button:hover, .recent-chat-wrap .input-group .input-group-btn .fc-today-button:hover, .recent-chat-wrap .input-group .input-group-btn .btn:active, .recent-chat-wrap .input-group .input-group-btn .wizard>.actions a:active, .wizard>.actions .recent-chat-wrap .input-group .input-group-btn a:active, .recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button:active, .dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button:active, .recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:active, .tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button:active, .recent-chat-wrap .input-group .input-group-btn .sweet-alert button:active, .sweet-alert .recent-chat-wrap .input-group .input-group-btn button:active, .recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"]:active, .owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*="owl-"]:active, .recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:active,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button:active, .recent-chat-wrap .input-group .input-group-btn .fc-prev-button:active, .recent-chat-wrap .input-group .input-group-btn .fc-next-button:active, .recent-chat-wrap .input-group .input-group-btn .fc-today-button:active, .recent-chat-wrap .input-group .input-group-btn .btn:focus, .recent-chat-wrap .input-group .input-group-btn .wizard>.actions a:focus, .wizard>.actions .recent-chat-wrap .input-group .input-group-btn a:focus, .recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button:focus, .dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button:focus, .recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:focus, .tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button:focus, .recent-chat-wrap .input-group .input-group-btn .sweet-alert button:focus, .sweet-alert .recent-chat-wrap .input-group .input-group-btn button:focus, .recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"]:focus, .owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*="owl-"]:focus, .recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:focus,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button:focus, .recent-chat-wrap .input-group .input-group-btn .fc-prev-button:focus, .recent-chat-wrap .input-group .input-group-btn .fc-next-button:focus, .recent-chat-wrap .input-group .input-group-btn .fc-today-button:focus {
    background: transparent none repeat scroll 0 0;
}

.recent-chat-wrap .input-group .input-group-btn .btn i, .recent-chat-wrap .input-group .input-group-btn .wizard>.actions a i, .wizard>.actions .recent-chat-wrap .input-group .input-group-btn a i, .recent-chat-wrap .input-group .input-group-btn .dt-buttons .dt-button i, .dt-buttons .recent-chat-wrap .input-group .input-group-btn .dt-button i, .recent-chat-wrap .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button i, .tablesaw-sortable th.tablesaw-sortable-head .recent-chat-wrap .input-group .input-group-btn button i, .recent-chat-wrap .input-group .input-group-btn .sweet-alert button i, .sweet-alert .recent-chat-wrap .input-group .input-group-btn button i, .recent-chat-wrap .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"] i, .owl-theme .owl-nav .recent-chat-wrap .input-group .input-group-btn [class*="owl-"] i, .recent-chat-wrap .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.recent-chat-wrap .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left i,
.recent-chat-wrap .input-group .input-group-btn button.fc-agendaWeek-button i, .recent-chat-wrap .input-group .input-group-btn .fc-prev-button i, .recent-chat-wrap .input-group .input-group-btn .fc-next-button i, .recent-chat-wrap .input-group .input-group-btn .fc-today-button i {
    color: rgba(33, 33, 33, 0.2);
    font-size: 20px;
}

.recent-chat-wrap .input-group .input-group-btn.emojis {
    right: 50px;
}

.recent-chat-wrap .chat-content .user-img {
    height: 27px;
    width: 27px;
    position: absolute;
}

.recent-chat-wrap .chat-content ul li {
    padding: 0 15px;
    margin-bottom: 20px;
    position: relative;
}

.recent-chat-wrap .chat-content .msg {
    padding: 7px 15px;
    color: #212121;
    max-width: 100%;
    overflow-wrap: break-word;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.recent-chat-wrap .chat-content .msg .msg-per-detail span {
    font-size: 10px;
    text-transform: uppercase;
}

.recent-chat-wrap .chat-content .friend .friend-msg-wrap .msg {
    margin-left: 37px;
    background: rgba(33, 33, 33, 0.04) none repeat scroll 0 0;
    border-radius: 15px;
}

.recent-chat-wrap .chat-content .self .self-msg-wrap .msg {
    background: rgba(139, 195, 74, 0.24) none repeat scroll 0 0;
    margin-left: 100px;
    border-radius: 15px;
}

.chat-cmplt-wrap {
    position: relative;
}

.chat-cmplt-wrap .chat-box-wrap {
    left: 0;
    position: relative;
    -webkit-transition: left 0.4s ease 0s;
    -o-transition: left 0.4s ease 0s;
    transition: left 0.4s ease 0s;
}

.chat-cmplt-wrap .recent-chat-box-wrap {
    position: absolute;
    right: -300px;
    top: 0;
    -webkit-transition: right 0.4s ease 0s;
    -o-transition: right 0.4s ease 0s;
    transition: right 0.4s ease 0s;
    width: 100%;
    z-index: 2;
}

.chat-cmplt-wrap.chat-box-slide .chat-box-wrap {
    left: -300px;
}

.chat-cmplt-wrap.chat-box-slide .recent-chat-box-wrap {
    right: 0;
}

.chat-cmplt-wrap.chat-for-widgets, .chat-cmplt-wrap.chat-for-widgets-1 {
    height: 370px;
    overflow: hidden;
}

.chat-cmplt-wrap.chat-for-widgets .recent-chat-box-wrap, .chat-cmplt-wrap.chat-for-widgets-1 .recent-chat-box-wrap {
    position: absolute;
    right: -100%;
}

.chat-cmplt-wrap.chat-for-widgets.chat-box-slide .chat-box-wrap, .chat-cmplt-wrap.chat-box-slide.chat-for-widgets-1 .chat-box-wrap {
    left: -100%;
}

.chat-cmplt-wrap.chat-for-widgets.chat-box-slide .recent-chat-box-wrap, .chat-cmplt-wrap.chat-box-slide.chat-for-widgets-1 .recent-chat-box-wrap {
    right: 0;
}

.chat-cmplt-wrap.chat-for-widgets-1 {
    height: 596px;
}

.chat-cmplt-wrap.chat-for-widgets-1 .chat-search {
    padding: 0;
}

.chat-cmplt-wrap.chat-for-widgets-1 .chat-search .input-group input {
    height: 52px;
    border-radius: 0;
    border: none;
}

.chat-cmplt-wrap.chat-for-widgets-1 .chat-search .input-group .input-group-btn {
    top: 18px;
}

.chat-cmplt-wrap.chat-for-widgets-1 .chat-box-wrap {
    float: left;
    width: 30%;
}

.chat-cmplt-wrap.chat-for-widgets-1 .recent-chat-box-wrap {
    border-left: 1px solid rgba(33, 33, 33, 0.05);
    float: left;
    position: relative;
    right: 0;
    width: 70%;
}

/*Widgets*/
.top-countries img {
    width: 20px;
}

/*Profile*/
.profile-box .fileupload.btn, .profile-box .wizard>.actions a.fileupload, .wizard>.actions .profile-box a.fileupload, .profile-box .dt-buttons .fileupload.dt-button, .dt-buttons .profile-box .fileupload.dt-button, .profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload, .tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload, .profile-box .sweet-alert button.fileupload, .sweet-alert .profile-box button.fileupload, .profile-box .owl-theme .owl-nav .fileupload[class*="owl-"], .owl-theme .owl-nav .profile-box .fileupload[class*="owl-"], .profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left,
.profile-box button.fileupload.fc-agendaWeek-button, .profile-box .fileupload.fc-prev-button, .profile-box .fileupload.fc-next-button, .profile-box .fileupload.fc-today-button {
    background: rgba(33, 33, 33, 0.5);
    border-radius: 0;
    padding: 3px 10px;
    border: none;
}

.profile-box .fileupload.btn:hover, .profile-box .wizard>.actions a.fileupload:hover, .wizard>.actions .profile-box a.fileupload:hover, .profile-box .dt-buttons .fileupload.dt-button:hover, .dt-buttons .profile-box .fileupload.dt-button:hover, .profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload:hover, .tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload:hover, .profile-box .sweet-alert button.fileupload:hover, .sweet-alert .profile-box button.fileupload:hover, .profile-box .owl-theme .owl-nav .fileupload[class*="owl-"]:hover, .owl-theme .owl-nav .profile-box .fileupload[class*="owl-"]:hover, .profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left:hover,
.profile-box button.fileupload.fc-agendaWeek-button:hover, .profile-box .fileupload.fc-prev-button:hover, .profile-box .fileupload.fc-next-button:hover, .profile-box .fileupload.fc-today-button:hover, .profile-box .fileupload.btn:active, .profile-box .wizard>.actions a.fileupload:active, .wizard>.actions .profile-box a.fileupload:active, .profile-box .dt-buttons .fileupload.dt-button:active, .dt-buttons .profile-box .fileupload.dt-button:active, .profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload:active, .tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload:active, .profile-box .sweet-alert button.fileupload:active, .sweet-alert .profile-box button.fileupload:active, .profile-box .owl-theme .owl-nav .fileupload[class*="owl-"]:active, .owl-theme .owl-nav .profile-box .fileupload[class*="owl-"]:active, .profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left:active,
.profile-box button.fileupload.fc-agendaWeek-button:active, .profile-box .fileupload.fc-prev-button:active, .profile-box .fileupload.fc-next-button:active, .profile-box .fileupload.fc-today-button:active, .profile-box .fileupload.btn:focus, .profile-box .wizard>.actions a.fileupload:focus, .wizard>.actions .profile-box a.fileupload:focus, .profile-box .dt-buttons .fileupload.dt-button:focus, .dt-buttons .profile-box .fileupload.dt-button:focus, .profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload:focus, .tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload:focus, .profile-box .sweet-alert button.fileupload:focus, .sweet-alert .profile-box button.fileupload:focus, .profile-box .owl-theme .owl-nav .fileupload[class*="owl-"]:focus, .owl-theme .owl-nav .profile-box .fileupload[class*="owl-"]:focus, .profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left:focus,
.profile-box button.fileupload.fc-agendaWeek-button:focus, .profile-box .fileupload.fc-prev-button:focus, .profile-box .fileupload.fc-next-button:focus, .profile-box .fileupload.fc-today-button:focus {
    background: rgba(33, 33, 33, 0.5);
}

.profile-box .fileupload.btn .btn-text, .profile-box .wizard>.actions a.fileupload .btn-text, .wizard>.actions .profile-box a.fileupload .btn-text, .profile-box .dt-buttons .fileupload.dt-button .btn-text, .dt-buttons .profile-box .fileupload.dt-button .btn-text, .profile-box .tablesaw-sortable th.tablesaw-sortable-head button.fileupload .btn-text, .tablesaw-sortable th.tablesaw-sortable-head .profile-box button.fileupload .btn-text, .profile-box .sweet-alert button.fileupload .btn-text, .sweet-alert .profile-box button.fileupload .btn-text, .profile-box .owl-theme .owl-nav .fileupload[class*="owl-"] .btn-text, .owl-theme .owl-nav .profile-box .fileupload[class*="owl-"] .btn-text, .profile-box button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right .btn-text,
.profile-box button.fileupload.fc-month-button.fc-state-default.fc-corner-left .btn-text,
.profile-box button.fileupload.fc-agendaWeek-button .btn-text, .profile-box .fileupload.fc-prev-button .btn-text, .profile-box .fileupload.fc-next-button .btn-text, .profile-box .fileupload.fc-today-button .btn-text {
    color: #fff;
    font-size: 13px;
}

.profile-box .profile-cover-pic .fileupload.btn, .profile-box .profile-cover-pic .wizard>.actions a.fileupload, .wizard>.actions .profile-box .profile-cover-pic a.fileupload, .profile-box .profile-cover-pic .dt-buttons .fileupload.dt-button, .dt-buttons .profile-box .profile-cover-pic .fileupload.dt-button, .profile-box .profile-cover-pic .tablesaw-sortable th.tablesaw-sortable-head button.fileupload, .tablesaw-sortable th.tablesaw-sortable-head .profile-box .profile-cover-pic button.fileupload, .profile-box .profile-cover-pic .sweet-alert button.fileupload, .sweet-alert .profile-box .profile-cover-pic button.fileupload, .profile-box .profile-cover-pic .owl-theme .owl-nav .fileupload[class*="owl-"], .owl-theme .owl-nav .profile-box .profile-cover-pic .fileupload[class*="owl-"], .profile-box .profile-cover-pic button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right,
.profile-box .profile-cover-pic button.fileupload.fc-month-button.fc-state-default.fc-corner-left,
.profile-box .profile-cover-pic button.fileupload.fc-agendaWeek-button, .profile-box .profile-cover-pic .fileupload.fc-prev-button, .profile-box .profile-cover-pic .fileupload.fc-next-button, .profile-box .profile-cover-pic .fileupload.fc-today-button {
    position: relative;
    float: right;
    z-index: 1;
}

.profile-box .profile-info {
    position: relative;
    z-index: 1;
}

.profile-box .profile-info .profile-img-wrap {
    border-radius: 2px;
    height: 143px;
    margin: -80px auto 0;
    padding: 4px;
    position: relative;
    width: 143px;
    background: #fff;
}

.profile-box .profile-info .profile-img-wrap img {
    width: 135px;
    height: 135px;
}

.profile-box .profile-info .profile-img-wrap .fileupload.btn, .profile-box .profile-info .profile-img-wrap .wizard>.actions a.fileupload, .wizard>.actions .profile-box .profile-info .profile-img-wrap a.fileupload, .profile-box .profile-info .profile-img-wrap .dt-buttons .fileupload.dt-button, .dt-buttons .profile-box .profile-info .profile-img-wrap .fileupload.dt-button, .profile-box .profile-info .profile-img-wrap .tablesaw-sortable th.tablesaw-sortable-head button.fileupload, .tablesaw-sortable th.tablesaw-sortable-head .profile-box .profile-info .profile-img-wrap button.fileupload, .profile-box .profile-info .profile-img-wrap .sweet-alert button.fileupload, .sweet-alert .profile-box .profile-info .profile-img-wrap button.fileupload, .profile-box .profile-info .profile-img-wrap .owl-theme .owl-nav .fileupload[class*="owl-"], .owl-theme .owl-nav .profile-box .profile-info .profile-img-wrap .fileupload[class*="owl-"], .profile-box .profile-info .profile-img-wrap button.fileupload.fc-agendaDay-button.fc-state-default.fc-corner-right,
.profile-box .profile-info .profile-img-wrap button.fileupload.fc-month-button.fc-state-default.fc-corner-left,
.profile-box .profile-info .profile-img-wrap button.fileupload.fc-agendaWeek-button, .profile-box .profile-info .profile-img-wrap .fileupload.fc-prev-button, .profile-box .profile-info .profile-img-wrap .fileupload.fc-next-button, .profile-box .profile-info .profile-img-wrap .fileupload.fc-today-button {
    position: absolute;
    right: 4px;
    bottom: 4px;
}

.profile-box .social-info {
    border-top: 1px solid rgba(33, 33, 33, 0.05);
    padding: 30px 15px;
    color: #212121;
}

.profile-box .social-info .counts {
    font-size: 21px;
}

.profile-box .social-info .counts-text {
    color: #878787;
}

/*Followers*/
.followers-list-wrap .follo-body .follo-data {
    padding: 10px 15px;
    border-bottom: 1px solid #dedede;
    display: block;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.followers-list-wrap .follo-body .follo-data .user-img {
    height: 35px;
    width: 35px;
    float: left;
    margin-right: 15px;
}

.followers-list-wrap .follo-body .follo-data .user-data {
    float: left;
}

.followers-list-wrap .follo-body .follo-data .user-data .name {
    color: #212121;
}

.followers-list-wrap .follo-body .follo-data .user-data .time {
    font-size: 12px;
    margin-top: 2px;
    width: 165px;
}

/*Small graph*/
.flex-stat {
    overflow: hidden;
}

.flex-stat li {
    float: left;
    width: 33.33%;
    text-align: center;
}

.flex-stat li>span {
    text-transform: capitalize;
}

.sm-data-box .counter {
    font-size: 24px;
}

.sm-data-box .data-wrap-left,
.sm-data-box .data-wrap-right {
    padding-top: 19px;
    padding-bottom: 19px;
    min-height: 99px;
}

.sm-data-box .data-wrap-right .data-right-rep-icon {
    display: inline-block;
    font-size: 40px;
    padding-top: 10px;
}

.sm-data-box.data-with-border .data-wrap-left {
    border-right: 1px solid rgba(135, 135, 135, 0.2);
}

.sm-data-box-1 .cus-sat-stat {
    font-size: 42px;
}

.sm-data-box-2 .table>tbody>tr>td, .sm-data-box-2 .jsgrid-table>tbody>tr>td, .sm-data-box-2 .table>tbody>tr>th, .sm-data-box-2 .jsgrid-table>tbody>tr>th, .sm-data-box-2 .table>tfoot>tr>td, .sm-data-box-2 .jsgrid-table>tfoot>tr>td, .sm-data-box-2 .table>tfoot>tr>th, .sm-data-box-2 .jsgrid-table>tfoot>tr>th, .sm-data-box-2 .table>thead>tr>td, .sm-data-box-2 .jsgrid-table>thead>tr>td, .sm-data-box-2 .table>thead>tr>th, .sm-data-box-2 .jsgrid-table>thead>tr>th {
    padding: 12.5px 15px;
}

.sm-data-box-3 .panel-heading .pull-left i.zmdi {
    padding-top: 11px;
}

.sm-data-box-3 .easypiechart {
    height: 165px;
    width: 165px;
}

.sm-data-box-3 .easypiechart .percent {
    font-size: 30px;
    line-height: 30px;
    margin-top: 56px;
}

.sm-data-box-3 .easypiechart .percent::after {
    font-size: 30px;
}

.sm-data-box-3 i.zmdi-caret-up, .sm-data-box-3 i.zmdi-caret-down {
    position: relative;
    top: 2px;
}

.lg-progress-box i {
    color: #e5e5e5;
    font-size: 56px;
}

/*Timeline*/
.timeline {
    list-style: none;
    padding: 10px 0;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 1px;
    background: #dedede;
    left: 50%;
    margin-left: -1.5px;
}

.timeline>li {
    margin-bottom: 150px;
    position: relative;
    width: 50%;
    float: left;
    clear: left;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline>li>.timeline-panel {
    width: calc(100% - 50px);
    float: left;
    position: relative;
    border-radius: 2px;
    background-color: rgba(33, 33, 33, 0.05);
}

.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 30px;
    right: -10px;
    display: inline-block;
    border-top: 10px solid transparent;
    border-left: 10px solid;
    border-right: 0 solid #fff;
    border-bottom: 10px solid transparent;
    border-left-color: rgba(33, 33, 33, 0.05);
    content: " ";
}

.timeline>li>.timeline-panel.bg-yellow:after {
    border-left-color: #f8b32d;
}

.timeline>li>.timeline-panel.bg-red:after {
    border-left-color: #f33923;
}

.timeline>li>.timeline-panel.bg-blue:after {
    border-left-color: #2196F3;
}

.timeline>li>.timeline-panel.bg-green:after {
    border-left-color: #0562e8;
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 16px;
    right: -22px;
    z-index: 100;
}

.timeline>li>.timeline-badge i {
    font-size: 16px;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 10px;
    left: -10px;
    right: auto;
    border-right-color: rgba(33, 33, 33, 0.05);
}

.timeline>li.timeline-inverted>.timeline-panel.bg-yellow:after {
    border-right-color: #f8b32d;
}

.timeline>li.timeline-inverted>.timeline-panel.bg-pink:after {
    border-right-color: #e7368d;
}

.timeline>li.timeline-inverted>.timeline-panel.bg-red:after {
    border-right-color: #f33923;
}

.timeline>li.timeline-inverted>.timeline-panel.bg-blue:after {
    border-right-color: #2196F3;
}

.timeline>li.timeline-inverted>.timeline-panel.bg-green:after {
    border-right-color: #0562e8;
}

.timeline>li.timeline-inverted {
    float: right;
    clear: right;
    margin-top: 150px;
}

.timeline>li.timeline-inverted>.timeline-badge {
    left: -22px;
}

/*Todo List*/
.todo-box-wrap .add-todo {
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #dedede;
}

.todo-box-wrap .add-todo span {
    text-transform: capitalize;
    font-size: 16px;
    width: 33.33%;
    float: left;
    text-align: center;
    vertical-align: middle;
}

.todo-box-wrap .add-todo a {
    width: 33.33%;
    float: left;
    font-size: 18px;
}

.todo-box-wrap .new-todo .input-group {
    width: 100%;
    display: block;
}

.todo-box-wrap .new-todo .input-group input {
    background: rgba(33, 33, 33, 0.05);
    border: medium none;
    height: 60px;
    padding-right: 60px;
}

.todo-box-wrap .new-todo .input-group .input-group-btn {
    position: absolute;
    right: 22px;
    top: 20px;
    z-index: 4;
}

.todo-box-wrap .new-todo .input-group .input-group-btn .btn, .todo-box-wrap .new-todo .input-group .input-group-btn .wizard>.actions a, .wizard>.actions .todo-box-wrap .new-todo .input-group .input-group-btn a, .todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button, .dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button, .todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button, .todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button, .sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button, .todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*="owl-"], .todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 0;
}

.todo-box-wrap .new-todo .input-group .input-group-btn .btn:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .wizard>.actions a:hover, .wizard>.actions .todo-box-wrap .new-todo .input-group .input-group-btn a:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button:hover, .dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:hover, .tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button:hover, .sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"]:hover, .owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*="owl-"]:hover, .todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:hover,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button:hover, .todo-box-wrap .new-todo .input-group .input-group-btn .btn:active, .todo-box-wrap .new-todo .input-group .input-group-btn .wizard>.actions a:active, .wizard>.actions .todo-box-wrap .new-todo .input-group .input-group-btn a:active, .todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button:active, .dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:active, .tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button:active, .sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"]:active, .owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*="owl-"]:active, .todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:active,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:active,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button:active, .todo-box-wrap .new-todo .input-group .input-group-btn .btn:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .wizard>.actions a:focus, .wizard>.actions .todo-box-wrap .new-todo .input-group .input-group-btn a:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button:focus, .dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button:focus, .tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button:focus, .sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"]:focus, .owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*="owl-"]:focus, .todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left:focus,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button:focus, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button:focus {
    background: transparent none repeat scroll 0 0;
}

.todo-box-wrap .new-todo .input-group .input-group-btn .btn i, .todo-box-wrap .new-todo .input-group .input-group-btn .wizard>.actions a i, .wizard>.actions .todo-box-wrap .new-todo .input-group .input-group-btn a i, .todo-box-wrap .new-todo .input-group .input-group-btn .dt-buttons .dt-button i, .dt-buttons .todo-box-wrap .new-todo .input-group .input-group-btn .dt-button i, .todo-box-wrap .new-todo .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button i, .tablesaw-sortable th.tablesaw-sortable-head .todo-box-wrap .new-todo .input-group .input-group-btn button i, .todo-box-wrap .new-todo .input-group .input-group-btn .sweet-alert button i, .sweet-alert .todo-box-wrap .new-todo .input-group .input-group-btn button i, .todo-box-wrap .new-todo .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"] i, .owl-theme .owl-nav .todo-box-wrap .new-todo .input-group .input-group-btn [class*="owl-"] i, .todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left i,
.todo-box-wrap .new-todo .input-group .input-group-btn button.fc-agendaWeek-button i, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-prev-button i, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-next-button i, .todo-box-wrap .new-todo .input-group .input-group-btn .fc-today-button i {
    color: rgba(33, 33, 33, 0.2);
    font-size: 20px;
}

.todo-list hr {
    margin: 0;
}

.todo-list .todo-item {
    padding: 10px 15px;
    transition: .2s ease;
    cursor: pointer;
}

.todo-list .todo-item:hover {
    background: rgba(33, 33, 33, 0.05);
}

.todo-list .todo-item .checkbox input[type="checkbox"] {
    width: 100%;
}

.todo-list .todo-item .checkbox input[type="checkbox"]+label {
    color: #212121;
    padding-left: 10px;
}

.todo-list .todo-item .checkbox input[type="checkbox"]:checked+label {
    color: #878787;
    text-decoration: line-through;
}

/*Activity*/
.sl-item {
    position: relative;
    padding-bottom: 18px;
    border-left: 1px solid #dedede;
}

.sl-item .sl-content {
    margin-left: 30px;
}

.sl-item .sl-content p {
    margin: 0;
}

.user-activity .sl-item .sl-content {
    border-bottom: 1px solid rgba(33, 33, 33, 0.05);
    padding-bottom: 15px;
}

.user-activity .sl-item .sl-content .activity-thumbnail {
    margin-top: 5px;
}

.user-activity .sl-item .sl-content .activity-thumbnail img {
    height: 65px;
    width: 65px;
    border-radius: 2px;
    margin-right: 10px;
}

.user-activity .sl-item .sl-content .activity-thumbnail img:last-child {
    margin-right: 0;
}

.user-activity .sl-item:last-child .sl-content {
    border-bottom: 0;
    padding-bottom: 0;
}

.sl-avatar {
    position: absolute;
    left: -15px;
}

.sl-avatar img {
    height: 30px;
    width: 30px;
}

/*Message Box*/
.message-box-wrap .msg-search {
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #dedede;
}

.message-box-wrap .msg-search span {
    text-transform: capitalize;
    font-size: 16px;
    width: 33.33%;
    float: left;
    text-align: center;
    vertical-align: middle;
}

.message-box-wrap .msg-search a {
    width: 33.33%;
    float: left;
    font-size: 18px;
}

.message-box-wrap .message-box-bottom-wrap .read-all {
    padding: 5px 15px;
    font-size: 13px;
    color: #878787;
    text-transform: capitalize;
}

.message-box>a, .review-box>a {
    display: block;
    border-bottom: 1px solid #dedede;
}

.message-box>a .sl-item, .review-box>a .sl-item {
    border: none;
    padding: 10px 15px;
    margin-left: 0;
    background: rgba(33, 33, 33, 0.05);
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
}

.message-box>a .sl-item:before, .review-box>a .sl-item:before, .message-box>a .sl-item:after, .review-box>a .sl-item:after {
    display: none;
}

.message-box>a .sl-item .sl-avatar, .review-box>a .sl-item .sl-avatar {
    left: auto;
}

.message-box>a .sl-item .sl-avatar img, .review-box>a .sl-item .sl-avatar img {
    width: 35px;
    height: 35px;
}

.message-box>a .sl-item .sl-content, .review-box>a .sl-item .sl-content {
    margin-left: 50px;
}

.message-box>a .sl-item .sl-content .message-time, .review-box>a .sl-item .sl-content .message-time {
    color: #878787;
}

.message-box>a .sl-item .sl-content .message-subject, .review-box>a .sl-item .sl-content .message-subject {
    font-size: 13px;
}

.message-box>a .sl-item .sl-content>p, .review-box>a .sl-item .sl-content>p {
    font-size: 13px;
    line-height: 16px;
}

.message-box>a .sl-item.unread-message, .review-box>a .sl-item.unread-message {
    background: transparent;
}

.message-box>a .sl-item.unread-message:hover, .review-box>a .sl-item.unread-message:hover {
    background: rgba(33, 33, 33, 0.05);
}

.message-box>a .sl-item.unread-message .sl-content .message-per, .review-box>a .sl-item.unread-message .sl-content .message-per, .message-box>a .sl-item.unread-message .sl-content .message-subject, .review-box>a .sl-item.unread-message .sl-content .message-subject {
    font-weight: 500;
}

.review-box hr {
    margin: 0;
}

.review-box .per-rating a {
    color: #0562e8;
    margin-right: 4px;
    font-size: 17px;
}

.review-box .per-rating span {
    font-size: 17px;
    color: #212121;
}

.review-box .sl-item {
    margin-left: 0;
    border: none;
    padding: 20px 15px;
}

.review-box .sl-item:before {
    display: none;
}

.review-box .sl-item .sl-content {
    margin-left: 0;
}

/*Inbox*/
.mail-box .panel {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    border-left: 1px solid #dedede;
    min-height: 1000px;
}

.mail-box .inbox-search .input-group {
    width: 100%;
}

.mail-box .inbox-search .input-group input {
    height: 25px;
    padding-right: 30px;
    width: 150px;
}

.mail-box .inbox-search .input-group .input-group-btn {
    position: absolute;
    right: 22px;
    top: 3px;
    z-index: 4;
    pointer-events: none;
}

.mail-box .inbox-search .input-group .input-group-btn .btn, .mail-box .inbox-search .input-group .input-group-btn .wizard>.actions a, .wizard>.actions .mail-box .inbox-search .input-group .input-group-btn a, .mail-box .inbox-search .input-group .input-group-btn .dt-buttons .dt-button, .dt-buttons .mail-box .inbox-search .input-group .input-group-btn .dt-button, .mail-box .inbox-search .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .mail-box .inbox-search .input-group .input-group-btn button, .mail-box .inbox-search .input-group .input-group-btn .sweet-alert button, .sweet-alert .mail-box .inbox-search .input-group .input-group-btn button, .mail-box .inbox-search .input-group .input-group-btn .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .mail-box .inbox-search .input-group .input-group-btn [class*="owl-"], .mail-box .inbox-search .input-group .input-group-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.mail-box .inbox-search .input-group .input-group-btn button.fc-month-button.fc-state-default.fc-corner-left,
.mail-box .inbox-search .input-group .input-group-btn button.fc-agendaWeek-button, .mail-box .inbox-search .input-group .input-group-btn .fc-prev-button, .mail-box .inbox-search .input-group .input-group-btn .fc-next-button, .mail-box .inbox-search .input-group .input-group-btn .fc-today-button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 0;
}

.user-head {
    position: relative;
}

.user-head .user-name h5 {
    margin-top: 15px;
}

ul.inbox-nav {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
}

ul.inbox-nav li {
    display: inline-block;
    width: 100%;
}

ul.inbox-nav li a {
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    text-transform: capitalize;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}

ul.inbox-nav li a i {
    font-size: 16px;
    padding-right: 10px;
    color: #878787;
}

ul.inbox-nav li a:hover, ul.inbox-nav li.active a, ul.inbox-nav li a:focus {
    background: rgba(33, 33, 33, 0.05);
}

.inbox-pagination {
    float: right;
}

.inbox-pagination li {
    float: left;
}

.inbox-head {
    color: #fff;
    min-height: 80px;
}

.table-inbox tr td {
    padding: 7px 15px !important;
}

.table-inbox tr td:hover {
    cursor: pointer;
}

.table-inbox tr td .zmdi-star.inbox-started,
.table-inbox tr td .zmdi-star:hover {
    color: #f33923;
}

.table-inbox tr.unread td {
    color: #212121;
    font-weight: 500;
}

.table-inbox tr.unread td i {
    color: #878787;
}

.table-inbox.table-hover>tbody>tr {
    background: rgba(33, 33, 33, 0.05) !important;
}

.table-inbox.table-hover>tbody>tr td {
    color: #212121;
}

.table-inbox.table-hover>tbody>tr td i {
    color: #878787;
}

.table-inbox.table-hover>tbody>tr.unread {
    background: transparent !important;
}

.table-inbox.table-hover>tbody>tr.unread:hover {
    background-color: rgba(33, 33, 33, 0.05) !important;
}

.time-chat-history {
    min-width: 70px;
}

.mail-option {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}

.mail-option .chk-all {
    display: inline-block;
    padding: 0;
}

.mail-option .chk-all .btn, .mail-option .chk-all .wizard>.actions a, .wizard>.actions .mail-option .chk-all a, .mail-option .chk-all .dt-buttons .dt-button, .dt-buttons .mail-option .chk-all .dt-button, .mail-option .chk-all .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .mail-option .chk-all button, .mail-option .chk-all .sweet-alert button, .sweet-alert .mail-option .chk-all button, .mail-option .chk-all .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .mail-option .chk-all [class*="owl-"], .mail-option .chk-all button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.mail-option .chk-all button.fc-month-button.fc-state-default.fc-corner-left,
.mail-option .chk-all button.fc-agendaWeek-button, .mail-option .chk-all .fc-prev-button, .mail-option .chk-all .fc-next-button, .mail-option .chk-all .fc-today-button, .mail-option .chk-all .dropdown {
    display: inline-block;
    padding: 0 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-right: 5px;
    background: transparent;
    color: #212121;
}

.mail-option .chk-all .btn i, .mail-option .chk-all .wizard>.actions a i, .wizard>.actions .mail-option .chk-all a i, .mail-option .chk-all .dt-buttons .dt-button i, .dt-buttons .mail-option .chk-all .dt-button i, .mail-option .chk-all .tablesaw-sortable th.tablesaw-sortable-head button i, .tablesaw-sortable th.tablesaw-sortable-head .mail-option .chk-all button i, .mail-option .chk-all .sweet-alert button i, .sweet-alert .mail-option .chk-all button i, .mail-option .chk-all .owl-theme .owl-nav [class*="owl-"] i, .owl-theme .owl-nav .mail-option .chk-all [class*="owl-"] i, .mail-option .chk-all button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.mail-option .chk-all button.fc-month-button.fc-state-default.fc-corner-left i,
.mail-option .chk-all button.fc-agendaWeek-button i, .mail-option .chk-all .fc-prev-button i, .mail-option .chk-all .fc-next-button i, .mail-option .chk-all .fc-today-button i, .mail-option .chk-all .dropdown i {
    color: #878787;
}

.mail-option .chk-all input[type="checkbox"] {
    margin-top: 0;
}

.mail-option .chk-all .checkbox {
    min-height: auto !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.mail-option .btn-group {
    margin-right: 5px;
}

.mail-option .btn-group a.all {
    border: medium none;
}

.heading-inbox .btn, .heading-inbox .wizard>.actions a, .wizard>.actions .heading-inbox a, .heading-inbox .dt-buttons .dt-button, .dt-buttons .heading-inbox .dt-button, .heading-inbox .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .heading-inbox button, .heading-inbox .sweet-alert button, .sweet-alert .heading-inbox button, .heading-inbox .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .heading-inbox [class*="owl-"], .heading-inbox button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.heading-inbox button.fc-month-button.fc-state-default.fc-corner-left,
.heading-inbox button.fc-agendaWeek-button, .heading-inbox .fc-prev-button, .heading-inbox .fc-next-button, .heading-inbox .fc-today-button, .heading-inbox .dropdown {
    display: inline-block;
    padding: 0 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
}

.heading-inbox .btn i, .heading-inbox .wizard>.actions a i, .wizard>.actions .heading-inbox a i, .heading-inbox .dt-buttons .dt-button i, .dt-buttons .heading-inbox .dt-button i, .heading-inbox .tablesaw-sortable th.tablesaw-sortable-head button i, .tablesaw-sortable th.tablesaw-sortable-head .heading-inbox button i, .heading-inbox .sweet-alert button i, .sweet-alert .heading-inbox button i, .heading-inbox .owl-theme .owl-nav [class*="owl-"] i, .owl-theme .owl-nav .heading-inbox [class*="owl-"] i, .heading-inbox button.fc-agendaDay-button.fc-state-default.fc-corner-right i,
.heading-inbox button.fc-month-button.fc-state-default.fc-corner-left i,
.heading-inbox button.fc-agendaWeek-button i, .heading-inbox .fc-prev-button i, .heading-inbox .fc-next-button i, .heading-inbox .fc-today-button i, .heading-inbox .dropdown i {
    font-size: 17px;
    color: #878787;
}

.heading-inbox .btn i.zmdi-chevron-left, .heading-inbox .wizard>.actions a i.zmdi-chevron-left, .wizard>.actions .heading-inbox a i.zmdi-chevron-left, .heading-inbox .dt-buttons .dt-button i.zmdi-chevron-left, .dt-buttons .heading-inbox .dt-button i.zmdi-chevron-left, .heading-inbox .tablesaw-sortable th.tablesaw-sortable-head button i.zmdi-chevron-left, .tablesaw-sortable th.tablesaw-sortable-head .heading-inbox button i.zmdi-chevron-left, .heading-inbox .sweet-alert button i.zmdi-chevron-left, .sweet-alert .heading-inbox button i.zmdi-chevron-left, .heading-inbox .owl-theme .owl-nav [class*="owl-"] i.zmdi-chevron-left, .owl-theme .owl-nav .heading-inbox [class*="owl-"] i.zmdi-chevron-left, .heading-inbox button.fc-agendaDay-button.fc-state-default.fc-corner-right i.zmdi-chevron-left,
.heading-inbox button.fc-month-button.fc-state-default.fc-corner-left i.zmdi-chevron-left,
.heading-inbox button.fc-agendaWeek-button i.zmdi-chevron-left, .heading-inbox .fc-prev-button i.zmdi-chevron-left, .heading-inbox .fc-next-button i.zmdi-chevron-left, .heading-inbox .fc-today-button i.zmdi-chevron-left, .heading-inbox .dropdown i.zmdi-chevron-left {
    font-size: 27px;
}

.file-name {
    color: #212121;
}

.date {
    font-size: 13px;
}

.download-blocks a {
    color: #878787;
}

.sender-img-wrap img {
    width: 40px;
    height: 40px;
}

.heading-inbox .compose-btn .btn, .heading-inbox .compose-btn .wizard>.actions a, .wizard>.actions .heading-inbox .compose-btn a, .heading-inbox .compose-btn .dt-buttons .dt-button, .dt-buttons .heading-inbox .compose-btn .dt-button, .heading-inbox .compose-btn .tablesaw-sortable th.tablesaw-sortable-head button, .tablesaw-sortable th.tablesaw-sortable-head .heading-inbox .compose-btn button, .heading-inbox .compose-btn .sweet-alert button, .sweet-alert .heading-inbox .compose-btn button, .heading-inbox .compose-btn .owl-theme .owl-nav [class*="owl-"], .owl-theme .owl-nav .heading-inbox .compose-btn [class*="owl-"], .heading-inbox .compose-btn button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.heading-inbox .compose-btn button.fc-month-button.fc-state-default.fc-corner-left,
.heading-inbox .compose-btn button.fc-agendaWeek-button, .heading-inbox .compose-btn .fc-prev-button, .heading-inbox .compose-btn .fc-next-button, .heading-inbox .compose-btn .fc-today-button {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #878787;
    display: inline-block;
    padding: 0 5px;
    background: transparent;
}

.sender-info .pull-right .dropdown-toggle>i {
    font-size: 17px;
    color: #878787;
    vertical-align: middle;
}

.attachment-mail li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.attachment-mail li .attached-img-container {
    width: 120px;
    height: 120px;
    display: block;
    background-position: center top;
    background-size: cover;
    margin-bottom: 10px;
    border-radius: 2px;
}

.attachment-mail li:last-child {
    margin-right: 0;
}

/*Contact List*/
.contact-list .panel {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    border-left: 1px solid #dedede;
}

.contact-list .table>tbody>tr>td, .contact-list .jsgrid-table>tbody>tr>td {
    padding: 12px 15px;
}

.contact-list .table>tbody>tr>td a, .contact-list .jsgrid-table>tbody>tr>td a {
    font-weight: 500;
}

.contact-list .create-label {
    display: block;
}

/*Contact Cards*/
.contact-card .card-user-img {
    height: 65px;
    width: 65px;
}

.contact-card .panel-heading .user-detail-wrap {
    margin-top: 8px;
}

.contact-card .panel-heading .user-detail-wrap .card-user-name {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
}

.contact-card .panel-heading .user-detail-wrap .card-user-desn {
    color: #fff;
    text-transform: capitalize;
    font-size: 13px;
}

.contact-card .panel-heading .pull-right {
    margin-top: 22px;
}

.contact-card .card-user-email, .contact-card .card-user-phone {
    color: #878787;
}

/*Login Form*/
.page-wrapper.auth-page {
    background: #fff;
}

.sp-header {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.box-layout .sp-header {
    max-width: 1400px;
    left: auto;
    right: auto;
}

.sp-logo-wrap {
    padding-top: 19px;
    padding-left: 15px;
}

.sp-logo-wrap .brand-img {
    position: relative;
    top: 2px;
}

.sp-logo-wrap .brand-text {
    color: #212121;
    display: inline-block;
    font-size: 21px;
    text-transform: capitalize;
}

.form-group.pull-right {
    padding-top: 19px;
    padding-right: 15px;
}

.auth-form-wrap {
    padding: 100px 0;
}

.auth-form {
    width: 400px;
}

.auth-form a.txt-danger:hover {
    color: #f33923;
}

/*Error Page*/
.error-page .brand-text {
    color: #212121;
}

.error-head {
    font-size: 112px;
    font-style: italic;
    line-height: 112px;
}

.error-bg-img {
    background: url("../../static/images/errorbg.jpg");
    background-size: cover;
}

/*Products*/
.col-item {
    background: #fff;
}

.col-item .photo {
    overflow: hidden;
    position: relative;
}

.col-item .photo .options {
    position: absolute;
    right: 10px;
    top: 5px;
}

.col-item .photo img {
    margin: 0 auto;
    width: 85%;
}

.col-item .info {
    background: #f7f7f9 none repeat scroll 0 0;
    padding: 15px 0 15px 15px;
}

/*Cropperjs*/
.img-container, .img-preview {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
}

.img-container {
    min-height: 200px;
    max-height: 469px;
    margin-bottom: 16px;
}

.img-container>img {
    max-width: 100%;
}

.docs-preview {
    margin-right: -16px;
}

.img-preview {
    float: left;
    margin-right: 8px;
    margin-bottom: 8px;
    overflow: hidden;
}

.img-preview>img {
    max-width: 100%;
}

.preview-lg {
    width: 255px;
    height: 143px;
}

.preview-md {
    width: 128px;
    height: 72px;
}

.preview-sm {
    width: 72px;
    height: 41px;
}

.preview-xs {
    width: 40px;
    height: 24px;
    margin-right: 0;
}

.docs-data>.input-group {
    margin-bottom: 8px;
}

.docs-data>.input-group>label {
    min-width: 80px;
}

.docs-data>.input-group>span {
    min-width: 50px;
}

.docs-buttons>.btn, .wizard>.actions .disabled .docs-buttons>a, .wizard>.actions .docs-buttons>a, .dt-buttons .docs-buttons>.dt-button, .tablesaw-sortable th.tablesaw-sortable-head .docs-buttons>button, .sweet-alert .docs-buttons>button, .owl-theme .owl-nav .docs-buttons>[class*="owl-"], .docs-buttons>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-buttons>button.fc-month-button.fc-state-default.fc-corner-left,
.docs-buttons>button.fc-agendaWeek-button, .docs-buttons>.fc-prev-button, .docs-buttons>.fc-next-button, .docs-buttons>.fc-today-button, .docs-buttons>.btn-group, .docs-buttons>.form-control, .select2-container--default .select2-selection--single .docs-buttons>.select2-selection__rendered,
.select2-container--default .docs-buttons>.select2-selection--single, .docs-buttons>.select2-selection.select2-selection--multiple, .wizard .content>.body .docs-buttons>input, .mce-floatpanel .docs-buttons>.mce-textbox, .mce-floatpanel .docs-buttons>.mce-btn, .dataTables_wrapper .dataTables_filter .docs-buttons>input, .dataTables_wrapper .dataTables_length .docs-buttons>select, .jsgrid-cell .docs-buttons>input, .jsgrid-cell .docs-buttons>select, .tablesaw-bar .btn-select .docs-buttons>select, .docs-buttons>.dd-handle, .docs-buttons>.dd3-content, .app-search .docs-buttons>.form-control:focus, .select2-container--default .select2-selection--single .app-search .docs-buttons>.select2-selection__rendered:focus,
.select2-container--default .app-search .docs-buttons>.select2-selection--single:focus, .app-search .docs-buttons>.select2-selection.select2-selection--multiple:focus, .wizard .content>.body .app-search .docs-buttons>input:focus, .mce-floatpanel .app-search .docs-buttons>.mce-textbox:focus, .mce-floatpanel .app-search .docs-buttons>.mce-btn:focus, .dataTables_wrapper .dataTables_filter .app-search .docs-buttons>input:focus, .dataTables_wrapper .dataTables_length .app-search .docs-buttons>select:focus, .app-search .jsgrid-cell .docs-buttons>input:focus, .jsgrid-cell .app-search .docs-buttons>input:focus, .app-search .jsgrid-cell .docs-buttons>select:focus, .jsgrid-cell .app-search .docs-buttons>select:focus, .tablesaw-bar .btn-select .app-search .docs-buttons>select:focus, .app-search .docs-buttons>.dd-handle:focus, .app-search .docs-buttons>.dd3-content:focus {
    margin-right: 4px;
    margin-bottom: 8px;
}

.docs-toggles>.btn, .wizard>.actions .disabled .docs-toggles>a, .wizard>.actions .docs-toggles>a, .dt-buttons .docs-toggles>.dt-button, .tablesaw-sortable th.tablesaw-sortable-head .docs-toggles>button, .sweet-alert .docs-toggles>button, .owl-theme .owl-nav .docs-toggles>[class*="owl-"], .docs-toggles>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-toggles>button.fc-month-button.fc-state-default.fc-corner-left,
.docs-toggles>button.fc-agendaWeek-button, .docs-toggles>.fc-prev-button, .docs-toggles>.fc-next-button, .docs-toggles>.fc-today-button, .docs-toggles>.btn-group, .docs-toggles>.dropdown {
    margin-bottom: 8px;
}

.docs-tooltip {
    display: block;
    margin: -8px -12px;
    padding: 8px 12px;
}

.docs-aspect-ratios, .docs-view-modes {
    width: 100%;
}

.docs-aspect-ratios>.btn, .wizard>.actions .disabled .docs-aspect-ratios>a, .wizard>.actions .docs-aspect-ratios>a, .dt-buttons .docs-aspect-ratios>.dt-button, .tablesaw-sortable th.tablesaw-sortable-head .docs-aspect-ratios>button, .sweet-alert .docs-aspect-ratios>button, .owl-theme .owl-nav .docs-aspect-ratios>[class*="owl-"], .docs-aspect-ratios>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-aspect-ratios>button.fc-month-button.fc-state-default.fc-corner-left,
.docs-aspect-ratios>button.fc-agendaWeek-button, .docs-aspect-ratios>.fc-prev-button, .docs-aspect-ratios>.fc-next-button, .docs-aspect-ratios>.fc-today-button {
    width: 20%;
}

.docs-view-modes>.btn, .wizard>.actions .disabled .docs-view-modes>a, .wizard>.actions .docs-view-modes>a, .dt-buttons .docs-view-modes>.dt-button, .tablesaw-sortable th.tablesaw-sortable-head .docs-view-modes>button, .sweet-alert .docs-view-modes>button, .owl-theme .owl-nav .docs-view-modes>[class*="owl-"], .docs-view-modes>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
.docs-view-modes>button.fc-month-button.fc-state-default.fc-corner-left,
.docs-view-modes>button.fc-agendaWeek-button, .docs-view-modes>.fc-prev-button, .docs-view-modes>.fc-next-button, .docs-view-modes>.fc-today-button {
    width: 25%;
}

.docs-options .dropdown-menu {
    width: 100%;
}

.docs-options .dropdown-menu>li {
    padding: 4px 16px;
}

.docs-options .dropdown-menu>li:hover {
    background-color: #f7f7f7;
}

.docs-options .dropdown-menu>li>label {
    margin-bottom: 0;
}

.docs-cropped .modal-body {
    text-align: center;
}

.docs-cropped .modal-body>img, .docs-cropped .modal-body>canvas {
    max-width: 100%;
}

.common-credits>li {
    display: block;
    margin-bottom: 15px;
}

.common-credits>li:last-child {
    margin-bottom: 0;
}

/****************************/
/** Mediaqueries **/
/****************************/
@media (max-width: 1024px) {
    .fixed-sidebar-left {
        width: 60px;
    }

    .fixed-sidebar-left .side-nav li.navigation-header>span {
        display: none;
    }

    .fixed-sidebar-left .side-nav li.navigation-header>i {
        display: block;
    }

    .fixed-sidebar-left .collapse.in {
        display: none;
    }

    .fixed-sidebar-left:hover {
        width: 225px;
    }

    .fixed-sidebar-left:hover .side-nav li.navigation-header>span {
        display: block;
    }

    .fixed-sidebar-left:hover .side-nav li.navigation-header>i {
        display: none;
    }

    .fixed-sidebar-left:hover .collapse.in {
        display: block;
    }

    .fixed-sidebar-left:hover .right-nav-text {
        opacity: 1;
    }

    .fixed-sidebar-left .right-nav-text {
        opacity: 0;
    }

    .page-wrapper {
        margin-left: 60px;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav-header {
        width: auto;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
        opacity: 0;
        visibility: hidden;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.mega-menu {
        width: calc(100% - 100px);
    }

    .sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header {
        width: 225px;
    }

    .sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
        opacity: 1;
        visibility: visible;
    }

    .slide-nav-toggle .fixed-sidebar-left {
        width: 225px;
    }

    .slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header>span {
        display: block;
    }

    .slide-nav-toggle .fixed-sidebar-left .side-nav li.navigation-header>i {
        display: none;
    }

    .slide-nav-toggle .fixed-sidebar-left .collapse.in {
        display: block;
    }

    .slide-nav-toggle .fixed-sidebar-left .right-nav-text {
        opacity: 1;
    }

    .slide-nav-toggle .page-wrapper {
        margin-left: 0;
        left: 225px;
    }

    .slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header {
        width: 225px;
    }

    .slide-nav-toggle .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-text {
        opacity: 1;
        visibility: visible;
    }
}

@media (max-width: 1200px) {
    .table-responsive .table>tbody>tr>td, .table-responsive .jsgrid-table>tbody>tr>td, .table-responsive .table>tbody>tr>th, .table-responsive .jsgrid-table>tbody>tr>th, .table-responsive .table>tfoot>tr>td, .table-responsive .jsgrid-table>tfoot>tr>td, .table-responsive .table>tfoot>tr>th, .table-responsive .jsgrid-table>tfoot>tr>th, .table-responsive .table>thead>tr>td, .table-responsive .jsgrid-table>thead>tr>td, .table-responsive .table>thead>tr>th, .table-responsive .jsgrid-table>thead>tr>th {
        white-space: nowrap;
    }
}

@media (min-width: 1025px) {

    .mobile-only-nav.collapse,
    .navbar.navbar-inverse.navbar-fixed-top .top-nav-search.collapse {
        display: block;
        height: auto !important;
    }

    .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn {
        pointer-events: none;
    }
}

@media (max-width: 767px) {
    .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap {
        text-align: center;
        width: 100%;
    }

    .mobile-only-view {
        display: block !important;
    }

    .fixed-sidebar-left {
        margin-left: -225px;
        width: 225px;
    }

    .fixed-sidebar-left .right-nav-text {
        opacity: 1;
    }

    .mobile-nav-open .fixed-sidebar-right,
    .mobile-nav-open .fixed-sidebar-left {
        top: 132px;
    }

    .page-wrapper {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .slide-nav-toggle .fixed-sidebar-left {
        margin-left: 0;
    }

    .slide-nav-toggle .page-wrapper {
        left: 225px;
        margin-left: 0;
    }

    .slide-nav-toggle.sidebar-hover .navbar.navbar-inverse.navbar-fixed-top .nav-header {
        width: 100%;
    }

    .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand {
        position: relative;
        z-index: 2;
    }

    .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand .nav-header {
        padding-left: 0;
        text-align: center;
    }

    .navbar.navbar-inverse.navbar-fixed-top .mobile-only-brand .nav-header .logo-wrap .brand-text {
        opacity: 1;
        visibility: visible;
    }

    .navbar.navbar-inverse.navbar-fixed-top a#toggle_mobile_nav {
        position: absolute;
        right: 21px;
        top: 0;
    }

    .navbar.navbar-inverse.navbar-fixed-top .top-nav-search {
        left: 0;
        margin-left: 0;
        position: absolute;
        width: 100%;
        padding-top: 0;
    }

    .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group input {
        border-radius: 0;
        height: 66px;
        border: medium none;
    }

    .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn {
        top: 23px;
    }

    .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn button.btn-default i:before, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn .tablesaw-sortable th.tablesaw-sortable-head button i:before, .tablesaw-sortable th.tablesaw-sortable-head .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn button i:before, .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn .owl-theme .owl-nav button[class*="owl-"] i:before, .owl-theme .owl-nav .navbar.navbar-inverse.navbar-fixed-top .top-nav-search .input-group .input-group-btn button[class*="owl-"] i:before {
        content: "\f136";
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.mega-menu {
        right: 0;
        width: 100%;
    }

    .mobile-nav-open .navbar.navbar-inverse.navbar-fixed-top .mobile-only-nav {
        top: 66px;
    }



    .inbox-pagination li:first-child {
        display: none;
    }

    .sender-info .inbox-detail-time-2 {
        display: none;
    }

    /*Responsive Tabs*/
    .nav.nav-tabs.nav-tabs-responsive {
        text-align: center;
        position: relative;
    }

    .nav.nav-tabs.nav-tabs-responsive>li {
        display: none;
        float: none;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.active, .nav.nav-tabs.nav-tabs-responsive>li.prev, .nav.nav-tabs.nav-tabs-responsive>li.next {
        display: inline-block;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.prev, .nav.nav-tabs.nav-tabs-responsive>li.next {
        position: absolute;
        top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.prev>a:before, .nav.nav-tabs.nav-tabs-responsive>li.prev>a:after, .nav.nav-tabs.nav-tabs-responsive>li.next>a:before, .nav.nav-tabs.nav-tabs-responsive>li.next>a:after {
        display: inline-block;
        font-family: Material-Design-Iconic-Font, sans-serif;
        -webkit-font-feature-settings: normal;
        font-feature-settings: normal;
        -webkit-font-kerning: auto;
        font-kerning: auto;
        -webkit-font-language-override: normal;
        font-language-override: normal;
        font-size-adjust: none;
        font-stretch: normal;
        font-style: normal;
        font-synthesis: weight style;
        font-variant: normal;
        font-weight: normal;
        text-rendering: auto;
        position: relative;
        top: 3px;
        font-size: 20px;
        line-height: 12px;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.prev {
        left: 0;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.prev>a:before {
        content: '\f2fa';
        margin-right: 10px;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.next {
        right: 0;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.next>a:after {
        content: '\f2fb';
        margin-left: 10px;
    }

    .fc-toolbar>div {
        display: block;
        width: 100%;
        float: left !important;
        margin-bottom: 25px;
    }

    .fc-toolbar {
        overflow: hidden;
    }
}

@media (max-width: 991px) {
    .dataTables_wrapper .dataTables_length {
        display: none;
    }

    .product-detail-wrap, .inbox-head {
        margin-top: 30px;
    }

    .mail-box aside.col-lg-3.col-md-4 {
        padding-right: 15px !important;
    }

    .mail-box aside.col-lg-3.col-md-4 h6, .mail-box aside.col-lg-3.col-md-4 .panel-title, .mail-box aside.col-lg-3.col-md-4 .inbox-nav, .mail-box aside.col-lg-3.col-md-4 .chat-list-wrap {
        display: none;
    }

    .mail-box aside.col-lg-9.col-md-8 {
        padding-left: 15px !important;
    }

    .mail-box aside.col-lg-9.col-md-8 .view-message .label, .mail-box aside.col-lg-9.col-md-8 .view-message .jvectormap-tip {
        display: none;
    }

    .contact-list aside.col-lg-2.col-md-4 {
        padding-right: 15px !important;
    }

    .contact-list aside.col-lg-2.col-md-4 .inbox-nav, .contact-list aside.col-lg-2.col-md-4 a.create-label {
        display: none;
    }

    .contact-list aside.col-lg-10.col-md-8 {
        padding-left: 15px !important;
    }

    .file-manager h6, .file-manager .panel-title, .file-manager .tag-list {
        display: none;
    }

    .ibox {
        margin-bottom: 0;
    }
}

@media (max-width: 950px) {

    .panel-dropdown .panel-heading>.pull-left, .panel-dropdown .panel-heading>.pull-right,
    .panel-tabs .panel-heading>.pull-left,
    .panel-tabs .panel-heading>.pull-right {
        width: 100%;
    }

    .panel-dropdown .panel-heading>.pull-right,
    .panel-tabs .panel-heading>.pull-right {
        margin-top: 10px;
    }

    .panel-dropdown .panel-heading>.pull-right .dropdown-menu-right {
        left: 0;
        right: auto;
    }
}

@media (max-width: 828px) {
    .wizard .steps ul>li {
        width: 100%;
    }
}

@media (max-width: 796px) {
    footer.footer .col-sm-5 {
        display: none;
    }

    footer.footer .col-sm-7 {
        width: 100%;
        text-align: center !important;
    }
}

@media (max-width: 767px) {

    .breadcrumb, .heading-bg .btn-outline-white.btn-rounded, .heading-bg button.btn-outline-white.fc-agendaDay-button.fc-state-default.fc-corner-right,
    .heading-bg button.btn-outline-white.fc-month-button.fc-state-default.fc-corner-left,
    .heading-bg button.btn-outline-white.fc-agendaWeek-button {
        display: none;
    }

    ul.timeline:before {
        left: 23px;
    }

    ul.timeline>li {
        margin-bottom: 0px;
        position: relative;
        width: 100%;
        float: left;
        clear: left;
    }

    ul.timeline>li>.timeline-badge {
        left: 0px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline>li>.timeline-panel {
        float: right;
        width: calc(100% - 65px);
        width: -webkit-calc(100% - 65px);
    }

    ul.timeline>li>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 10px;
        left: -10px;
        right: auto;
    }

    ul.timeline>li>.timeline-panel.bg-yellow:after {
        border-right-color: #f8b32d;
    }

    ul.timeline>li>.timeline-panel.bg-pink:after {
        border-right-color: #e7368d;
    }

    ul.timeline>li>.timeline-panel.bg-red:after {
        border-right-color: #f33923;
    }

    ul.timeline>li>.timeline-panel.bg-blue:after {
        border-right-color: #2196F3;
    }

    ul.timeline>li>.timeline-panel.bg-green:after {
        border-right-color: #0562e8;
    }

    .timeline>li.timeline-inverted {
        float: left;
        clear: left;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .timeline>li.timeline-inverted>.timeline-badge {
        left: 0;
    }
}

@media (max-width: 600px) {
    .product-detail-wrap {
        margin-top: 30px;
    }

    .product-detail-wrap .btn-group {
        margin-top: 20px;
        width: 100%;
    }

    .product-detail-description ul li {
        width: 100%;
    }

    .product-detail-description ul li a {
        padding-left: 0 !important;
    }

    .clear-todo-btn {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .img-container {
        min-height: 469px;
    }
}

@media (max-width: 768px) {
    .col-item .photo img {
        width: 100%;
    }
}

@media (max-width: 565px) {
    .clear-todo-btn {
        margin-top: 20px;
    }

    .nav-tabs>li, .nav-tab-reverse .nav-tabs>li {
        float: none;
        margin-right: 0;
        margin-left: 0;
    }

    .nav-tabs>li:first-child>a, .nav-tab-reverse .nav-tabs>li:first-child>a {
        border-radius: 0;
        border-top-left-radius: 10px;
    }

    .nav-tabs>li:last-child, .nav-tab-reverse .nav-tabs>li:last-child {
        margin-bottom: -1px;
    }

    .nav-tabs>li:last-child>a, .nav-tab-reverse .nav-tabs>li:last-child>a {
        border-radius: 0;
        border-bottom-left-radius: 10px;
    }

    .custom-tab-1 .nav-tabs>li:first-child>a, .nav-tab-reverse .nav-tabs>li:first-child>a {
        border-top-left-radius: 0;
    }

    .custom-tab-1 .nav-tabs>li:last-child>a, .nav-tab-reverse .nav-tabs>li:last-child>a {
        border-bottom-left-radius: 0;
    }

    .nav.nav-tabs.nav-tabs-responsive>li.next a span, .nav.nav-tabs.nav-tabs-responsive>li.prev a span {
        display: none;
    }

    .nav-pills>li {
        float: none;
        margin-bottom: 5px;
        margin-left: 0 !important;
    }

    .nav-pills>li:last-child {
        margin-bottom: 0;
    }

    .fixed-sidebar-right .right-sidebar .nav-tabs>li, .fixed-sidebar-right .right-sidebar .nav-tab-reverse .nav-tabs>li {
        float: left;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.mega-menu li.col-menu-list {
        width: 100%;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.alert-dropdown {
        width: 100%;
        min-width: auto;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav>li.alert-drp {
        position: static;
    }

    .sp-header .form-group.pull-right span {
        display: none !important;
    }
}

@media (max-width: 458px) {
    .auth-form {
        width: 100%;
    }
}

@media (max-width: 414px) {
    .navbar.navbar-inverse.navbar-fixed-top .nav>li .dropdown-menu.app-dropdown {
        width: 100%;
        min-width: auto;
    }

    .navbar.navbar-inverse.navbar-fixed-top .nav>li.app-drp {
        position: static;
    }
}

@media (max-width: 400px) {
    .btn-group-crop {
        margin-right: -16px !important;
    }

    .btn-group-crop>.btn, .wizard>.actions .disabled .btn-group-crop>a, .wizard>.actions .btn-group-crop>a, .dt-buttons .btn-group-crop>.dt-button, .tablesaw-sortable th.tablesaw-sortable-head .btn-group-crop>button, .sweet-alert .btn-group-crop>button, .owl-theme .owl-nav .btn-group-crop>[class*="owl-"], .btn-group-crop>button.fc-agendaDay-button.fc-state-default.fc-corner-right,
    .btn-group-crop>button.fc-month-button.fc-state-default.fc-corner-left,
    .btn-group-crop>button.fc-agendaWeek-button, .btn-group-crop>.fc-prev-button, .btn-group-crop>.fc-next-button, .btn-group-crop>.fc-today-button {
        padding-left: 8px;
        padding-right: 8px;
    }

    .typeahead,
    .tt-query,
    .tt-hint {
        width: 100%;
    }
}

@media (max-width: 378px) {

    .form-actions button.btn.btn-success, .form-actions .dt-buttons button.btn-success.dt-button, .dt-buttons .form-actions button.btn-success.dt-button,
    .form-actions .dt-buttons button.dt-button.fc-agendaWeek-button,
    .dt-buttons .form-actions button.dt-button.fc-agendaWeek-button, .form-actions .dt-buttons button.dt-button.fc-prev-button, .dt-buttons .form-actions button.dt-button.fc-prev-button, .form-actions .dt-buttons button.dt-button.fc-next-button, .dt-buttons .form-actions button.dt-button.fc-next-button, .form-actions .dt-buttons button.dt-button.fc-today-button, .dt-buttons .form-actions button.dt-button.fc-today-button, .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.btn-success, .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.btn-success,
    .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-agendaWeek-button,
    .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-agendaWeek-button, .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-prev-button, .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-prev-button, .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-next-button, .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-next-button, .form-actions .tablesaw-sortable th.tablesaw-sortable-head button.fc-today-button, .tablesaw-sortable th.tablesaw-sortable-head .form-actions button.fc-today-button, .form-actions .sweet-alert button.btn-success, .sweet-alert .form-actions button.btn-success,
    .form-actions .sweet-alert button.fc-agendaWeek-button,
    .sweet-alert .form-actions button.fc-agendaWeek-button, .form-actions .sweet-alert button.fc-prev-button, .sweet-alert .form-actions button.fc-prev-button, .form-actions .sweet-alert button.fc-next-button, .sweet-alert .form-actions button.fc-next-button, .form-actions .sweet-alert button.fc-today-button, .sweet-alert .form-actions button.fc-today-button, .form-actions .owl-theme .owl-nav button.btn-success[class*="owl-"], .owl-theme .owl-nav .form-actions button.btn-success[class*="owl-"],
    .form-actions .owl-theme .owl-nav button[class*="owl-"].fc-agendaWeek-button,
    .owl-theme .owl-nav .form-actions button[class*="owl-"].fc-agendaWeek-button, .form-actions .owl-theme .owl-nav button[class*="owl-"].fc-prev-button, .owl-theme .owl-nav .form-actions button[class*="owl-"].fc-prev-button, .form-actions .owl-theme .owl-nav button[class*="owl-"].fc-next-button, .owl-theme .owl-nav .form-actions button[class*="owl-"].fc-next-button, .form-actions .owl-theme .owl-nav button[class*="owl-"].fc-today-button, .owl-theme .owl-nav .form-actions button[class*="owl-"].fc-today-button, .form-actions button.fc-agendaDay-button.fc-state-default.fc-corner-right,
    .form-actions button.fc-month-button.fc-state-default.fc-corner-left,
    .form-actions button.fc-agendaWeek-button, .form-actions button.fc-prev-button, .form-actions button.fc-next-button, .form-actions button.fc-today-button {
        margin-bottom: 20px;
    }
}

/* Notification Panel New CSS */
.notification-panel-heading {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.notification-panel-content {
    display: flex;
    justify-content: space-between;
}

.sl-content {
    padding: 0 0 5px 0;
}

.notification-panel-content p {
    font-size: 13px;
    color: #878787;
}

span.inline-block.capitalize-font.pull-left.truncate.head-notifications {
    font-size: 13px;
    font-weight: 500;
}

li.applied-jobs-list {
    height: 165px;
    overflow: hidden;
    margin: 0;
    padding: 15px 15px 0 15px;
}

.dropdown-menu-active .dropdown-menu {
    display: block;
}

.applied-jobs-list-scroll-active {
    overflow-y: scroll !important;
    height: 220px !important;
}

li.applied-jobs-list .sl-item {
    border: 0;
}

.applied-jobs-list-scroll-active::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(56 53 53 / 10%);
    border-radius: 10px;
}

.applied-jobs-list-scroll-active::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.applied-jobs-list-scroll-active::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: lightgray;
}

/* Notification Panel New CSS */
.applied-jobs-list-active {
    height: auto !important;
}


/* Candidate Upload Button  */
button.ant-btn {
    border: 0;
    background: 0;
    color: #1890ff;
    font-size: 15px;
}

button.ant-btn:active, button.ant-btn:focus {
    background: 0;
    border: 0;
    color: #1890ff;
}

button.ant-btn:hover {
    color: #40a9ff;
    border: 0;
    background: 0;
}


button.ant-btn::after {
    transition: none;
}

[ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
    box-shadow: none;
    animation: none;
}

.ant-btn>.anticon+span, .ant-btn>span+.anticon {
    margin-left: 3px;
    font-size: 14px;
}

.user-profile-flex span {
    text-align: end;
}

@media(max-width:767px) {
    .user-profile-flex {
        display: block;
    }
}

/* Candidate Upload Button */
.react-xd-table-wrapper {
	width: 100%;
	padding: 0 0 16px;
	overflow: auto;
}
.react-xd-table-body {
	width: 100%;
	overflow: auto;
}
table.react-xd-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	text-align: left;
	display: table;
	font-size: 16px;
	font-weight: lighter;
}
.xd-table-thead {
	background: #f3f3f3;
	display: table-header-group;
}
.xd-table-row {
	display: table-row;
	width: 100%;
}
.xd-table-td td{
	display: table-cell;
	border-bottom: 1px solid #e8e8e8;
	padding: 10px 12px;
	vertical-align:middle;
	font-size: inherit;
}
.xd-table-th th {
	padding: 12px 12px;
	font-weight: bold;
	font-size: inherit;
	display: table-cell;
	vertical-align: middle;
	font-family: "Roboto",Helvetica,Arial,sans-serif;
	border-bottom: 1px solid #ddd;
}
.xd-table-row.xd-table-td:hover {
	background: #f3f3f3;
	cursor: default !important;
}
.striped-xd-table .xd-table-row.xd-table-td:nth-child(2n) {
	background: #f9f9f9;

}
.react-xd-table.center-align, .react-xd-table.center-align th {
	text-align: center;
}
/* Sortable table */
.xd-th-component-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
	cursor: default;
}
.xd-th-component-content img {
	width: 16px;
	display: inline-block;
	vertical-align: middle;
}
.xd-th-component-text {
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
	padding-right: 28px;
}
.sorting-group {
	position: absolute;
	width: 20px;
	margin-left: 6px;
	height: 20px;
	right: 0;
	top: calc(50% - 10px);
	display: flex;
	align-items: center;
	justify-content: center;
}
.xd-th-component-content i {
	font-size: 12px;
	color: #222222;
	font-weight: lighter;
	display: inline-block;
	vertical-align: middle;
}
.table-action-group .xd-table-btn {
	vertical-align: middle;
	display: inline-block;
	margin: 0 5px;
	padding: 0 8px;
	font-size: 14px;
	min-height: 28px;
	line-height: 28px;
	box-sizing: border-box;
	border: 1px solid #dddddd;
	border-radius: 3px;
	background-color: #f3f3f8;
	color: #0052CE;
	transition: 0.4s ease all;
}
.xd-table-btn.delete {
	color: #ec3c3c;
	margin: 5px;
	background-color: #f8f3f3;
}
.xd-table-btn.view {
	color: white;
	background-color: #48194e;
	cursor: pointer;
}
.xd-table-btn.edit {
	color: #EF5C22;
	background-color: #f6f4f3;
}
.xd-table-btn.delete:hover {
	color: #ffffff;
	background-color: #ec3c3c;
	border-color: #ec3c3c;
}
.xd-table-btn.view:hover {
	background:#f3eaff;
	color: #48194e;
	border-color: #f3eaff;
}
.xd-table-btn.edit:hover {
	background: #F0B04F;
	color: #fff;
	border-color: #F0B04F;
}

.sortable-table .xd-table-th th:hover {
	background: #ebebeb;
}
/*  page sizr chooser & table header and footer*/
.react-xd-tabler-header {
	margin-bottom: 16px;
	position: relative;
	width: 100%;
}
.react-xd-pagesize-chooser span {
	vertical-align: middle;
	display: inline-block;
	margin-right: 8px;
	margin-left: 12px;
}
.pagesizer-outer {
	vertical-align: middle;
	display: inline-block;
	position: relative;
}
.table-select {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
	background-color: #fff;
	border: 1px solid #ddd;
	min-width: 60px;
	min-height: 30px;
	border-radius: 3px;
	padding: 0 6px;
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: none;
	font-size: 14px;
	display: block;
	font-weight: normal;
	line-height: 30px;
	outline: none;
}
.table-select:focus {
	border-color: #0052CE;
	outline: 0;
	box-shadow: none;
	background-color: #e2e9f5;
}
.react-xd-pagesize-chooser {
	position: relative;
	display: flex;
	align-content: center;
}
.react-xd-table-footer {
	display: block;
	width: 100%;
	padding: 1rem 0;
	overflow: hidden;
}
/*  page sizr chooser & table header and footer end*/
/* pagination*/
.react-xd-pagination-container {
	height: 100%;
	align-content: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: right;
}
.pagination-numbers {
	vertical-align: middle;
	display: inline-block;
	margin-right: 16px;
	font-size: 15px;
	font-weight: lighter;
}
.pagination-btn {
	border: 1px solid #ddd;
	border-radius: 3px;
	min-width: 36px;
	display: inline-block;
	vertical-align: middle;
	font-size: 16px;
	padding: 0 5px;
	line-height: 30px;
	min-height: 30px;
	background: #ffffff;
	margin: 0 4px;
	outline: none;
	box-shadow: none;
}
.pagination-btn:hover, .pagination-btn:focus, .pagination-btn.activePageNumber {
	background: #e2e9f5;
	border-color: #0052CE;
	outline: none;
	box-shadow: none;
	color: #0052CE;
}
.pagination-btn.noNextPage, .pagination-btn.noNextPage:hover, .pagination-btn.disabled, .pagination-btn.disabled:hover {
	/* cursor: not-allowed; */
	border-color: #ddd;
	color: #999;
	background: #f5f5f5;
}
.pagination-group .pagination > li {
	display: inline-block;
	margin: 0 4px;
}
.pagination-group .pagination > li > a {
	padding: 6px 16px;
	text-decoration: none;
	border-radius: 3px;
	font-weight: lighter;
}
.pagination-group .pagination {
	display: inline-block;
	margin: 0;
}
/* responsive vertical */
/* pagination*/

@media(max-width:640px){
.react-xd-table.ver-responsive-table, .ver-responsive-table tbody, .ver-responsive-table tr, .ver-responsive-table td  {
	display: block;
	font-size: 14px;
}
.ver-responsive-table tr.xd-table-row {
	border-bottom: 1px solid #ddd;
	/* padding-bottom: ; */
	display: block;

}
.ver-responsive-table .xd-table-thead{
	display: none;
}
.ver-responsive-table .xd-table-td td::after {
	position: absolute;
	left: 0;
	content: attr(data-value);
	padding: 10px 12px;
	width: 40%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-family: "Roboto",Helvetica,Arial,sans-serif;
	font-weight: bold;
	display: block;
	top: 0;
}
.ver-responsive-table .xd-table-td td {
	position: relative;
	padding-left: calc(40% + 12px);
	display: block;
	width: 100%;
	text-transform: capitalize;
}
.react-xd-pagesize-chooser {
	text-align: center;
	display: block;
	margin-bottom: 16px;
}
.react-xd-pagination-container {
	height: auto;
	display: block;
	text-align: center;
}
}

/*eslint-disable*/
.row::after, .row::before{
    display: inline-block;
}
.verification-code input {
    max-width: 55px;
}
button[disabled], html input[disabled] {
    cursor: not-allowed;
  opacity: .5;
}
.otp-ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.otp-ul input {
    max-width: 50px;
    height: 50px;
    margin-right: 1rem;
    padding: 10px 0;
    text-align: center;
}
.css-1ns25le, .css-vj8t7z {
    border-color: hsl(214.6, 32.5%, 84.3%) !important;
    border-radius: 0px !important;
    min-height: 46px !important;
}
.form-group .css-1hwfws3 {
    padding: 6px 16px;
    font-size: 15px;
    font-weight: lighter;
    min-height: 46px;
}
.css-14uuagi {
    position: absolute !important;
}

.telephone-outer .react-tel-input {
    position: relative;
    width: 100%;
}

.telephone-outer .react-tel-input.form-ctrl {
    padding: 0px !important;
}

.telephone-outer .react-tel-input input[type="tel"] {
    position: relative;
    line-height: 25px;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 44px;
    margin-left: 0;
    background: #ffffff;
    color: black !important;
    border-radius: 0px !important;
    height: 44px !important;
    width: 100%;
    border-color: transparent !important;
    border-left-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.telephone-outer .react-tel-input input[type="tel"]:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #fff !important;
    border: 0px transparent !important;
}
.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
     outline: 0px solid transparent;
    padding: 0;
    background-color: #F5F9FF !important;
    border: 0px !important;
    border-radius: 0px !important;
}
.react-tel-input .flag-dropdown:focus .selected-flag {
    background-color: #F5F9FF;
}
.react-tel-input .selected-flag {
    z-index: 13;
    position: relative;
    width: 38px;
    height: 44px !important;
    padding: 0 0 0 8px;
    border: 0px !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0px !important;
    box-shadow: 0px !important;
    border-radius: 0px !important;
}
.react-tel-input .country-list {
    top: 46px !important;
    z-index: 9999 !important;
}
.telephone-outer {
    position: relative;
}
.telephone-outer .material-icons {
    position: absolute;
    right: 8px;
    color: #3f51b5;
    top: 0;
    line-height: 47px;
    cursor: pointer;
}
.tooltipHeight{
    line-height: 10px
}
.personal-select-without-error::after {
    top: 65% !important;
}
.personal-select-with-error::after {
    top: 50% !important;
}
.form-group.has-error .with-error {
    display: block;
    color: #d50000;
    font-size: 13px;
    padding: 5px 0 0 0;
}

.form-group .with-error {
    display: none
}
.date-error {
    display: none;
    color: #d50000;
    font-size: 13px;
    padding: 5px 0 0 0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
textarea {
    resize: none;
}
.main-container {
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
}
section {
    width: 100%;
}
.p-0 {
    padding: 0 !important;
}
.right {
    text-align: right;
}
.mar-b-3 {
    margin-bottom: 3rem;
}
.mar-b-6 {
    margin-bottom: 6rem;
}
.outer-wrapper {
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding-top: 90px;
}
/* .inner-wrapper {
    min-height: 730px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
} */
.wrapper-container{
    padding:120px 0 80px 0;
    /* min-height: 615px; */
}
.wrapper {
    margin: 0 auto;
    max-width: 850px;
    padding: 40px 24px;
}
.review-wrapper {
    padding: 0 24px 40px;
}
.error-wrapper {
    padding: 0 24px;
}
.big-heading {
    font-size: 30px;
    font-weight: lighter;
    color: #48194e;
    margin-bottom: 1rem;
}
.banner-heading {
    font-size: 4rem;
    margin: 0;
}
.subtitle {
    font-size: 2.4rem;
    font-weight: 300;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
}
.page-heading {
    font-size: 3.5rem;
    margin-bottom: 1rem;
}
.page-heading-desc {
    font-size: 12pt;
    font-weight: lighter;
}
.title_head {
    font-weight: lighter;
    font-size: 24px;
}
.section_header {
    margin-bottom: 40px;
}
.form-group {
    display: block;
    width: 100%;
    margin: 0 0 20px;
    position: relative;
}

label {
    display: block;
    margin: 0 2px 6px;
    font-weight: lighter;
    font-size: 12pt;
}

.form-group label .req {
    color: #F74734;
    margin-left: 4px;
}

.form-group .form-ctrl {
    width: 100%;
    min-height: 46px;
    border: 1px solid #cad5e4;
    border-radius: 2px;
    padding: 6px 16px;
    font-size: 12pt;
    /* font-weight: lighter; */
}
.form-group .form-ctrl::placeholder{
    color: #444;
}

.form-group .form-ctrl:focus, .form-group .form-ctrl:active {
    background: white;
    border-color: #48194e;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-group .css-lszy77t-control:hover{
    color:#48194e
}

.forgot_pw {
    color:#48194e ;
    display: block;
    margin: 5px 0px;
}

.forgot_pw:hover{
    color:#aa15ba
}
.login-div.existing-user {
    border-right: 1px solid #eee;
}
.btn {
    border-radius: 5px;
    width: auto;
    min-width: 100px;
    border: none;
    display: inline-block;
    min-height: 42px;
    line-height: 42px;
    font-size: 13pt;
    color: #fff;
    text-align: center;
    background: #48194e;
    outline: none;
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
    transition: 0.4s ease all;
    padding: 0 2rem;
}

.btn:hover, .btn:focus {
    color: #48194e;
    background: #f3eaff;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.sign-in {
    width: 100%;
    /* background-color: #48194e; */
}
.btn.signin{
    margin-left: 30px;
}
.btn.sign-in:hover,.btn.sign-in:focus{
    color: #48194e;
    background: #f3eaff;
}
.btn.icons {
    border-radius: 2px;
    width: auto;
    border: none;
    display: inline-block;
    min-height: 42px;
    line-height: 42px;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    padding: 0 0;
    margin: 0 30px;
    min-width: 0px;
}
.btn.btn-select{
    border-radius: 2px;
    width: auto;
    min-width: 77px;
    border: none;
    display: inline-block;
    min-height: 37px;
    line-height: 40px;
    font-size: 10pt;
    color: #fff;
    text-align: center;
    background: #0562e8;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    padding: 0px 0rem;
    margin-right: 5px;
}
.btn.btn-reject{
    border-radius: 2px;
    width: auto;
    min-width: 77px;
    border: none;
    display: inline-block;
    min-height: 37px;
    line-height: 40px;
    font-size: 10pt;
    color: #fff;
    text-align: center;
    background: #f44336;
    outline: none;
    cursor: pointer;
    box-shadow: none;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    padding: 0px 0rem;
}
.linkedin_btn {
    background-color: #48194e;
    width: 100%;
}

.manually_btn {
    width: 100%;
    background-color: transparent;
    border: 2px solid #48194e;
    color: #48194e;
}

.manually_btn:hover {
    background-color: #f3eaff;
    color: #48194e;
}

.or_label {
    width: 100%;
    text-align: center;
}



/* User profile css */
.profile-wrap {
    padding: 15px;
    border: 2px solid #f8f8f8;
    margin-bottom: 3rem;
    word-wrap: break-word;
}

.user-profile-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #f8f8f8;
    padding: 15px;

}

.user-profile-flex h3 {
    margin: 0;
}

.user-profile-flex i {
    font-size: 16px;
    cursor: pointer;
}

.profile-complete-status {
    border: 2px solid #eee;
    border-radius: 0px;
    display: inline-block;
    width: 100%;
    padding: 15px;
    margin-bottom: 2rem;
}

.complete-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.complete-content h2 {
    margin-bottom: .0rem;
    font-size: 22px;
    color: #48194e;
}

.incomplete-lable {
    font-size: 17px;
    margin-left: 5px;
    color: #ff3b21;

}

.one-field-status {
    display: block;
    background-color: #f9f9f9;
    padding: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 2rem 0;
    height: 85px;
}

.one-field-status h3 {
    margin: 0;
}

.sticky-div {
    position: -webkit-sticky;
    position: sticky;
    top: 85px;
}

/*  User profile css end */

/* apply with linkedin css start */
.education-section, .information-section {
    background-color: #f8f8f8;
    border: 0;
}

.select-wrapper {
    position: relative;
}

.select-wrapper select.form-ctrl {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding-top: 1.5rem;
    position: relative;
    background: #fff;
    z-index: 99;
    color: #333;    
}

.select-wrapper::after {
    position: absolute;
    content: "";
    right: 10px;
    height: 5px;
    border-right: 8px solid transparent;
    top: calc(50% - 4px);
    border-left: 8px solid transparent;
    border-top: 8px solid rgba(3, 27, 78, 0.4);
    z-index: 99;
}

.help-note {

        color: #b9b9b9;

        margin: 0px;
        font-size: 13px;
}

/* custom checkbox */
.cstm-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    top: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.cstm-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.cstm-checkbox input:checked ~ .checkmark {
    background-color: #48194e;
}
.cstm-checkbox input:checked ~ .checkmark:hover{
    border-color: #48194e;

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.cstm-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.exp-check-col {
    position: relative;
    top: 40px;
    right: 0px;
}

/* Style the checkmark/indicator */
.cstm-checkbox .checkmark:after {
    left: 9px;
    top: 4px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.select-fileupload {
    opacity: 0;
    margin-bottom: 1rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.file-upload {
	background-color: #fff;
	width: 100%;
	height: 45px;
	position: absolute;
	top: 0;
	left: 0;
	border: 1px solid #ddd;
	text-align: center;
	line-height: 45px;
	cursor: pointer;
	z-index: 99;
	outline: none;
}

.file-upload a{
    color: #48194e;
}
.file-upload a:hover{
    color: #aa15ba;
}


.file-upload span {
    color: #757575;
    font-size: 13px;
    opacity: 0.5;
    position: relative;
    top: -2px;
}

.file-upload span::before {
    content: "\1F4CE";
    font-size: 18px;
    margin-right: 5px;
    position: relative;
    top: 0px;
    vertical-align: middle;
}

.image-preview {
    font-size: 15px !important;
    color: #333;
    line-height: 3;
    font-weight: lighter;
}

.show-image .image-preview {
    padding: 10px 5px;
    border: 1px solid #eee;
    background-color: #f8f8f8;
}

.image-preview::before {
    color: #888;
    content: "\1F4CE";
    margin-right: 5px;
}

.image-preview span {
    color: #888;
    cursor: pointer;
    font-weight: bold;
    margin-left: 15px;
}

.image-preview span::before {
    content: "\D7";
}

.document {
    position: absolute;
    top: 17px;
    left: 19px;
}

/* apply with linkedin css end here */

/* apply Manually css starts here */
.do-later {
    cursor: pointer;
    color: #0562e8;
    display: inline-block;
    text-align: right;
    line-height: 45px;
    margin-top: 15px;
}

.progress-bottom-btn {
    width: 100%;
}

/* apply Manually css end here */

/* progressbar css */

.progressbar-outer {
    width: 100%;
    display: inline-block;
    padding-top: 3rem;
}

.progressbar {
    margin: 20px 0 0;
    padding: 0;
    counter-reset: step;
}

.progressbar li {
    list-style-type: none;
    width: 20%;
    float: left;
    font-size: 15px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
    z-index: 1;
}

.progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #ddd;
}

.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #ddd;
    top: 15px;
    left: -43%;
    z-index: -1;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active {
    color: #48194e;
}


.progressbar li.active::before {
    background-color: #48194e;
    color: #fff;
}

.progressbar li.active + li:after {
    background-color: #48194e;
}

/* job page css starts here */
.jobpage-banner-container {
    display: -ms-grid;
    display: grid;
    min-height: 250px;
    -ms-flex-line-pack: center;
    align-content: center;
    max-height: 900px;
    position: relative;
}

.banner-background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    background-color: #f8fafb;
}

.job-banner-image {
    background-image: url(../images/Cover.svg);
}

.hiring-banner-image {
    background-image: url(../images/hiring-banner.svg);
}

.banner-content {
    position: relative;
    z-index: 999;
    padding: 2rem 7rem;
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 100%;
}

.page-heading-outer.mar-b-6 {
    margin-bottom: 6rem;
}

.team-content-info {
    position: relative;
    -webkit-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
    transition: 0.4s ease all;
    background-color: #FCFCFC;
    -webkit-box-shadow: 0 1px 2px 0 rgba(168, 168, 168, 0.3), 0 1px 3px 1px rgba(197, 197, 197, 0.15);
    box-shadow: 0 1px 2px 0 rgba(168, 168, 168, 0.3), 0 1px 3px 1px rgba(197, 197, 197, 0.15);
    /* display: grid; */
    height: 100%;
}

.box-image {
    margin-bottom: 2rem;
    position: relative;
}

.box-image::after {
    position: absolute;
    background: rgba(52, 97, 153, 0.3);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    -webkit-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
    transition: 0.4s ease all;
}

.box-content {
    padding: 0 1rem 2rem;
}

.box-title {
    font-size: 2.2rem;
    margin: 0 0 1rem;
}

.team-content-info:hover .box-image::after {
    opacity: 1;
}

.team-content-info:hover .box-title {
    color: #0b4dad;
}

.full-link-hover {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 99;
}

/* job page css end here */

/* Job listing page css starts here */
.joblist-banner-container {
    min-height: 450px;
}

.joblist-image {
    background-image: url(../images/banner-ourteam.jpg);
}

.text-center {
    text-align: center;
}

/*.search-input{*/
/*position: relative;*/
/*padding: 11px 16px;*/
/*font-size: 12pt;*/
/*font-weight: lighter;*/
/*border: 1px solid #cad5e4;*/
/*width: 100%;*/
/*}*/
.search-input {
    position: relative;
    padding: 10px 55px 10px 20px;
    font-size: 14pt;
    font-weight: lighter;
    border-radius: 2px;
    width: 100%;
    border: 1px solid #ddd;
    /*box-shadow: 0 1px 8px 0 rgba(0,0,0,0.1);*/
}

.jobsearch-btn {
    position: absolute;
    right: 30px;
    padding: 7px;
    background: transparent;
    color: #0562e8;
    font-size: 22px;
    border: 0;
    font-weight: lighter;
    cursor: pointer;
}

.search-input:focus {
    background: #f5f9ff;
    border-color: #0b4dad;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*.jobsearch-btn{*/
/*position: absolute;*/
/*right: 15px;*/
/*min-width: 7%;*/
/*padding: 11px;*/
/*background: #0562e8;*/
/*color: white;*/
/*font-size: 17px;*/
/*border: 0;*/
/*cursor: pointer;*/
/*}*/
.job-post-item {
    margin-bottom: 3rem;
    position: relative;
    padding: 2rem;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.job-post-heading {
    font-weight: bold;
    color: #0b4dad;
    display: inline-block;
    margin: 0 0 0.5rem 0;
}

.arrow-bottom-links {
    position: absolute;
    right: 3rem;
    bottom: 2rem;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
    -webkit-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
    transition: 0.4s ease all;
}

.job-post-item:hover {
    -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .1);
}

.job-post-item:hover .job-post-heading {
    color: #0562e8;
}

/* Job listing page css end here */

/* job detail css starts here */
.btn.mar-t-2 {
    margin-top: 2rem;
}

.btn.mar-t-6 {
    margin-top: 6rem;
}

.mar-t-3 {
    margin-top: 3rem;
}

.post-detail-header {
    margin: 0 0 4rem;
}

.post-content h2 {
    font-size: 2rem;
    margin: 0 0 2rem;
}

.post-content h3 {
    margin: 0 0 1rem;
    font-size: 1.8rem;
}

.post-content p {
    font-weight: lighter;
    font-size: 1.8rem;
    margin: 0 0 2rem;
}

.post-content ul, .instructions-list ul {
    padding-left: 3rem;
    margin-bottom: 2rem;
}

.post-content ul li, .instructions-list ul li {
    font-size: 1.8rem;
    margin: 0 0 1rem;
    font-weight: lighter;
    position: relative;
    display: block;
    padding-left: 3rem;
}
.post-content ul li::before, .instructions-list ul li::before {
    position: absolute;
    left: -10px;
    top: -5px;
    content: "+";
    font-weight: 500;
    color: #e8eef6;
    font-size: 15pt;
}

.post-content ul li::after, .instructions-list ul li::after {
    position: absolute;
    left: 0;
    top: 0px;
    content: "+";
    font-weight: 500;
    color: #0562e8;
    font-size: 15pt;
}
.post-content span{
    background: #e1ebff;
    padding: 0.5rem 1.5rem;
    border-radius: 50px;
    font-size: 1.3rem;
    margin: 0 .5rem 2rem;
    display: inline-block;
}
/*  job detail css end here */

.chatbox-open, .chatbox-close {
    position: fixed;
    bottom: 30px;
    right: 50px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: #0562e8;
    color: #fff;
    cursor: pointer;
    -webkit-box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
    box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
    border-radius: 100%;
    text-align: center;
    z-index: 999;
}

.chatbox-close {
    display: none;
}

.chatbox-icon {
    width: 35px;
    vertical-align: middle;
}

.chatbox-popup {
    position: fixed;
    display: none;
    -webkit-box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
    box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
    bottom: 110px;
    right: 55px;
    width: 370px;
    height: auto;
    background-color: #fff;
    border-radius: 16px;
    z-index: 999;
}

.chatbox-popup_header {
    width: 100%;
    padding: 15px;
    color: #fff;
    background-color: #0562e8;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.chatbox-popup_header h1 {
    margin: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 16px;
}

.chat-minimize {
    color: #fff;
    cursor: pointer;
    font-size: 12px;
}

.chatbox-popup .chatbox-popup_main {
    width: 100%;
    padding: 15px;
    color: #888;
}

.chat-content {
    height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.chat-content .chatbox-message li {
    padding: 10px;
    margin: 0 0 20px 0;
    border-radius: 10px;
    background-color: #f5f5f5;
    word-wrap: break-word;
    max-width: 65%;
}

.chat-content .chatbox-message li.self {
    float: right;
    background-color: #deeafc;
}

.chatbox-popup .chatbox-popup_footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
    padding: 15px;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.chatbox-textarea {
    width: 100%;
    margin: 0;
    height: calc(16px + 16px / 2);
    padding: 0 calc(16px / 2);
    font-size: 16px;
    line-height: calc(16px + 16px / 2);
    color: #888;
    background-color: none;
    border: 0;
    outline: 0 !important;
    resize: none;
    overflow: hidden;
}

/* sidebar css start here */

.sidebar-menu-item {
    margin-bottom: 1rem;
}

.question-list li {
    display: block;
    padding: 10px;
    cursor: pointer;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 1;
    margin: 0.5rem;
    background-color: #ddd;
    font-size: 13px;
}

.sidebar-head {
    text-align: center;
    line-height: 40px;
    color: #606060;
    font-size: 16px;
    margin-bottom: 1rem;
    border-bottom: 1px solid #EEE;
}

.sec-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 5px 10px 10px;
    background-color: #eee;
}

.summary-li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
}

.summary-li span {
    width: 50%;
    display: inline-block;
}

.summary-no {
    text-align: right;
}

.question-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.sidebar-inst {
    margin-bottom: 2rem;
}

.sidebar-inst-list li {
    margin-bottom: 1rem;
    font-size: 15px;
}

.question {
    font-weight: lighter;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.question-no {
    font-size: 20px;
    margin-right: 1rem;
    font-weight: normal;
}

.legends {
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    border-radius: 100%;
}

.legends.completed {
    background-color: #3db93d;
}

.legends.review {
    background-color: #fbbc32;
}

.legends.active {
    background-color: #007be3;
}

.legends.Unanswered {
    background-color: #ddd;
}

.test-sidebar {
    position: fixed;
    height: 100%;
    width: 300px;
    overflow-x: hidden;
    padding-top: 72px;
    background-color: #f7f7f7;
    /*box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.07);*/
}

.right-test-sidebar {
    right: 0;
}

.sidebar-menu {
    padding: 10px;
}

.sidebar-list li a {
    text-decoration: none;
    display: block;
    color: #5d5d5d;
}

.question-list li:hover a, .question-list li.active a, .question-list li.completed a, .question-list li.review a {
    color: #fff;
}

.question-list li:hover, .question-list li.active {
    background-color: #007be3;
}

.question-list li.completed {
    background-color: #3db93d;
}

.question-list li.Unanswered {
    background-color: #ddd;
}

.question-list li.review {
    background-color: #fbbc32;
}

.test-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.test-header .navigation-menu.nav-right {
    margin-left: 0;
}

/* sidebar css end here */

/* material-icon css */
i.material-icons {
    position: relative;
    top: 5px;
   

}

.menu-group i.material-icons {
    font-size: 20pt;
}

.menu-group i {
    display: inline-block;
    text-align: center;
    font-size: 15pt;
    vertical-align: middle;
    min-width: 60px;
}

/* material-icon css end */

/* question paper css start */

#main-content {
    width: auto;
    background-color: #fff;
    height: 100%;
    margin-left: 300px;
    margin-right: 300px;
}

#main-content.test-page {
    margin: 0;
}

.content-wrapper {
    padding: 15px 30px;
    display: inline-block;
    margin-top: 72px;
    width: 100%;
    min-height: calc(100vh - 72px);
}

.test-paper-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.test-paper-heading h2 {
    font-weight: normal;
    margin: 0;
}

.test-paper-questions {
    margin: 2rem 0;
}
.test-question-image-ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 1rem;
}
.test-question-image-ul li{
    width: 200px;
    display: -ms-grid;
    display: grid;
    margin: 1rem 2rem;
    height: 100%;
    background-color: #eee;
}
.test-section-head{
    border-bottom: 1px solid #eee;
    font-size: 20px;
    color: #0562e8;
    font-weight: normal;
    padding-bottom: 10px;
}
.Answer-div {
    margin: 4rem 0;
}

.option-1 {
    margin-bottom: 2rem;
    width: 70%;
    cursor: pointer;
    padding: 20px;
    display: inline-block;
    border: 1px solid #eee;
}
.option-1 span{
    display: inline-block;
    width: 90%;
}
.option-1.active {
    border-color: #0562e8;
}

.option-1.active .list-number {
    background-color: #0562e8;
    border-color: #0562e8;
    color: #fff;
}
.list-number {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    margin-right: 1rem;
    border-radius: 100%;
    border: 2px solid #ddd;
    color: #aaa;
    vertical-align: top;
}

@media screen and (max-width: 1170px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }

    .sidebar.right-sidebar {
        display: none;
    }

    #main-content {
        margin-left: 0;
        margin-right: 0;
    }

    .content-wrapper {
        margin-top: 0;
    }
}

/* question paper css end */

/*  dashboad footer */
.footer-wrapper {
    padding: 15px;
    background-color: #e7e9ec;
}

/* dashboad footer end */

/* toggle sidebar */
.sidebar-toggle-box {
    width: 25px;
    position: relative;
    font-size: 0;
    cursor: pointer;
    margin-right: 15px;
    display: block;
}

.sidebar-toggle-box span {
    height: 2.5px;
    background: #212529;
    width: 100%;
    display: block;
    top: 22px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    position: absolute;
}

.sidebar-toggle-box span:nth-child(2) {
    top: 30px;
}

.sidebar-toggle-box span:nth-child(3) {
    top: 38px;
}

/* toggle sidebar end */

/* user dashboard navbar css */
.dash-heading {
    padding: 15px;
    background-color: #48194e;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.dash-heading h3 {
    color: white;
    margin: 0px;
    font-weight: lighter;
}

.dash-heading a, .dash-heading a:hover {
    color: #fff;
    font-size: 20px;
}

.dash-navbar {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.dash-navMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.dash-navMenu li {
    display: inline-block;
    background-color: #f3eaff;
    border-bottom: 2px solid #fff;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.dash-navMenu li a {
    font-size: 17px;
    padding: 15px;
    display: block;
    color: #48194e;
    /* font-weight: lighter; */
}

.dash-navMenu li:hover, .dash-navMenu li.active {
    border-color: white;
    background-color: white;
}

.dash-navMenu li:hover a, .dash-navMenu li.active a {
    color: #48194e;
}

.dash-navMenu li a i.material-icons, .dash-heading a i.material-icons {
    position: relative;
    top: 6px;
}

/* user dashboard css navbar end */

/* dashboad content css start */
.main-heading-h1 {
    margin: 5rem 0;
    display: inline-block;
}

.dash-status, .dash-side {
    padding: 0 10px;
}

.dash-side {
    margin-top: 4.5rem;
}

.current-status h2, .activity-status h2 {
    font-size: 23px;
    color:#48194e;
    margin-bottom: 1rem;
}

.progress2 {
    margin-bottom: 2rem;
    border-radius: 30px;
    background: #eee;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
    height: 10px;
    border-radius: 30px;
    width: 50%;
    background-color: #67b752;
}

.status-div {
    border: 2px solid #eee;
    border-radius: 10px;
    margin: 1rem 0 3rem;
    background-color: #f9f9f9;
}

.status-div ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.status-div ul li {
    display: inline-block;
    padding: 0 20px;
    max-width: 100%;
    margin: 1rem 0;
    text-align: center;
    color: #1e1e1e;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    font-size: 2.2rem;
    flex-grow: 1;
    border-right: 1px solid #ddd;
}

.status-div ul li p {
    margin: 0;
    color: #4d5b66;
    font-size: 18px;
}

.upcoming-status h2 {
    font-size: 23px;

}

.upcoming-status h2, .status-div.upcoming h3 {
    color: #48194e;
    margin-bottom: 0.5rem;
}

.status-div.upcoming {
    padding: 15px;
    border-color: #48194e;
    background-color: #fff;
}

.no-activity {
    background-color: #fdfdfd;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 10px;
    margin: 1rem 0 3rem;
}

.no-activity h3 {
    font-weight: normal;
    color: #48194e;
    margin-bottom: 0rem;
    margin-top: 1.5rem;
}

.no-activity p {
    margin: 0;
    /* font-weight: lighter; */
    color: #757575;
}

.no-activity img {
    width: 100px;
}

.status-div .dashboard-activity-ul {
    border-bottom: 1px solid #ddd;
}

.status-div .dashboard-activity-ul li {
    text-align: left;
    border: none;
    font-size: 2.2rem;
    color: #1e1e1e;
}

.status-div .dashboard-activity-ul li p {
  
    display: inline-block;
    font-size: 18px;
    color: #4d5b66;
}

.drive .status-div.upcoming:first-child {
    border-color: #547dc5;
}

.status-div.upcoming ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.all-test {
    border: 1px solid #eee;
    border-radius: 10px;
    margin: 2rem 0 4rem;
    background-color: #fff;
}

.all-test ul li {
    padding: 20px 15px;
    font-size: 18px;
    border-bottom: 2px solid #eee;
}
/*
.all-test ul li.ongoing {
    color: #0562e8;
} */
.react-datepicker__input-container {

    display: inline-block !important;
}
.all-test ul li:first-child {
    color: #48194e;
    text-transform: uppercase;
}

.all-test ul li:last-child {
    border-bottom: 0;
}

.all-test ul li span {
    margin-right: 1rem;
    color: #aaa;
}

.light-btn, .light-btn:hover {
    display: block;
    background-color: #f3eaff;
    padding: 10px;
    text-align: center;
    margin-top: 3rem;
    color: #48194e;
}

.note-status {
    width: 100%;
    color: #c01800;
    font-size: 17px;
    padding: 0 10px;
    text-align: center;
    margin: 5rem 0 2rem;
}

/* dashboad content css end */

/* activity page css */
.activity-page {
    margin-bottom: 60px;
    overflow-x: auto;
}

.no-job img {
    width: 150px;
}

.no-job h3 {
    font-weight: normal;
    color: #0562e8;
    margin-bottom: 0rem;
    margin-top: 1.5rem;
}

#job-activity {
    border-collapse: collapse;
    width: 100%;
     /*border: 1px solid #eee;*/
}

#job-activity td, #job-activity th {
    padding: 18px;
    border-bottom: 1px solid #eee;
    font-size: 16px;

}

#job-activity th {
    background-color: #f8f8f8;
}

.activity-list li {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    color: #757575;
    font-weight: lighter;
    overflow-wrap: break-word;
    border: 1px solid #f6f6f6;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0 6px 0 rgba(0, 0, 0, .04);
    box-shadow: 0px 0 6px 0 rgba(0, 0, 0, .04);
    margin-bottom: 3rem;
    background-color: #fff;
}

.activity-list li .activity-detail {
    color: #333;
}

.activity-list li i {
    color: #0562e8;
    width: 40px;
    text-align: center;
    top: 6px;
}

.actvity-time {
    /*float: right;*/
    color: #757575;
    padding: 0 10px;
    margin: .5rem 0;
    font-weight: lighter;
}

/* activity page css end */

/* hiring process page css start */
.hiring-wrapper-div {
    margin-bottom: 5rem;
}

.hiring-step {
    padding: 0 25px;
    background-color: #f9f9f9;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hiring-step h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    display: inline-block;
    color: #333;
}
.hiring-step span {
    font-size: 2.2rem;
    font-weight: 500;
    color: #012732;
    margin-right: 1rem;
}

.row-wrapper {
    position: relative;
    background-color: #f9f9f9;
    padding: 25px 25px 10px;
    margin-bottom: 3rem;
    border: 1px solid #eee;
}

.cross-btn {
    position: absolute;
    top: -7px;
    right: -9px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    background: #eee;
    height: 25px;
    font-size: 10px;
    border-radius: 100%;
}

.cross-btn i.material-icons {
    font-size: 18px;
}

/* hiring process page css end */

/****************************************************************** Admin CSS Start  *********************************************************************/
.admin-header .navigation-bar {
    background-color: #004860;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}

.admin-header .navbar-menu li .nav-links {
    color: #fff;
    cursor: pointer;
}

.admin-sidebar {
    background-color: #0384ae;
    -webkit-box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.admin-sidebar .sidebar-menu {
    padding: 0;
    margin-top: 2.5rem;
}

.admin-sidebar .sidebar-list li {
    /* border-left: 3px solid transparent; */
    font-size: 15px;
    padding: 15px 10px;
    margin-bottom: 0;
    -webkit-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}

.admin-sidebar .sidebar-list li a {
    color: #fff;
}

.admin-sidebar .sidebar-list li:hover {
    background-color: #004860;
}

.admin-sidebar .sidebar-list li.active {
    background-color: #004860;
    border-left: 2px solid #fff;
}

#admin-content.admin-page {
    width: auto;
    background-color: #f7f7f7;
    height: 100%;
    margin-left: 260px;
}

#admin-content .content-wrapper {
    padding: 40px 20px;
}

.heading-layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.heading-layout h3 {
    font-size: 2.5rem;
    font-weight: lighter;
}

.section-wrapper {
    -webkit-box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 2px 3px 10px 2px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    background: white;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

table * {
    position: relative;
}

table th, table td {
    padding: 10px;
    color: #1e1e1e;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
}

table td {
    text-transform: none;
    color: #1e1e1e;
    border-color: #da1313;
    border-bottom: 1px solid #eee;
}

tbody tr:hover {
    color: #555555;
    background-color: #f7f7f7;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
}

table thead th {
    border-color: #da1313;
    border-bottom: 2px solid #eee;
}
.drive-table tbody tr:hover {
    color: #555555;
    background-color: #f7f7f7;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    cursor: inherit !important;
}
.drive-table.drive-name {
    color: #0562e8;
    cursor: pointer;

}
.action-btn {
    background-color: #0562e8;
    border: 0;
    color: #fff;
    margin: 2px;
    min-width: 63px;
    border-radius: 25px;
    padding: 1px 5px;
}

.action-btn.delete {
    background-color: #ff2d2d;
}

.table-inside-btn, .table-inside-btn:hover, .table-inside-btn:focus {
    padding: 4px 15px;
    color: #48194e;
    margin: 2rem 0 0;
    display: inline-block;
    border-radius: 3px;
    font-size: 13px;
    min-width: 120px;
    text-align: center;
    border: 1px solid #48194e;
    background: #fff;
}

.column1 {
    width: 6%;
}

.column2 {
    width: 20%;
}

.column3 {
    width: 30%;
}

.column5 {
    width: 12%;
}

/******* table css end *******/

.navbar-collapse.sidebar {
    width: 49px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    white-space: nowrap;
    overflow: hidden;
}

.navbar-collapse .menu-group i {
    text-align: left;
}

.collapseFdmin-content {
    margin-left: 50px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#toggle-btn {
    position: absolute;
    left: 257px;
    top: 72px;
    background-color: #0384ae;
    border-radius: 0;
    color: #fff;
    width: 30px;
    text-align: center;
    line-height: 15px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#toggle-btn.move {
    -webkit-transform: translateX(-210px);
    -ms-transform: translateX(-210px);
    transform: translateX(-210px);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.divider {
    background-color: #ddd;
    height: 1px;
    margin-bottom: 1.5rem;
}

.divider-create-team {
    background-color: #ddd;
    height: 1px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.coming-soon-page , .coming-soon-page-google  {
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
}

.coming-soon-page h2 , .coming-soon-page-google h2{
    font-size: 60px;
    display: inline-block;
    color: #48194e;
    margin: 0;
}

.coming-soon-page p , .coming-soon-page-google p {
    padding: 0 30px;
    font-size: 22px;
    color: #757575;
}
.coming-soon-page img , .coming-soon-page-google img {
    width: 550px;

}
#profileCompletedTotal {
    width: 100px;
    height: 100px;
}

#profileCompletedPersonal, #profileCompletedEducation, #profileCompletedExperience, #profileCompletedBasic {
    height: 30px !important;
}

#profileCompletedTotal > svg, #profileCompletedPersonal > svg, #profileCompletedEducation > svg, #profileCompletedExperience > svg, #profileCompletedBasic > svg {
    width: 100% !important;
    display: block;
}

#profileCompletedPersonal > svg, #profileCompletedEducation > svg, #profileCompletedExperience > svg, #profileCompletedBasic > svg {

    width: 100% !important;
    height: 20px !important;
    display: block;
}

/*popup for editing and creating pool.....*/
.overlay {
    z-index: 99999 !important;
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    /*transition: opacity 500ms;*/
    display: none;
}

.popup {
    margin: 0px auto;
    -webkit-transform: translateY(80px);
    -ms-transform: translateY(80px);
    transform: translateY(80px);
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    max-width: 400px;
    position: relative;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.overlay:target .popup {
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
}

.popup-heading {
    border-bottom: 1px solid #eee;
}

.popup-heading h4 {
    margin-bottom: 1rem;
}

.popup .close {
    position: absolute;
    top: 10px;
    right: 15px;
    -webkit-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.popup .content {
    max-height: 30%;
    overflow: auto;
    padding: 20px 0;
    text-align: center;
    font-size: 18px;
}

.create-creating-pool .popup .content {
    text-align: left;
}
.user-score-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
    min-height: 142px;
}

.progress-status {
    width: 120px;
    background-color: #fcfcfc;
    border: 15px solid #0562e8;
    border-radius: 100%;
    height: 120px;
    text-align: center;
    font-size: 20px;
    line-height: 90px;
    vertical-align: middle;
}

/* media queries */
@media screen and (max-width: 1368px) {
    .hiring-step-img img {
        min-width: 140px;
    }

    /* .navbar-menu li .nav-links {
        font-size: 16px;
    } */

    .coming-soon-page h2 , .coming-soon-page-google h2 {
        font-size: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .main-container {
        padding: 0;
    }
    .banner-content {
        padding: 1rem 2rem;
    }

    .banner-heading {
        font-size: 3.3rem;
    }

    .page-heading {
        font-size: 3rem;
    }

    .coming-soon-page h2 , .coming-soon-page-google h2 {
        font-size: 42px;
    }

    .coming-soon-page p , .coming-soon-page-google p{
        font-size: 18px;
    }

    .page-heading-desc {
        font-size: 11pt;
    }

    .big-heading {
        font-size: 27px;
        margin-bottom: 1rem;
    }

    .progressbar li span {
        font-size: 13px;
    }

    .main-heading-h1 {
        font-size: 3rem;
        margin: 4rem 0;
    }

    .section_header {
        margin-bottom: 40px;
    }

    .hiring-step-img {
        display: none;
    }
    .hiring-wrapper-div{
        margin-bottom: 3rem;
    }
    .hiring-step {
        padding: 25px;
    }
    .current-status h2, .activity-status h2, .upcoming-status h2 {
        font-size: 21px;
    }
    .profile-nav .profile-pic{
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .activity-page {
        width: 100%;
        overflow-y: hidden;
        border: 1px solid #ddd;
    }
    .blank-label{
        display: none;
    }

    .banner-heading {
        font-size: 2.8rem;
    }

    .page-heading {
        font-size: 2.5rem;
    }

    .big-heading {
        font-size: 24px;
    }

    .main-heading-h1 {
        font-size: 2.5rem;
        margin: 3rem 0;
    }
    .post-content p, .instructions-list ul li, .post-content ul li{
        font-size: 1.6rem;
    }
    .login-div {
        border: 1px solid #ddd;
        padding: 20px;
        margin-bottom: 2rem;
    }

    .coming-soon-page h2 , .coming-soon-page-google h2 {
        font-size: 32px;
    }

    .section_header {
        margin-bottom: 20px;
    }

    .subtitle {
        font-size: 1.7rem;
    }

    .footer-container {
        padding: 15px;
    }

    .legal-activities-links, .social-media-links {
        text-align: center;
    }

    .form-group .form-ctrl {
        padding: 6px 10px;
    }
    textarea.form-control.description{
        box-sizing: border-box;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    display: block;
    padding: 8px;
    line-height: 24px;
    color: rgb(85, 85, 85);
    background-color: rgb(255, 255, 255);
    background-image: none;
    width: 100%;
    resize: none;
    font-size: 14px;
    overflow: auto;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    }

    .form-control-image{
        box-sizing: border-box;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    display: block;
    padding: 8px;
    line-height: 24px;
    color: rgb(85, 85, 85);
    background-color: rgb(255, 255, 255);
    background-image: none;
    width: 100%;
    resize: none;
    font-size: 14px;
    overflow: auto;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    }
    /*.dash-heading{*/
    /*    display: none;*/
    /*}*/
    .dash-navMenu li {
        width: 100%;
    }

    .error-wrapper {
        padding: 0;
    }

    .progressbar li span {
        display: none;
    }

    .post-content ul, .instructions-list ul {
        padding-left: 1rem;
    }

    .dash-heading span {
        display: none;
    }
    .hiring-step h2 {
        font-size: 1.6rem;
        margin-top: 1rem;
        display: inline-block;
        width: 80%;
    }

    .hiring-step p {
        font-size: 15px;
    }

    .hiring-step span {
        font-size: 2rem;
        display: inline-block;
        vertical-align: top;
        margin-top: 0.7rem;
        margin-right: 0.5rem;
    }
    .status-div .dashboard-activity-ul li{
        text-align: center;
    }
    .resend-otp {
        text-align: center;
    }

    .error-responsive, .do-later {
        text-align: center;
    }

    .mobile-profile {
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
    }
}

@media screen and (max-width: 440px) {
    .otp-ul input {
        max-width: 30px;
        min-height: 30px !important;
        max-height: 30px !important;
    }

    .popup {
        margin: 0 20px;
    }


}
.tag-input .react-tagsinput--focused{
    border-color:#ccc !important;
}
.tag-input .react-tagsinput-input{
    width: auto;
}
.tag-input .react-tagsinput-tag {
    border-radius: 2px;

     width:auto;
    display: inline-block;
    font-family: sans-serif;

    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;


    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/* use score box */
.test-panel-body .user-score-div li {
	font-size: 18px;
	text-transform: capitalize;
	margin-bottom: 1rem;
	padding: 8px 0;
}
.scoreboard .panel-heading strong {
	text-transform: uppercase;
}
.pagination-group .pagination > li.disabled > a {
	background: #f5f5f5;
	color: #999;
}
.otp-header .subtitle {
	margin: 0 0 1rem;
}
.note-msg {
	font-weight: lighter;
	color: #1e1e1e;
	display: block;
	font-size: 15px;
}
.otp-header .note-msg {
	margin: 0 0 4rem;
}

.btn.add-btn{
    background-color: white;
    color: #48194e;
}

.btn.add-btn:hover{
    color: #aa15ba;
    
}
.btn.add-btn:focus{
    color:#48194e;
}

label.cstm-checkbox:hover .checkmark, label.cstm-checkbox input:focus ~ .checkmark {
    border: 1px solid #48194e;
}

.form-group.collegeFormGroup .xenon__control{
    z-index: 10
}

.form-group.collegeFormGroup .xenon__menu{
    z-index: 10000
}

hr.expSep{
    border-color: #d6d6d6;
}
span.ant-checkbox-inner{
    border-color: #48194e;
}

select#select option{
    background-color: white;
}


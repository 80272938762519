

.react-datepicker {
	box-shadow: 0 1px 6px 0 rgba(0,0,0,.2);
	border: 1px solid #bccae3;
	border-radius: 4px;
	font-family: "Roboto",Helvetica,Arial,sans-serif;
  transition: 0.3s ease all;
}
.react-datepicker__day, .react-datepicker__month-text {
	cursor: pointer;
	width: 36px;
	height: 32px;
	line-height: 32px;
	font-size: 14px;
	font-weight: lighter;
	background: #ffffff;
	margin: 1px;
	color: #454545;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover {
	border-radius: 2px;
	background-color: #E8ECF8;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
	border-radius: 2px;
	background-color: #0562E8;
	color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
	background-color: #095FC6;
}
.react-datepicker__day-name {
	color: #757575;
	display: inline-block;
	width: 36px;
	line-height: 28px;
	text-align: center;
	margin: 1px;
	font-size: 13px;
	font-weight: 600;
	height: 28px;
}
.react-datepicker__month {
	margin: 6px;
	text-align: center;
}
.react-datepicker__header {
	text-align: center;
	background-color: #f5f9ff;
	border-bottom: none;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 8px 0 0px;
	position: relative;
	box-shadow: 0px 1px 3px 0 rgba(139, 158, 206, 0.69);
}
.react-datepicker__current-month {
	margin-top: 0;
	color: #333333;
	font-weight: bold;
	font-size: 18px;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: #bccae3;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
	border-bottom-color: #F5F9FF;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
	border-top-color: #bccae3;
}
.react-datepicker__navigation {
	background: transparent;
	line-height: 30px;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: 8px;
	padding: 0;
	border: none;
	z-index: 1;
	height: 30px;
	width: 30px;
	text-indent: 0;
	font-size: 0;
	border-radius: 100%;
}
.react-datepicker__navigation::after {
	left: 0;
	font-family: "simple-line-icons", sans-serif;
	right: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 999;
	font-size: 12px;
	color: #666;
}
.react-datepicker__navigation--previous::after {
	content: "\e605";
}
.react-datepicker__navigation--next::after{
  content: "\e606";
}
.react-datepicker__navigation:hover::after {
	color: #0562E8;
}
.react-datepicker__navigation:hover {
	background: #D4E2F5;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
	background-color: #f0f0f0;
	position: absolute;
	width: 50%;
	left: 25%;
	top: 30px;
	z-index: 1;
	text-align: center;
	border-radius: 0.3rem;
	border: 1px solid #bccae3;
}
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
	line-height: 20px;
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 4px 3px;
	font-size: 13px;
	background: #ffffff;
	border-bottom: 1px solid #e7eaf0;
}
.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
	background-color: #F0F4FB;
	color: #0562E8;
}
.react-datepicker__year-option.react-datepicker__year-option--selected_year {
	color: #0562E8;
	background: #e6f0ff;
}
.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
	max-height: 350px;
	overflow-y: scroll;
	min-width: auto;
	height: auto;
}
.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
	border: 1px solid #a3b5d1;
	border-radius: 3px;
	min-width: 120px;
	padding: 3px 8px;
	position: relative;
	font-size: 13px;
	visibility: visible !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
	border-top-color: #bcc8e4;
	float: right;
	margin-left: 10px;
	top: 7px;
	position: relative;
	border-width: 0.45rem;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
	margin: 8px 0 5px;
}
.react-datepicker__input-time-container {
	clear: both;
	width: 100%;
	float: left;
	padding: 8px 10px;
	text-align: center;
	margin: 0;
	border-top: 1px solid #bccae3;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
	display: inline-block;
	width: 30%;
	vertical-align: middle;
	font-size: 15px;
	font-weight: bold;
	color: #454545;
	text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
	display: inline-block;
	width: 70%;
	vertical-align: middle;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
	display: inline-block;
	padding-left: 10px;
	width: 100%;
	margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
	width: 100%;
	box-sizing: border-box;
	padding: 0 8px;
	min-width: 36px;
	line-height: 36px;
	display: block;
	border: 1px solid #ddd;
	border-radius: 3px;
	font-size: 16px;
	color: #445454;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input:focus {
	border-color: #0052CE;
	background-color: #F5F9FF;
	outline: none;
	box-shadow: none;
}
.react-datepicker__day--disabled{
    cursor: default;
    color: #ccc;
}
.react-datepicker__navigation--next:after {
    content: "\e606";
}
.react-datepicker-popper {
	z-index: 9999;
}

.wekanBoardContainer {
    background-color: #f3f3f3 !important;
    min-height: 100% !important;
     
}
.board-height .wekanBoardContainer.jrGeNN{

    height:calc(100vh - 185px) !important;
    min-height: calc(100vh - 185px) !important;
    background-color: transparent!important;
}
.board-height .sc-EHOje.cqkFMI{
    height:auto;
    overflow:auto;
    overflow-x: visible !important;
    max-height: 100vh !important;

}
.board-height .jrGeNN{
    overflow-y:auto !important;

}
.board-height section{
    width:auto;
    margin: 5px 10px;
    background-color: #fff !important;
    max-height: calc(100vh - 215px);
    box-shadow: 0 0 0 0 rgba(90,113,208,.11),0 2px 8px 0 rgba(167,175,183,.33);
}
.board-height .cuFeqX{
    margin-top: 5px;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: start !important;
    border-top: none !important;

}
.board-height .eoGtce{
    border-bottom: none !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}
.board-height .comPlainTextContentEditable{
    padding: 6px 12px !important;
    background: white !important;
    border-radius: 2px !important;
    border : 2px solid #ccc important;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s !important;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s !important;
}
.board-height .sc-cvbbAY.cZqlgI{
    background: #0562e8 !important;
    border-radius: 2px !important;
    width: auto !important;
    min-width: 100px !important;
    border: none !important;
    display: inline-block !important;
    min-height: 42px !important;
    line-height: 42px !important;
    font-size: 13pt !important;
    color: #fff !important;
    text-align: center !important;
    outline: none !important;
    cursor: pointer !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: 0.4s ease all !important;
    -o-transition: 0.4s ease all !important;
    transition: 0.4s ease all !important;
    padding: 0 2rem !important;
}
.board-height .hKmTfD {
     
    border-radius: 2px !important;
    width: auto !important;
    min-width: 100px !important;
    border: none !important;
    display: inline-block !important;
    min-height: 42px !important;
    line-height: 42px !important;
    font-size: 13pt !important;
    color: #fff !important;
    text-align: center !important;
    outline: none !important;
    cursor: pointer !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: 0.4s ease all !important;
    -o-transition: 0.4s ease all !important;
    transition: 0.4s ease all !important;
    padding: 0 2rem !important;
}
.board-height .smooth-dnd-container {
    min-height: calc(100vh - 305px) !important;
}
.board-popup{
    height: calc(100vh - 210px) !important;
    overflow: auto;
    margin: auto auto !important;
    -webkit-transform: translateY(80px);
    transform: translateY(135px);
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    max-width: 450px;    
    transition: all .5s ease-in-out;
}
.board-popup .board-popup-heading{
    margin: -15px -15px 16px -15px;
    background: #ededed;
    padding: 7px 16px;
    border-bottom: 1px solid #dbdbdb;
}
.board-popup .board-close
{
    position: absolute;
    top: 10px;
    right: 15px;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
.wekancomment-section{
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.board-close{
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    cursor: pointer;
}
.CustomCardStyle{
    position: absolute;
}
.inner-cardheader p{
    padding: 0px 5px;
    display: flex;
    font-size: 13px;
    justify-content: flex-start;
    flex-flow: row wrap;
    background-color: #f3eaff;
    margin: 5px 0;
    width: 95px;
}
.wekanBoardContainer .eoPYBZ{
    background-color: #f8f8f8;
}
button.sc-gipzik.epzHhr{
    background-color: #48194e;
}

button.sc-iRbamj.bnWttt{
    background-color:#48194e ;
}

button.sc-jlyJG.iHJMOK{
    background-color:#48194e ;
}

textarea.sc-hMqMXs.cySimG:focus{
    box-shadow:inset 0 0 0 2px #48194e;
}
.sc-htpNat.sc-bxivhb.lnaEuj a{
    color: #48194e;
}
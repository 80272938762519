@import "./common.css";

/* Banner section */
.banner-background-outer.home-banner-background{
    background-image: url(https://www.xenonstack.com/images/career/xenonstack-careers-home-banner.svg);
}
.home-banner-container.banner-container {
    max-height: 1000px;
    min-height: 600px;
}
.banner-post{
    box-shadow: none;
    position: absolute; 
    transform-origin: bottom left;
    transition: transform 0.5s ease;
    border: 1px solid #0562e8;
    max-width: 100%;
    width: 400px;
}
.banner-post.post-3{
    transform: rotate(2deg) translate(6.5rem, 0);
}
.banner-post.post-2{
    right: 0;
    transform: rotate(-5deg) translate(-6rem, 1.5rem);
}
.banner-post.post-1{
    right: 0;
    transform: rotate(-1deg) translate(-6.4rem, 0.3rem);
}
/* Why XenonStack css */
.why-xenonstack-wrapper{
    max-width: 1100px;
    margin: 0 auto;
}
.xenonstack-points-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
}
.box-item{
    display: flex;
    background-color: #64A3FF12;
    margin: 15px;
    max-width: 300px;
    padding: 25px 15px;
    width: 100%;
    min-height: 230px;
    align-items: center;
    justify-content: center;
}
.box-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-item-content figure{
    margin: 0 1rem;
}
.box-item-content figure img{
    max-width: 8rem;
width: 50px;
}
.box-title{
    font-size: 2.5rem;
    font-weight: lighter;
    margin: 0 1rem;
    color: #0b4dad;
}
.light-text{
    font-size: 2rem;
    font-weight: lighter;
    line-height: 1.7;
    margin-bottom: 2rem;
    color: #757575;
    border-left: 1px solid #eee;
    padding-left: 40px;
}
/* Get started/pillars css */
.pillars-list-boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 9rem;
}
.boxes-item {
    width: 33.33%;
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 100%;
    padding: 0 15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.pillars-list-item {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 30px 5px #eee;
    padding: 2rem;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    -webkit-transition: background 0.3s ease all;
    transition: background 0.3s ease all;
}
.pillars-list-item figure img {
    width: 100px;
    background: #fff;
    padding: 10px;
}
.pillars-list-item h3 {
    margin: 1rem 0;
    font-size: 1.8rem;
}
.pillars-list-item p {
    font-size: 11pt;
    font-weight: lighter;
    margin: 0 0 2rem;
}
/* Slider */
.home-slider-container{
    max-width: 1100px;
    margin: 0 auto;
}
.home-slider-container .wb-banner-heading{
    line-height: 1.4;
}
.home-slider-container .wb-banner-heading span{
    display: inline-block;
    margin-bottom: 2rem;
}
.slick-slide img {
    margin: auto;
}
.home-slider-container .slick-prev, .home-slider-container .slick-next{
    width: 50px;
    height: auto;
    top: calc(100% - 220px);
}
.home-slider-container .slick-prev{
    left: 0;
    z-index: 99;
} 
.home-slider-container .slick-next{
    left: 60px;
}
.home-slider-container .slick-prev::before, .home-slider-container .slick-next::before {
    font-family: 'FontAwesome';
    font-size: 4.5rem;
    line-height: 1;
    opacity: .9;
    color: #7f7f7f;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.home-slider-container .slick-prev::before{
    content: "\f104";
}
.home-slider-container .slick-next::before{
    content: "\f105";
}
.home-slider-container .slick-dots {
    position: unset;
    margin-top: 30px ;
    display: flex!important;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
}
.home-slider-container .slick-dots li{
    width: 130px;
    height: auto;
    text-align: center;
}
.home-slider-container .slick-dots li a{
    color: #dfdfdfed;
    font-weight: bold;
    font-size: 40px;
}
.slick-dots li.slick-active a{
    color: #84b1f2;
}
.home-slider-container .slick-dots li button::before{
    content: unset;
}
.home-slider-container .slick-slide img {
    display: block;
    width: 45rem;
}
/* Hire process three boxes */
.three-boxes-item.hire-process{
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    margin: 0 auto;
    max-width: 270px;
    padding: 30px;
    transition: box-shadow .4s, transform .4s;
}
.three-boxes-item.hire-process:hover{
    box-shadow: 0 30px 40px rgba(0, 0, 0, .4);
    cursor: pointer;
    transform: translateY(-10px);
}
.three-boxes-item.hire-process figure img{
    width: 100%;
    max-width: 20rem;
    max-height: 15rem;
}
.hire-process-item-content{
    padding: 1rem 0 2rem;
}
/* Responsive css */

@media screen and (max-width:1024px){
    .banner-background-outer.home-banner-background{
        background-image: url(https://www.xenonstack.com/images/career/mobile-screen-common-banner.svg);
    }
    .home-banner-container.banner-container{
        min-height: 300px;
        height: auto;
    }
    .banner-image-box-item .banner-post{
        display: none;
    }
    .pillars-list-boxes {
        margin-left: 0;
    }
    .home-slider-container .slick-dots li {
        width: 100px;
    }
}
@media screen and (max-width:640px) {
    
    .pillars-list-boxes .boxes-item {
        width: 100%;
        height: auto;
        display: block;
    }
    .home-slider-container .slick-dots li {
        width: 48px;
    }
    .home-slider-container .slick-dots li a {
        font-size: 25px;
    }
    .three-boxes-item.hire-process{
        max-width: 450px;
    }
}


.login-div.existing-user {
    border: 0;
}

.wrapper-container.signing-container, .wrapper-container.verify-email-container, .wrapper-container.verification-container, .wrapper-container.signing-container.personal-information, .wrapper-container.signing-container.education, .wrapper-container.signing-container.experience, .wrapper-container.signing-container.basic-information, .wrapper-container.signing-container.create-password {
    position: relative;
    padding: 8.8rem 0;
    height: 100vh;
    display: flex;
    align-items: center;
}

.wrapper-container.verify-email-container::after {
    background-image: url('../images/verify-your-email.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container::after {
    background-image: url('../images/sign-in.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.sign-up::after {
    background-image: url('../images/sign-up.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.verification-container::after {
    background-image: url('../images/email-verification.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 55%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.personal-information::after {
    background-image: url('../images/personal-information.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.education::after {
    background-image: url('../images/education.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;       
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.experience::after {
    background-image: url('../images/experience.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.basic-information::after {
    background-image: url('../images/basic-information.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.wrapper-container.signing-container.create-password::after {
    background-image: url('../images/create-password.svg');
    content: "";
    position: absolute;
    top: 0;
    left: 70%;
    right: 0;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: bottom;
}

.user-journey-form.user-journey-form-internal-steps {
    width: 60%;
    overflow: hidden;
    overflow-y: scroll;
}

form#loginForm .form-group label {
    color: #1e1e1e;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

a.forgot_pw {
    color: #4D5B66;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding-top: 1rem;
    margin: 0;
}

a.dont-have-an-account {
    color: #4D5B66;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding-top: 10px;
}

a.dont-have-an-account:hover {
    color: #aa15ba;
}

.form-group .form-ctrl::placeholder {
    color: #8792A1;
}

.form-group {
    display: block;
    width: 100%;
    padding: 1rem 0;
    position: relative;
    margin: 0;
}

.btn.mar-t-2 {
    margin-top: 1.5rem;
    border-radius: 5px;
}

.subtitle {
    width: 55%;
    color: #8792A1;
    font-weight: 400;
    font-size: 2.2rem;
    font-family: 'Roboto', sans-serif;
}

.subtitle.big-subtitle {
    width: 80%;
    color: #4D5B66;
    padding-block-start: 1.2rem;
}

.form-group label {
    color: #1e1e1e;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.big-heading {
    font-size: 4.5rem;
    color: #48194e;
    margin-bottom: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.user-journey-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 50%;
}

.container.user-jounrney-form-container {
    height: 100%;
}
.user-journey-form .wrapper {
    padding: 40px 0;
}
.xenonstack-jobs-copyright {
    display: flex;
    align-items: baseline;
    gap: 10px;
    padding-top: 3rem;
}

.xenonstack-jobs-copyright i {
    font-size: 2rem;
    color: #4d5b66;
}

.xenonstack-jobs-copyright p {
    font-size: 2rem;
}

.col-12.col-sm-12.col-md-12.right {
    display: flex;
}

.col-12.col-sm-12.col-md-12.right button {
    margin: 0;
    padding: 0;
    width: 20rem;
}

.col-12.col-sm-12.col-md-12.right .btn-group {
    width: 100%;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 1.2rem;
}

button.btn.signin.mar-t-2.btn-2 {
    background: #fff;
    border: 1px solid #48194e;
    color: #48194e;
}

.form-group .form-ctrl {
    border: 1px solid #D5DCE3;
    border-radius: 5px;

}

::-webkit-input-placeholder {
    /* Edge */
    color: #8792A1 !important
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8792A1 !important
}

::placeholder {
    color: #8792A1 !important
}

.form-group input::placeholder {
    color: #4d5b66 !important;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.form-group input {
    opacity: 1;
    color: #4d5b66 !important;
    background: #fff;
}

.telephone-outer .react-tel-input input[type="tel"], .select-wrapper select.form-ctrl, .css-151xaom-placeholder {
    color: #4d5b66 !important;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.row.add-your-social-links-row {
    padding-top: 3rem;
}

.user-journey-form.user-journey-form-internal-steps span::after {
    width: 9px;
    left: 12px;
    height: 14px;
    top: 6px;
}

.css-1o9y6at-multiValue {
    border: 1px solid #48194e;
    background: #f3eaff;
    border-radius: 5px !important;
    color: #48194e;
}

.css-1pcexqc-container {
    border: 1px solid #D5DCE3;
    border-radius: 5px
}

.css-aesaq-control {
    border: 0;
}

.css-aesaq-control.xenon__control {
    border: 0;
    background: 0;
    border-color: transparent;
}

.form-group .css-1hwfws3 {
    font-weight: normal !important;
    font-size: 1.9rem;
    font-family: 'Roboto', sans-serif;
}

.user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right {
    display: flex;
    max-width: 50%;
}

.user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right .btn-group, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right .btn-group {
    width: 100%;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 0;
    justify-content: flex-end;
}

.user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2, .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2 {
    min-width: 20rem;
    margin: 0;
    padding: 7px 14px;
}

form.experience-form .form-group label {
    color: #8792A1;
}

.col-12.col-sm-12.col-md-12.add-your-projects {
    padding-top: 10px;
}
label.label-for-experience-fresher {
    color: #1e1e1e !important;
}

h4.page-heading-desc.experience-paragraph {
    font-weight: normal;
    font-size: 2.2rem;
    color: #4d5b66;
    padding-bottom: 15px;
}

.cstm-checkbox {
    display: flex;
    align-items: center;
    margin: 0;
}

.file-upload span {
    opacity: 1;
}

.file-upload {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle.area-of-interest {
    width: 100%;
}

.subtitle.add-your-social-links {
    width: 100%;
}

.checkmark {
    height: 30px;
    width: 30px;
}

.help-note {
    color: #4d5b66;
    padding-top: 10px;
}

.cstm-checkbox .checkmark:after {
    left: 12px;
}

.image-preview {
    font-weight: normal;
    color: #1e1e1e;
}

.user-journey-form::-webkit-scrollbar {
    display: none;
}
form.education-form .form-group {
    padding: 1.3rem 0;
}

.css-16pqwjk-indicatorContainer {
    color: #1e1e1e70 !important;
}

.select-wrapper::after {
    border: 0;
}

.progress-bottom-btn {
    width: 100%;
    padding-top: 2rem;
}

input#fresher {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid black
}

input#exp {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid black
}

label.label-for-experience-fresher::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #48194e;
    border-color: azure;
}

input#fresher:checked, input#exp:checked {
    background: #48194e;
}

.form-group.select-wrapper.personal-select-without-error select {
    background: transparent;
    background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 13px;
    padding-right: 2rem;
    background-size: 20px;
}

.form-group.select-wrapper.personal-select-without-error .education_select0 {
    background: transparent;
    background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 13px;
    padding-right: 2rem;
    background-size: 20px;
}

.cross-btn {
    top: 5px;
    right: 5px;
}

.row-wrapper {
    background-color: #fff;
    border: 1px solid #1e1e1e40;
}

.col-12.col-sm-6.col-md-6.resume {
    display: flex;
    align-items: center;
}

.col-12.col-sm-6.col-md-6.resume label {
    margin: 0;
}

form.assign-user-form.create-college-csv-form .form-group button {
    margin-top: 10px;
}

.dashbaord-user-form {
    padding-top: 15px;
}

form.assign-user-form.create-college-csv-form .csv-upload-box {
    top: 0;
}
.help-note span small{
    color: #878787 !important;
    font-weight: 400 !important;
    font-size: 1.8rem !important;
    font-family: 'Roboto', sans-serif;
}

@media(max-width:767px) {
    .wrapper-container.signing-container::after, .wrapper-container.verify-email-container::after, .wrapper-container.verification-container::after {
        display: none;
    }

    .wrapper-container.signing-container .login-div {
        padding: 0;
        border: 0;
        margin: 0;
    }

    .signing-container .subtitle {
        width: 100%;
    }

    .wrapper-container.signing-container {
        align-items: flex-start;
    }

    .signing-container .btn-group {
        display: flex;
        justify-content: space-around;
    }

    .xenonstack-jobs-copyright {
        display: flex;
        align-items: baseline;
        gap: 3px;
    }

    .user-journey-form, .user-journey-form.user-journey-form-internal-steps {
        width: 100% !important;
    }

    .resend-otp {
        text-align: start;
    }

    .error-responsive, .do-later {
        text-align: start;
        padding-top: 1rem;
    }

    .otp-header .subtitle {
        margin: 0;
        width: 100%;
    }

    .user-journey-form.user-journey-form-internal-steps .btn-group {
        justify-content: flex-start;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right .btn-group, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right .btn-group {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right {
        padding-top: 10px;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2:nth-child(2), .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2:nth-child(2) {
        margin-top: 10px;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2, .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2 {
        padding: 5px 10px;
    }

    .subtitle {
        width: 100%;
    }

    section#bottom-btn button {
        margin-bottom: 2rem;
    }
    .wrapper-container.verification-container .container.user-jounrney-form-container .user-journey-form .btn-group {
        width: 100%;
        display: flex;
        gap: 3rem;
        margin: 2rem 0;
    }
    
    .wrapper-container.verification-container .container.user-jounrney-form-container .user-journey-form .btn-group button {
        margin: 0;
    }
}

@media(max-width:1023px) {
    .signing-container .big-heading {
        font-size: 3rem;
    }
}

@media(min-width:768px) and (max-width:1023px) {
    .subtitle {
        width: 80%;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form button.btn.mar-t-2, .user-journey-form.user-journey-form-internal-steps form.experience-form button.btn.mar-t-2 {
        width: 20rem;
        min-width: auto;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right {
        display: flex;
        max-width: 58%;
    }
    .col-12.col-sm-12.col-md-12.right .btn-group {
        width: 100%;
        display: flex;
        padding-top: 1.2rem;
        justify-content: space-between;
    }
    .wrapper-container.verification-container .container.user-jounrney-form-container .user-journey-form .btn-group {
        width: 100%;
        display: flex;
        gap: 3rem;
        margin: 2rem 0;
    }
    
    .wrapper-container.verification-container .container.user-jounrney-form-container .user-journey-form .btn-group button {
        margin: 0;
    }

}

@media(min-width:768px) and (max-width:1439px) {
    .section_header {
        margin: 0;
    }

    .user-journey-form.user-journey-form-internal-steps form.education-form .col-12.col-sm-12.col-md-12.right .btn-group, .user-journey-form.user-journey-form-internal-steps form.experience-form .col-12.col-sm-12.col-md-12.right .btn-group {
        gap: 15px;
    }
}

.footer-top-section{
    background-color: #F8F9FB;
    padding: 3rem 0 1rem;
    border-bottom: 1px solid #ddd;
}
.footer-logo img {
    height: 6rem;
}
.footer-logo-content p{
    padding: 15px 20px 0 0;
    font-size: 12pt;
    font-weight: lighter;
}
.footer-logo h3{
    font-size: 2.2rem;
    font-weight: lighter;
    margin: 0;
    padding: 0 0 0px 20px;
}
.footer-navigation-title {
    font-size: 2.1rem;
    font-weight: 500;
    margin: 15px 0;
    display: block;
    position: relative;
}
ul.footer-links li {
    position: relative;
    display: block;
    padding-left: 0;
    margin: 0 0 1rem;
    font-weight: lighter;
    font-size: 1.7rem;
}
ul.footer-links li a{
    color: #222;
}
ul.footer-links li a:hover{
    color: #0562e8;
}
@media screen and (max-width:1024px){
    .footer-logo-content{
        margin-bottom: 2rem;
    }
    .footer-logo-content h3{
        font-size: 2.5rem;
        padding: 0;
    }
}
@media screen and (max-width:640px){
    .footer-logo-content h3{
        font-size: 2.1rem;
    }
}
section.footer-upper-section {
    display: none!important;
}

@media(min-width:768px)
{
    section.footer-upper-section {
        display: block!important;
    }
}
.new-footer .checkout-button a {
    color: #fff;
}
.new-footer.quiz-heading {
    margin-top: 6%;
  
    letter-spacing: 0em;
    text-align: left;
    color: #1e1e1e;
  }
  .new-footer.quiz-para {
    color: #4d5b66;
    width: 45%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .new-footer.btn-quiz {
    left: 142px;
    top: 303px;
    border-radius: 0px;
    padding: 4px 17px 2px 18px;
    background-color: #48194e;
    color: white;
    font-size: 2.4rem;
    border: none;
    margin-top: 23px;
  }
/* Footer */

footer.custom-footer p {
  color: #333;
  font-size: 14px;
  padding: 5px 15px;
  margin: 0;
  text-align: right;
  line-height: 40px;
  background-color: #fafafa;
}

.admin-form-group {
  margin-bottom: 10px;
}

/* footer end */
/* Header */
.custom-header .nav.top-nav>li {
  margin: 0 5px;
}

.navbar.navbar-inverse.navbar-fixed-top .nav.top-nav>li>a .top-nav-icon-badge {
  background: #ef4432;
}

.custom-header .nav-header .logo-wrap {
  height: 100% !important;
}

.custom-header .nav-header .logo-wrap a {
  line-height: 60px;
}

.custom-header .nav-header .logo-wrap .brand-img {
  max-width: 100%;
  /* width: 35px; */
}

.custom-header .nav.top-nav>li.alert-drp .dropdown-menu.alert-dropdown .sl-item .sl-content {
  margin-left: 0 !important;
}

/*  Header end*/
.fix-height {
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
}

/*  Sidebar */

.custom-sidebar-left.fixed-sidebar-left {
  border: 0 !important;
}

.custom-sidebar-left.fixed-sidebar-left .side-nav li.navigation-header>span {
  color: #48194e !important;
}

.custom-sidebar-left.fixed-sidebar-left .side-nav>li>a {
  padding: 15px !important;
  font-size: 16px;
}

.fixed-sidebar-left .side-nav {
  background-color: #021056;
  width: auto;
}
.fixed-sidebar-left .side-nav>li>ul {
  background-color: #48194e;
}

.fixed-sidebar-left .side-nav>li>ul>li a {
  color: #fff !important;
}

.fixed-sidebar-left .side-nav li a .pull-right i {
  color: #48194e !important;
}

.custom-sidebar-left.fixed-sidebar-left .side-nav li a i {
  width: 25px !important;
}

li.career-portal-drawer-sidebar-dropdown-title a[aria-expanded="true"] {
  background: #48194e !important;
  color: #fff !important;
}

li.career-portal-drawer-sidebar-dropdown-title a[aria-expanded="true"] pull-right {
  color: #fff !important;
}

li.career-portal-drawer-sidebar-dropdown-title a[aria-expanded="true"] .pull-right i {
  color: #fff !important;
}

li.career-portal-drawer-sidebar-dropdown-title a[aria-expanded="true"]:hover {
  color: #fff !important;
}

/* Sidebar end */

/*  Content start */
.control-label {
  color: #212121;
  text-transform: capitalize;
}

.pass {
  color: green;
}

.fail {
  color: red;
}

body {
  overflow-x: hidden;
}

p {
  font-size: 17px;
}

label {
  min-height: 26px;
}

.radio label {
  display: block;
}

.page-wrapper {
  padding: 70px 0 0;
  min-height: calc(100vh - 50px);
}

.custom-content .heading-bg {
  margin: 0 !important;
  height: auto !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom-content .heading-bg h4 {
  margin: 0;
  font-size: 2rem;
}

.custom-content .heading-bg h4 a {
  color: #2a3e4c;
}

.custom-content .heading-bg h4 a:hover {
  color: #aa15ba;
}

.dashboard-card {
  padding: 15px;
  background-color: #fff;
  margin-bottom: 3rem;
  -webkit-box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 2px 8px 0 rgba(167, 175, 183, 0.33);
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 2px 8px 0 rgba(167, 175, 183, 0.33);
}

.custom-panel.card-view.panel.panel-default>.panel-heading {
  background: #48194e !important;
}

.custom-panel.card-view.panel>.panel-heading>.panel-title {
  color: #fff;
  font-size: 17px;
}

.custom-panel.card-view {
  margin: 0;
  /*padding: 15px;*/
}

/*  Content end */
.feedback-section {
  position: relative;
  margin-bottom: 3rem;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.feedback-section:hover {
  border: 1px solid #48194e;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.feedback-section:hover .feedback-dlt {
  visibility: visible;
  color: #0562e8;
  -webkit-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.heading-div {
  /*display: flex;*/
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.heading-div .head {
  font-size: 19px;
  margin: 0;
  margin-right: 5px;
  display: inline-block;
  color: #1e1e1e;
}

.date-text {
  color: #757575;
  font-size: 13px;
}

.heading-div span {
  color: #aaa;
  font-weight: lighter;
  font-size: 16px;
}

.feedback-detail {
  margin-top: 1.5rem;
}

.feedback-dlt {
  color: #0562e8;
  visibility: hidden;
  position: absolute;
  right: 15px;
  top: 5px;
}

/* Feedback end */

/* Pool css start */
.panel.custom-panel.pool-panel {
  margin-bottom: 2rem;
}

.panel.custom-panel.pool-panel .panel-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 10px 15px;
  font-size: 18px;
  align-items: center;
  color: #fff;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.view-pool {
  float: right;
  color: #48194e;
}

.view-pool:hover {
  color: #aa15ba;
}

.pool-form-wrapper {
  background-color: #fff;
  padding: 20px;
  min-height: calc(100vh - 185px);
  -webkit-box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 2px 8px 0 rgba(167, 175, 183, 0.33);
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 2px 8px 0 rgba(167, 175, 183, 0.33);
}

.form-upload-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}

.form-btn-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}

.form-heading h4,
h4.content-heading {
  color: #48194e;
  margin-bottom: 1rem;
  font-size: 21px;
}

.form-heading h4,
h4.content-heading2 {
  color: #48194e;
  margin-bottom: 1rem;
  font-size: 17px;
}

.pool-form-wrapper .dropzone-panel .panel-body {
  padding: 0px;
}

/* Pool css end */

/* create test css start */
.add-div {
  position: relative;
  width: 100%;
  top: 31px;
  height: 40px;
  font-size: 13px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  background: #48194e;
  color: #fff;
}

.test-panel-body li {
  font-size: 17px;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.test-panel-body li span {
  color: #333;
  margin-left: 10px;
}

.bottom-body {
  border-top: 1px solid #ddd;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .add-div {
    top: 0;
  }
}

/* create test css end */

/* Candidate listing */
.custom-action {
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 10px;
}

/* Candidate listing end */

/* Candidate detail */

.divider {
  background-color: #eee;
  height: 1px;
  width: 100%;
  margin-bottom: 2rem;
}

.profile-sec-head {
  font-size: 21px;
  margin-bottom: 1.5rem;
  background-color: #f9f9f9;
  padding: 5px 10px;
  border: 1px solid #eee;
}

.candidate-img-div {
  border: 1px solid #ddd;
  background-color: #eee;
}

.candidate-img-div img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.resume-link,
.resume-link:hover {
  color: #0562e8;
}

/* Candidate detail end */

/*********  add question page  ***********/
.question-upload-text {
  font-size: 15px;
  text-decoration: underline;
  color: #003a8d;
  text-align: right;
  cursor: pointer;
}

.csv-upload-container {
  cursor: pointer;
  padding: 10px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  border: 1px solid #48194e;
  color: #48194e;

}

.csv-upload-input {
  margin: 0 auto;
  width: 100%;
  opacity: 0!important;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.csv-upload-box {
  position: absolute;
  left: 35%;
  top: 15%;
  cursor: pointer;
}

.csv-upload-box i {
  font-size: 30px;
  color: #48194e;
}

.csv-upload-box span {
  position: absolute;
  cursor: pointer;
  top: 18%;
  color: #1e1e1e;
  width: 200px;
  font-size: 17px;
}

.custom-image-uploader {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 3rem;
}

.custom-img-box {
  height: 40px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  margin: 0 auto;
  width: 100%;
  background-color: #f9f9f9;
  position: relative;
  border: 1px dashed #ddd;
}

.custom-img-box input[type="file"] {
  width: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0!important;
  z-index: 1;
}

.custom-img-box i {
  font-size: 30px;
  color: #4d5b66;
  cursor: pointer;
}

.custom-img-box span {
  position: relative;
  top: -4px;
  cursor: pointer;
  color: #4d5b66;
}
.form-control.description::placeholder{
  color: #4d5b66!important;
}

.css-1szy77t-control,.css-bg1rzq-control{
  border-color: #48194e!important;
}

.css-bg1rzq-control{
  border-color: #48194e!important;
}

.css-bg1rzq-control:focus{
  border-color: #48194e!important;
}

.css-bg1rzq-control:hover{
  border-color: #48194e!important;
}

.css-bg1rzq-control:active{
  border-color: #48194e!important;
}

.img-preview-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  margin-top: 1rem;
  padding-top: 20px;
}

.prev-img-box {
  position: relative;
  height: 100px;
  width: 100px;
  text-align: center;
  line-height: 100px;
  margin-right: 2rem;
  background-color: #f9f9f9;
  overflow: hidden;
}

.prev-img-box .image-overlay {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.prev-img-box:hover .image-overlay {
  opacity: 1;
}

.image-dlt-details {
  position: absolute;
  color: #fff;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  cursor: pointer;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.prev-img-box:hover .image-dlt-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fadeIn-top {
  top: 20%;
}

#writeQuestion .cstm-checkbox {
  top: 0;
}

/*********  add question page end ***********/

/***********  Main dashboard page css *********/

.info-box {
  display: flex;
  min-height: 75px;
  background: #fff;
  width: 100%;
  border-radius: 2px;
}

.info-box-icon {
  display: block;
  float: left;
  height: 75px;
  color: #fff;
  width: 75px;
  background-color: #757575;
  border-radius: 2px 0 0 2px;
  text-align: center;
  font-size: 35px;
  line-height: 75px;
}

.info-box-content {
  padding: 5px 10px;
}

.info-box-text {
  text-transform: uppercase;
}

.progress-description,
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.info-box-number {
  display: block;
  font-size: 30px;
}

.info-text {
  margin: 0 6px;
  text-transform: uppercase;
  font-weight: bold;
}

.info-number {
  display: block;
  font-size: 16px;
  margin: 0 8px;

}

#dash-feedback-table {
  table-layout: fixed;
}

.dash-feedback-panel table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.main-dashboard.feedback-section,
.main-dashboard.feedback-section:hover {
  padding: 10px 10px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  border: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.main-dashboard.feedback-section .feedback-detail {
  margin-top: 0;
  cursor: default;
}

.main-dashboard.feedback-section .heading-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/***********  Main dashboard page css end  *********/

/*********  create drive page css  *******/
#selectCollege {
  display: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100% !important;
}

.not-found {
  text-align: center;
  font-size: 25px;
  color: #999;
}

/*********  create drive page css end  *******/

/* custom radio button */
/* The container */
.cstm-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.cstm-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.cstm-radio .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.cstm-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.cstm-radio input:checked~.checkmark {
  background-color: #48194e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

/* Show the indicator (dot/circle) when checked */
.cstm-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.cstm-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  content: "";
  position: absolute;
  display: none;
}

/* end */
textarea.desciption-textbox {
  box-sizing: border-box;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  display: block;
  padding: 8px;
  line-height: 24px;
  color: rgb(85, 85, 85);
  background-color: rgb(255, 255, 255);
  background-image: none;
  width: 100%;
  resize: none;
  font-size: 14px;
  overflow: auto;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
}

textarea.desciption-textbox:focus {
  border-color: #0562e8;
  outline: 0;
  box-shadow: none;
}

.refresh {
  float: right;
  margin: 2px 24px 8px 10px;
  cursor: pointer;
  padding: 2px 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.material-icons.redarrow {
  color: #f01c1f;
}

.material-icons.greenarrow {
  color: #35f132;
}

.material-icons.same {
  color: #0562e8;
}

ul.notification.dropdown-menu.alert-dropdown {
  display: block;
  opacity: 1;
}

.form-group.check-filter-button {
  display: flex;
  align-items: center;
  height: 100%;
  top: 5px;
  justify-content: space-between;
  width: 100%;
}

.form-group.check-filter-button button {
  width: auto;
  color: #48194e;
  font-weight: 400;
  background: 0;
}

.form-group.check-filter-button ul {
  width: 80%;
}

span.ant-tag {
  background: #421C4B;
  color: #fff;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

option::selection {
  background: #ccc;
  color: #4d5b66;
}

option::-moz-selection {
  background: #ccc;
}

option::-webkit-selection {
  background: #ccc;
  color: #fff;
}

span.anticon.anticon-close.ant-tag-close-icon {
  color: #fff;
  font-weight: bolder;
}

select#location-val option, select#location-val::placeholder {
  color: #4d5b66 !important;
}

select#location-val option {
  color: #4d5b66;
}

.ant-picker-input>input:placeholder-shown::placeholder {
  color: #4d5b66 !important;
}

.form-group.candidate-search {
  margin: 0;
  width: 30%;
}

.panel-body.candidates-panel-body .filter-holder .row .col-lg-12.col-md-12.col-sm-12.col-xs-12 .row .col-lg-2 {
  flex: 0 0 20%;
  max-width: 20%;
}

select#location-val {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 11px;
  padding-right: 2rem;
  background-size: 20px;
  color: #4d5b66;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #CCCCCC;
}
.multiSelectContainer input::placeholder {
  color: #4d5b66 !important;
}

.searchWrapper {
  border: 1px solid #cccccc;
  padding: 6px 12px;
}

div#multiselectContainerReact {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/header/xenonstack-downarrow.png);
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 11px;
  background-size: 20px;
}

.multiSelectContainer li {
  padding: 0 10px !important;
}

.highlightOption {
  color: #1e1e1e!important;
  background: #fff !important;
  font-weight: normal;
}

.multiSelectContainer li:hover {
  cursor: default !important;
  background: #0a95ff !important;
  color: #fff!important;
}
.panel-body.candidates-panel-body {
  padding: 20px 0 !important;
}

.panel-body.candidates-panel-body .table-wrap .table-responsive .react-xd-table-wrapper .ant-pagination {
  padding-top: 15px;
}

.css-151xaom-placeholder {
  color: #4d5b66 !important;

}

.form-group .css-1hwfws3 {
  font-weight: normal !important;
}

.form-group.personal-select-without-error .form-control {
  color: darkgray;
  font-weight: normal;
}

.form-group.personal-select-without-error .form-control option {
  color: #1e1e1e;
}

.form-group.admin-form-group span {
  color: #4d5b66;
}

.form-group.admin-form-group label {
  color: #1e1e1e;
}

.form-group.candidate-search .form-control {
  border: 1px solid #fff;
  position: relative;
  background: transparent;
  background-repeat: no-repeat;
  border-radius: 5px;
  color: #fff !important;
  background-position-x: 98%;
  background-position-y: 50%;
  background-image: url('../images/candidate-search.svg');
}

.form-group.candidate-search .form-control::placeholder {
  color: #fff;
}

.ant-space.ant-space-vertical {
  width: 100%;
}

.ant-picker.ant-picker-range {
  width: 100%;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

.user-profile-flex a {
  color: #1890ff;
  font-size: 14px;
}

.panel .panel-heading a {
  background: #fff;
  border-radius: 50%;
  width: 30px;
  min-height: 10px;
  height: 30px !important;
}

.panel .panel-heading a i {
  color: #48194e !important;
  top: 0;
  font-size: 20px;
}

a.pull-left.inline-block.mr-15.txt-light {
  height: 35px !important;
  width: 35px !important;
}

table#myTable1 tr td:nth-child(3) {
  color: #4d5b66;
}

table#myTable1 tr td:nth-child(4) {
  color: #4d5b66;
}

table#dash-feedback-table tr td:nth-child(3) {
  color: #4d5b66;
}

table#dash-feedback-table tr td:nth-child(4) {
  color: #4d5b66;
}

.feedback-section .heading-div span {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: normal;
}

.feedback-detail p.page-heading-desc {
  font-weight: normal;
}

.feedback-section p.date-text {
  color: #8a96a8;
}

.pool-operation {
  display: flex;
  gap: 10px;
}

.info-box img {
  width: 75px;
  height: 75px;
}

.dashboard-admin-notification-bell {
  width: 32px;
  height: 65px;
  z-index: 4;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}
.ant-picker-input input {
  color: #1e1e1e;
  font-weight: normal;
}

.profile-wrap label {
  color: #1e1e1e;
}

.profile-wrap span {
  color: #4d5b66;
}

.light-btn, .light-btn:hover {
  display: block;
  background-color: #fff !important;
  padding: 0 !important;
  text-align: center;
  margin-top: 3rem;
  color: #48194e;
}

.note-status {
  width: 100%;
  color: #c01800;
  font-size: 17px;
  padding: 0 10px;
  text-align: center;
  margin: 5rem 0 2rem;
}

.light-btn span {
  display: block;
  padding: 10px 20px;
  color: #48194e !important;
}

.light-btn button::before, .light-btn button::after {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  transition: all 0.2s linear;
  background: #48194e;
}

.light-btn span::before, .light-btn span::after {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #48194e;
}

.light-btn button:hover::before, button:hover::after {
  width: 100%;
}

.light-btn button:hover span::before, button:hover span::after {
  height: 100%;
}

.light-btn .btn-2::before, .btn-2::after {
  transition-delay: 0s;
}

.light-btn .btn-2 span::before, .btn-2 span::after {
  transition-delay: 0.2s;
}

.light-btn .btn-2::before {
  right: 0;
  top: 0;
}

.light-btn .btn-2::after {
  left: 0;
  bottom: 0;
}

.light-btn .btn-2 span::before {
  left: 0;
  top: 0;
}

.light-btn .btn-2 span::after {
  right: 0;
  bottom: 0;
}

.light-btn .btn-2:hover::before, .btn-2:hover::after {
  transition-delay: 0.2s;
}

.light-btn .btn-2:hover span::before, .btn-2:hover span::after {
  transition-delay: 0s;
}

.light-btn button {
  display: inline-block;
  position: relative;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  background: #f3eaff;
}

.panel.panel-default.card-view.custom-panel.pool-panel.custom-drive-list-panel .panel-heading a {
  background: transparent !important;
  height: auto !important;
  width: auto !important;
  align-items: center;
  display: inline-flex !important;
  color: #fff !important;
}

.panel.panel-default.card-view.custom-panel.pool-panel.custom-drive-list-panel .panel-heading a i {
  color: #fff !important;
}

.btn.mar-t-2 {
  margin-top: 3rem;
}

.custom-img-box.candidate-resume-button {
  border: 0;
  padding-left: 10px;
}

.custom-img-box.candidate-resume-button i {
  color: #1890ff;
  font-size: 19px;
  top: 1px;
}

.custom-img-box.candidate-resume-button span {
  color: #1890ff;
  top: 2px;
  font-size: 14px;
}

.custom-img-box.candidate-resume-button .user-profile-flex a {
  display: flex;
}

span.candidate-buttons {
  display: flex;
  align-items: center;
}

span.candidate-buttons a {
  display: flex;
  align-items: baseline;
}

span.candidate-buttons a i {
  top: 6px;
}

.candidate-upload-button:hover i, .candidate-upload-button:hover span {
  color: #40a9ff;
}

.candidate-upload-button {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-img-box.candidate-resume-button input[type="file"] {
  z-index: 0;
}

.sl-item a {
  cursor: default;
}

.notification-panel-heading span:first-child {
  color: #878787;
  font-family: 'Roboto', sans-serif;
  font-weight: normal !important;
}

.notification-panel-heading span a {
  cursor: pointer;
}

.panel.panel-default.card-view.custom-panel.pool-panel.custom-drive-list-panel .panel-heading a.btn.signin {
  color: #fff;
}

table#myTable1 p {
  height: 100%;
  top: 0 !important;
  padding-top: 30px;
}

ul.ant-pagination span::after {
  width: 0;
}


.test-paper-inst button:hover {
  color: #fff !important;
}

i.fa.fa-comment-o {
  cursor: pointer;
}

span.chip.false.false {
  display: none;
}

.ant-tag-hidden {
  display: inline-block !important;
}



@media(max-width:1023px) {
  .panel-body.candidates-panel-body .filter-holder .row .col-lg-12.col-md-12.col-sm-12.col-xs-12 .row .col-lg-2 {
    flex: auto;
    max-width: -webkit-fill-available;
  }
}

@media(min-width:1024px) and (max-width:1339px) {
  .info-box-text {
    width: 85%;
  }
}

@media(max-width:767px) {
  .form-group.candidate-search {
    width: 100%;
  }

  .custom-header .nav-header .logo-wrap a {
    line-height: 67px;
  }

  img.brand-img-cropped {
    padding: 0 5px !important;
  }

  .panel-heading.candidate-page {
    display: block !important;
  }

  .panel-heading-mobile {
    width: 100% !important;
    padding-block-end: 10px;
  }

  .form-group.check-filter-button button {
    width: 100%;
    color: #48194e;
    font-weight: 400;
    background: 0;
    text-align: end;
    padding: 0;
  }

  div#multiselectContainerReact {
    background-position-x: 97%;
    padding: 0;
  }

  a.btn.icons.collapsed {
    margin: 0;
  }

  .admin-dashboard-pages .brand-img {
    display: none;
  }

  .admin-dashboard-pages.slide-nav-toggle .brand-img {
    display: block;
  }

  .navbar.navbar-inverse.navbar-fixed-top {
    padding: 0 !important;
  }

}

@media(min-width:768px) and (max-width:1023px) {
  .form-group.candidate-search {
    margin: 0;
    width: 50%;
  }

  .ant-space.ant-space-vertical {
    width: 100%;
  }

  .ant-picker.ant-picker-range {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
  }
  .navbar.navbar-inverse.navbar-fixed-top .nav-header .logo-wrap .brand-img {
    left: 0 !important;
  }

}

.feedback-section.main-dashboard {
  cursor: default;
}

.heading-div:hover a, .heading-div:hover h5, .heading-div:hover span, .heading-div:hover p {
  color: purple;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
}

.table-responsive tbody tr {
  cursor: default !important;
}

.kxAQtI {
  z-index: 4;
  position: relative;
}

img.brand-img-cropped {
  padding-inline-start: 10px;
}

span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next::after {
  width: 0;
}

.react-datepicker__navigation-icon {
  font-size: 0 !important;
}

.react-datepicker__navigation--next:after {
  content: none !important;
}

.react-datepicker__navigation:hover {
  background: none !important;
}

.feedback-section.main-dashboard:hover {
  cursor: default;
}

.form-group.candidate-search input::placeholder {
  color: #fff !important;
  font-weight: normal !important;
}

.wekanBoardContainer {
  background-color: #f3f3f3 !important;
  height: 100% !important;
}

/* Loader Css */

.scanning-loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-7 {
  width: 50px;
  height: 50px;
  --c: radial-gradient(farthest-side, #48194e 92%, #0000);
  background:
    var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  animation: s7 1s infinite;
}

@keyframes s7 {
  to {
    transform: rotate(.5turn)
  }
}

/* Loader CSS */
label.cstm-radio input {
  display: none;
}

img.hamburger {
  height: 30px;
  width: 30px;
}
form.assign-user-form.create-college-csv-form .form-group.has-error .with-error {
  padding: 15px 0 0 0;
}
.create-team-form-inter-sectional-spacing {
  padding-bottom: 1.8rem;
}
.create-team-form-inter-sectional-spacing .custom-image-uploader {
  margin-bottom: 1rem;
}
.create-team-form-inter-sectional-spacing  h4.content-heading2 {
  font-family: 'Roboto',sans-serif;
  font-size: 3rem;
}
.create-team-form-inter-sectional-spacing .mb-30 , .create-team-form-inter-sectional-spacing .mt-30{
  margin: 10px 0 !important;
}
.create-team-form-inter-sectional-spacing  .divider
{
  background-color: #ddd;
}
.ongoing-drive{
text-align:center;
position:relative;
top:30px
}
.xd-table-btn.delete {
  color: #ec3c3c;
  background-color: #f8f3f3;
  cursor: pointer;
}
.footer-bottom-section{
    background-color: #F8F9FB;
    padding: 1.5rem 0;
}
.legal-activities-outer {
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-flex-line-pack: center;
    align-content: center;
}
.legal-activities-links {
    display: block;
    text-align: left;
    margin: 0;
}
.legal-activities-links .copyright-text {
    font-size: 11pt;
    font-weight: normal;
    margin: 0;
}
.social-media-links {
    text-align: right;
}
.social-media-links li {
    vertical-align: middle;
    display: inline-block;
}
.social-media-links a {
    display: block;
    padding: .3rem;
}
.social-icon-svg {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
}
.social-icon-svg .circle-cls {
    fill: none;
    stroke: none;
    stroke-miterlimit: unset;
    stroke-width: 0;

}
.social-icon-svg .path-cls {
    fill: #424242;
}
.social-media-links a:hover .social-icon-svg .circle-cls {
    stroke: #48194e;
      fill: #48194e;
}
.social-media-links a:hover {
    background:transparent;
}
.social-media-links a:hover .social-icon-svg .path-cls {
    fill: #fff;
}
@media screen and (max-width:640px){
    .copyright-text{
        margin: 10px 0;
    }
    .social-media-links {
        text-align: center;
    }
    .legal-activities-links{
        text-align: center;
        margin-bottom: 10px;
    }
}
#footer-social-menu {
    margin: 0;
  }

@import "./common.css";

.banner-background-outer.team-banner-background{
    background-image: url(https://www.xenonstack.com/images/career/xenonstack-careers-team-banner.svg);
}
.teams-item-box{
    max-width: 350px;
}
.teams-item-box a{
    display: inline-block;
    color: #0562e8;
    line-height: 1.5;
    font-weight: lighter;
    margin-right: 50px;
    border-bottom: 1px solid #ddd;
}
.teams-item-box a:hover{
    color: #0b4dad;
    border-bottom: 1px solid #0b4dad;
}
.subteam-item-box{
    display: grid;
    grid-template-columns: 1fr 3fr;
    max-width: 1110px;
    gap: 40px;
    margin-bottom: 7rem;
}
.subteam-item-box img{
    max-width: 300px;
}
.btn.lght-btn{
    border-radius: 0;
    width: 50%;
    background-color: #fff;
    border-right: 1px solid #ddd;
    color: #0b4dad;
    font-size: 19px;
}
.btn.dark-btn{
    border-radius: 0;
    width: 50%; 
    font-size: 19px;
}
/* Jobs page css */
.banner-background-outer.jobs-banner-background{
    background-image: url(https://www.xenonstack.com/images/career/xenonstack-careers-common-banner.svg);
}
.banner-background-outer.job-detail-banner-background{
    background-image: url(https://www.xenonstack.com/images/career/xenonstack-careers-common-banner.svg);
    opacity: 0.6;
}
.right-arrow .material-icons{
    font-size: 35px;
    color: #b1b0b0;
}
.job-item-box{
    display: flex;
    justify-content: space-between;
    border: 1px solid #eeee;
    padding: 20px;
    font-size: 18px;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: 3rem auto;
    align-items: center;
    background-color: #F9FBFF;
    box-shadow: 0 0 10px 0 rgb(243, 248, 255);
}
.job-item-content{
    flex: 1;
    padding: 0 10px 0 0;
    cursor: pointer;
}
.job-item-content:first-child a p{
    color: #0562e8;
    font-size: 2.4rem;  
}
.job-item-content a span{
    color: #2b2b2b;
    font-size: inherit;  
}
.job-item-content:first-child:hover p{
    color: #0b4dad;
    text-decoration: underline;
}
.job-item-content:last-child{
    text-align: right;
    max-width: 100px;
}
.job-detail-banner-container.banner-container{
    min-height: 330px;
}
.filter-item-group {
	border: 1px solid #86b3d9;
	display: inline-block;
	border-radius: 3px;
	padding: 0 30px 0px 8px;
	position: relative;
	font-size: 0.9rem;
	font-weight: lighter;
	background: #e8f2f7;
	min-height: 24px;
	line-height: 24px;
	margin: 5px;
}
.filter-item label {
	font-size: 0.8rem;
	font-weight: lighter;
	color: #666;
	display: block;
	padding: 0 5px;
}
.filter-item {
	margin: 0 -5px;
}
.no-data-found-img img {
    width: 100px;
}
@media screen and (max-width:1368px){
    .three-boxes-item.item-with-image.team-list-item {
    margin: 0;
    }
}
@media screen and (max-width:1024px){
    .banner-background-outer.team-banner-background,
    .banner-background-outer.job-detail-banner-background,
    .banner-background-outer.jobs-banner-background{
        background-image: url(https://www.xenonstack.com/images/career/mobile-screen-common-banner.svg);
    }
    .job-detail-banner-container.banner-container{
        min-height: 200px; 
    }
}
@media screen and (max-width:640px){
    .three-boxes-item.item-with-image.team-list-item {
        margin: 0 auto;
    }
    .teams-item-box{
        max-width: 100%;
    }
    .subteam-item-box{
        grid-template-columns: unset;
        gap: 0px;
        margin-bottom: 3rem;
        text-align: center;
    }
    .subteam-item-box img{
        max-width: 60%;
        margin: 0 0 2rem;
    }
}